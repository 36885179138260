import { useEffect, useState } from "react";
import Header from "../../Header";
import { AnimatePresence, motion } from "framer-motion";
import { Button, Progress } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useStatsigClient } from "@statsig/react-bindings";

export default function HomePageV5() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [progressValue, setProgressValue] = useState(0);
  const [goal, setGoal] = useState("");
  const [challenge, setChallenge] = useState("");
  const [periodPain, setPeriodPain] = useState("");
  const { logEvent } = useStatsigClient();

  useEffect(() => {
    const quizCompleted = localStorage.getItem("quizCompleted");
    if (quizCompleted === "true") {
      navigate(ROUTES.root);
    }
  }, [navigate]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    let timeout: NodeJS.Timeout;
    if (step !== 0 && step % 2 === 0) {
      timeout = setTimeout(() => {
        setStep(step + 1);
      }, 5000);

      const increment = 100 / ((5 * 1000) / 100);
      let currentProgress = 0;
      interval = setInterval(() => {
        currentProgress += increment;
        if (currentProgress >= 100) {
          clearInterval(interval);
          setProgressValue(100);
        } else {
          setProgressValue(currentProgress);
        }
      }, 100);
    }

    if (step === 11) {
      logEvent("quiz_completed", "true");
      localStorage.setItem("quizCompleted", "true");
    }

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [step]);

  return (
    <>
      <Header />
      <section className="flex min-h-[calc(100vh-4rem)] justify-center">
        <AnimatePresence mode="popLayout">
          {step === 0 && (
            <div className="container mx-auto mt-16 max-w-screen-md px-6">
              <motion.h1
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="text-3xl font-bold"
              >
                Kíváncsi vagy, hogy Te is NutriQueen lehetsz-e?
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4"
              >
                Töltsd ki a villámgyors kvízünket, és derítsd ki, hogy megéri-e
                számodra csatlakozni!
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.5, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-16"
              >
                Készen állsz?🤩
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-8"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    logEvent("quiz_started", "Igen");
                  }}
                  className="w-full"
                  variant="solid"
                  color="primary"
                >
                  Igen 😍
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    logEvent("quiz_started", "Hát persze");
                  }}
                  className="w-full"
                  variant="bordered"
                  color="primary"
                >
                  Hát persze 🥳
                </Button>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          {step === 1 && (
            <div className="container mx-auto mt-16 max-w-screen-md px-6">
              <motion.h1
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="text-center text-2xl font-bold"
              >
                Mi az elsődleges célod az életmódváltással?
              </motion.h1>

              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.5, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-12"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    setGoal("Fogyás");
                    logEvent("goal_selected", "Fogyás");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Fogyás
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    setGoal("Önbizalom növelése");
                    logEvent("goal_selected", "Önbizalom növelése");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Önbizalom növelése
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2.5, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    setGoal("Egészséges élnetmód kialakítása");
                    logEvent(
                      "goal_selected",
                      "Egészséges élnetmód kialakítása",
                    );
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Egészséges élnetmód kialakítása
                </Button>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          {step === 2 && (
            <div className="mt-4 flex flex-col px-4">
              <Progress value={progressValue} size="sm" maxValue={100} />
              <div className="container mx-auto mb-20 mt-16 flex max-w-screen-md items-center justify-center px-6">
                <motion.p
                  initial={{ opacity: 0, y: -100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 1 }}
                  exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                  className="text-center"
                >
                  {goal === "Fogyás" &&
                    "A Nutriqueens segíthet neked a fogyásban, a hatékony edezés és az egészséges táplálkozás kialakításában."}
                  {goal === "Önbizalom növelése" &&
                    "Növeld az önbizalmadat a Nutriqueens által kínált egészséges életmóddal és támogató közösséggel."}
                  {goal === "Egészséges élnetmód kialakítása" &&
                    "Élj egészségesebben a Nutriqueens praktikus tanácsaival és táplálkozási útmutatásával."}
                </motion.p>
              </div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          {step === 3 && (
            <div className="container mx-auto mt-16 max-w-screen-md px-6">
              <motion.h1
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="text-center text-2xl font-bold"
              >
                Mi a legnagyobb kihívás számodra az egészséges étrend
                fenntartásában?
              </motion.h1>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.5, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-12"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    setChallenge("Időhiány");
                    logEvent("challenge_selected", "Időhiány");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Időhiány
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    setChallenge("Az egészséges ételek költsége");
                    logEvent(
                      "challenge_selected",
                      "Az egészséges ételek költsége",
                    );
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Az egészséges ételek költsége
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2.5, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    setChallenge("Ízletes receptek megtalálása");
                    logEvent(
                      "challenge_selected",
                      "Ízletes receptek megtalálása",
                    );
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Ízletes receptek megtalálása
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 3, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    setChallenge("Motiváció fenntartása");
                    logEvent("challenge_selected", "Motiváció fenntartása");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Motiváció fenntartása
                </Button>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          {step === 4 && (
            <div className="mt-4 flex flex-col px-4">
              <Progress value={progressValue} size="sm" maxValue={100} />
              <div className="container mx-auto mb-20 mt-16 flex max-w-screen-md items-center justify-center px-6">
                <motion.p
                  initial={{ opacity: 0, y: -100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 1 }}
                  exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                  className="text-center"
                >
                  {challenge === "Időhiány" &&
                    "Küzdj meg az időhiánnyal a Nutriqueens gyors és egyszerű receptjeivel."}
                  {challenge === "Az egészséges ételek költsége" &&
                    "Találd meg az egészséges ételeket megfizethető áron a Nutriqueens segítségével."}
                  {challenge === "Ízletes receptek megtalálása" &&
                    "Fedezd fel az ízletes és egészséges recepteket, a Nutriqueens kínálatában."}
                  {challenge === "Motiváció fenntartása" &&
                    "Maradj motivált a Nutriqueens támogató közösségével."}
                </motion.p>
              </div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          {step === 5 && (
            <div className="container mx-auto mt-16 max-w-screen-md px-6">
              <motion.h1
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="text-center text-2xl font-bold"
              >
                Küzdesz menstruációs fájdalmakkal?
              </motion.h1>

              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.5, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-12"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    setPeriodPain("Igen");
                    logEvent("period_pain", "Igen");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Igen
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    setPeriodPain("Nem");
                    logEvent("period_pain", "Nem");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Nem
                </Button>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          {step === 6 && (
            <div className="mt-4 flex flex-col px-4">
              <Progress value={progressValue} size="sm" maxValue={100} />
              <div className="container mx-auto mb-20 mt-16 flex max-w-screen-md items-center justify-center px-6">
                <motion.p
                  initial={{ opacity: 0, y: -100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 1 }}
                  exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                  className="text-center"
                >
                  {periodPain === "Igen" &&
                    "Találd meg a megoldást a menstruációs fájdalmaidra a Nutriqueens szakértői tanácsaival."}
                  {periodPain === "Nem" &&
                    "A Nutriqueens segíthet megérteni a menstruációs ciklusodat és egy egészséges életmódot kialakítani."}
                </motion.p>
              </div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          {step === 7 && (
            <div className="container mx-auto mt-16 max-w-screen-md px-6">
              <motion.h1
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="text-center text-2xl font-bold"
              >
                Mennyi időt szoktál általában főzésre fordítani?
              </motion.h1>

              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.5, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-12"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    logEvent("cooking_time", "Kevesebb mint 30 perc");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Kevesebb mint 30 perc
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    logEvent("cooking_time", "30-60 perc");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  30-60 perc
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2.5, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    logEvent("cooking_time", "Több mint egy óra");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Több mint egy óra
                </Button>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          {step === 8 && (
            <div className="mt-4 flex flex-col px-4">
              <Progress value={progressValue} size="sm" maxValue={100} />
              <div className="container mx-auto mb-20 mt-16 flex max-w-screen-md items-center justify-center px-6">
                <motion.p
                  initial={{ opacity: 0, y: -100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 1 }}
                  exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                  className="text-center"
                >
                  Nincs sok időd főzni? A Nutriqueens gyors és egyszerű
                  receptjei segítenek neked.
                </motion.p>
              </div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          {step === 9 && (
            <div className="container mx-auto mt-16 max-w-screen-md px-6">
              <motion.h1
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="text-center text-2xl font-bold"
              >
                Mennyi időt töltesz hetente sportolással vagy testmozgással?
              </motion.h1>

              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.5, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-12"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    logEvent("sport_time", "Nem szoktam sportolni");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Nem szoktam sportolni
                </Button>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    logEvent("sport_time", "Kevesebb mint 1 órát");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Kevesebb mint 1 órát
                </Button>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2.5, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    logEvent("sport_time", "1-3 órát");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  1-3 órát
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 3, duration: 0.5 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    logEvent("sport_time", "Több mint 3 órát");
                  }}
                  className="w-full"
                  variant="shadow"
                  color="primary"
                  size="lg"
                >
                  Több mint 3 órát
                </Button>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          {step === 10 && (
            <div className="mt-4 flex flex-col px-4">
              <Progress value={progressValue} size="sm" maxValue={100} />
              <div className="container mx-auto mb-20 mt-16 flex max-w-screen-md items-center justify-center px-6">
                <motion.p
                  initial={{ opacity: 0, y: -100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 1 }}
                  exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                  className="text-center"
                >
                  Akár sokat, akár keveset sportolsz, a Nutriqueens edzései
                  segítenek neked.
                </motion.p>
              </div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          {step === 11 && (
            <div className="container mx-auto mt-16 max-w-screen-md px-6">
              <motion.h1
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="text-center text-2xl font-bold"
              >
                Gratulálunk!
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 text-center font-bold"
              >
                Az eredményeid alapján Te is NutriQueen lehetsz! 🎉
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.5, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-8 text-center"
              >
                A NutriQueens közössége pont Rád vár. Csatlakozz hozzánk, és
                élvezd az életmódváltást egy motiváló közösségben! 💜
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-16"
              >
                <Button
                  onClick={() => {
                    logEvent("need_more_info", "false");
                    navigate(ROUTES.subsciptions);
                  }}
                  className="w-full"
                  variant="solid"
                  color="primary"
                >
                  Vágjunk bele 😍
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2, duration: 1 }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
                className="mt-4 w-full"
              >
                <Button
                  onClick={() => {
                    logEvent("need_more_info", "true");
                    navigate(ROUTES.root);
                  }}
                  className="w-full"
                  variant="bordered"
                  color="primary"
                >
                  Tóbbet szeretnék tudni 🤓
                </Button>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </section>
    </>
  );
}
