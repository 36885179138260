import { useEffect, useState } from "react";
import {
  Chat as StreamChat,
  Channel,
  Window,
  ChannelHeader,
  MessageList,
  MessageInput,
  Thread,
  useCreateChatClient,
} from "stream-chat-react";
import { EmojiPicker } from "stream-chat-react/emojis";
import { useAuth } from "../../store/auth.store";

import "stream-chat-react/dist/css/v2/index.css";
import Layout from "../../components/Layout";
import { useApp } from "../../store/app.store";
import { Channel as StreamChannel, DefaultGenerics } from "stream-chat";
import { GETSTREAM_API_KEY } from "../../constants/environment";
import { Spinner } from "@nextui-org/react";

const apiKey = GETSTREAM_API_KEY;
const userId = useAuth.getState().user?.id || "";
const token = useAuth.getState().user?.streamToken || "";
const userName =
  useAuth.getState().user?.firstName + " " + useAuth.getState().user?.lastName;

export default function Chat() {
  const [channel, setChannel] = useState<
    StreamChannel<DefaultGenerics> | undefined
  >();
  const app = useApp();
  const client = useCreateChatClient({
    apiKey,
    tokenOrProvider: token,
    userData: { id: userId.toString(), name: userName },
  });

  useEffect(() => {
    if (!client) return;

    const channel = client.channel("messaging", "main", {
      name: "Main Chat",
      image: "https://getstream.io/random_svg/?name=Main+Chat",
    });

    setChannel(channel);
  }, [client]);

  useEffect(() => {
    return () => {
      if (client) {
        client.disconnectUser();
      }
    };
  }, [client]);

  if (!client)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout padding={false}>
      <StreamChat
        client={client}
        theme={app.theme === "dark" ? "str-chat__theme-dark" : ""}
      >
        <Channel channel={channel} EmojiPicker={EmojiPicker}>
          <Window>
            <ChannelHeader />
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      </StreamChat>
    </Layout>
  );
}
