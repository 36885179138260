import Layout from "../../../components/Layout";
import {
  IconArrowLeft,
  IconBookmark,
  IconClock,
  IconPencil,
  IconToolsKitchen2,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useMutation, useQuery } from "react-query";
import {
  getRecipeById,
  toggleLike,
} from "../../../api/app/recipes/recipes.api";
import {
  Card,
  CardBody,
  CardHeader,
  Chip,
  Divider,
  Image,
  Spacer,
  Spinner,
} from "@nextui-org/react";
import { getImageUrl } from "../../../utils/images.utils";
import { startCase } from "lodash";
import parse, { Element } from "html-react-parser";
import { Button } from "../../../components/Button";
import { useAuth } from "../../../store/auth.store";
import { Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";

const ModifyButton = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation("recipes");
  const { id } = useParams();

  if (auth.user!.role === "ADMIN" || auth.user!.role === "OWNER") {
    return (
      <div className="flex justify-center items-center pt-3 cursor-pointer opacity-70">
        <Button
          startContent={<IconPencil />}
          variant="light"
          color="default"
          onClick={() => navigate(ROUTES.admin.recipes.getRecipe({ id: id! }))}
        >
          {t("edit")}
        </Button>
      </div>
    );
  }

  return null;
};

export default function Recipe() {
  const { t } = useTranslation(["recipes", "recipeunits"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(false);

  const { isLoading, data: recipe } = useQuery(
    ["recipe", id],
    () => getRecipeById(id!),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { isLoading: likeIsLoading, mutate: toggleLikeData } = useMutation(
    toggleLike,
    {
      onSuccess: () => {
        setIsSaved(!isSaved);
      },
    },
  );

  useEffect(() => {
    if (recipe) {
      setIsSaved(recipe.isLiked);
    }
  }, [recipe]);

  if (isLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter>
      <ModifyButton />
      <div className="flex justify-between">
        <div
          className="flex items-center gap-2 pb-4 pt-6 cursor-pointer opacity-70 w-min"
          onClick={() => navigate(ROUTES.recipes.root)}
        >
          <IconArrowLeft size={24} />
          <span className="whitespace-nowrap">{t("back")}</span>
        </div>
        <div
          className="flex items-center gap-2 pb-4 pt-6 cursor-pointer opacity-70 w-min"
          onClick={() => toggleLikeData(recipe.id)}
        >
          {likeIsLoading && <Spinner size="sm" />}
          {!likeIsLoading && (
            <IconBookmark
              size={24}
              className={`${isSaved ? "fill-accent-500 stroke-accent-500" : "fill-none"}`}
            />
          )}
          <span className="whitespace-nowrap">
            {isSaved ? t("saved") : t("save")}
          </span>
        </div>
      </div>
      <Spacer y={2} />
      <Card
        classNames={{
          base: "w-full max-h-96",
          body: "p-0 overflow-hidden",
        }}
        shadow="none"
      >
        <CardBody>
          <Image
            src={getImageUrl(recipe?.image)}
            alt={recipe?.title}
            className="h-full w-full"
            classNames={{
              wrapper: "w-full max-h-96 !max-w-full flex items-center",
            }}
          />
        </CardBody>
      </Card>
      <Spacer y={6} />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-md:gap-x-0">
        <div className="flex flex-row gap-4 justify-center">
          {recipe?.servings && (
            <div className="flex flex-col items-center">
              <IconToolsKitchen2 />
              <span>
                {recipe?.servings} {t("servings")}
              </span>
            </div>
          )}
          {recipe?.time && (
            <div className="flex flex-col items-center">
              <IconClock />
              <span>
                {recipe?.time} {t("minutes")}
              </span>
            </div>
          )}
        </div>
        <h1 className="text-3xl font-bold col-span-2 max-md:text-center">
          {recipe?.title}
        </h1>
        <Card>
          <CardHeader className="flex w-full flex-col pb-0">
            <h1 className="text-lg font-semibold opacity-70 pb-4 self-start">
              Tapanyagok <span className="text-sm opacity-65">/100g</span>
            </h1>
            <div className="flex justify-evenly w-full">
              <div className="flex flex-col items-center">
                <h2 className="font-semibold opacity-70">
                  {recipe.nutritionFacts?.calories}
                </h2>
                <label className="font-bold text-sm">Kcal</label>
              </div>
              <div className="flex flex-col items-center">
                <h2 className="font-semibold opacity-70">
                  {recipe.nutritionFacts?.carbs}g
                </h2>
                <label className="font-bold text-sm">Szenhidrat</label>
              </div>
              <div className="flex flex-col items-center">
                <h2 className="font-semibold opacity-70">
                  {recipe.nutritionFacts?.protein}g
                </h2>
                <label className="font-bold text-sm">Feherje</label>
              </div>
              <div className="flex flex-col items-center">
                <h2 className="font-semibold opacity-70">
                  {recipe.nutritionFacts?.fat}g
                </h2>
                <label className="font-bold text-sm">Zsir</label>
              </div>
            </div>
          </CardHeader>
          <CardBody className="gap-3">
            <Divider orientation="horizontal" />
            <h2 className="text-lg font-semibold opacity-70">
              {t("ingredients")}
            </h2>
            {recipe?.RecipeToIngredient?.map(
              (rti: {
                ingredient: {
                  id: string;
                  quantity: string;
                  unit: string;
                  ingredient: string;
                };
              }) => {
                if (rti.ingredient.unit === "none") {
                  return (
                    <div key={rti.ingredient.id} className="grid grid-cols-12">
                      <div className="col-span-5 " />
                      <div className="col-span-7 flex flex-row gap-2">
                        <Divider orientation="vertical" />
                        <p>{startCase(rti.ingredient.ingredient)}</p>
                      </div>
                    </div>
                  );
                }

                return (
                  <div key={rti.ingredient.id} className="grid grid-cols-12">
                    <span className="text-right mr-2 font-semibold col-span-2 self-center">
                      {rti.ingredient.quantity}
                    </span>
                    <span className="self-center col-span-3">
                      {t(`${rti.ingredient.unit}Short`, { ns: "recipeunits" })}
                    </span>
                    <div className="col-span-7 flex flex-row gap-2">
                      <Divider orientation="vertical" />
                      <p>{startCase(rti.ingredient.ingredient)}</p>
                    </div>
                  </div>
                );
              },
            )}
          </CardBody>
        </Card>
        <div className="col-span-2">
          {parse(recipe?.instructions, {
            transform: (reactNode, domNode, index) => {
              const isFirst = index === 0;
              const isLast = index === recipe?.instructions.length - 1;

              if (domNode instanceof Element && domNode.name === "li") {
                return (
                  <div
                    key={index}
                    className={`flex flex-row gap-2 text-lg ${!isFirst && !isLast ? "my-4" : ""} ${isFirst ? "mb-4" : ""} ${isLast ? "mt-4" : ""}`}
                  >
                    <Chip color="primary">{index + 1}</Chip>
                    {reactNode}
                  </div>
                );
              }

              return <Fragment key={index}>{reactNode}</Fragment>;
            },
          })}
        </div>
        <div>
          <h2 className="text-lg font-semibold opacity-70 mb-2">{t("tags")}</h2>
          {recipe?.tags && (
            <div className="flex gap-2 flex-wrap">
              {recipe?.tags.map(
                (tag: { id: string | number; tag: { name: string } }) => (
                  <Chip
                    onClick={() =>
                      navigate(`${ROUTES.recipes.root}?tags=${tag.tag.name}`)
                    }
                    key={tag.id}
                    color="primary"
                    variant="bordered"
                    className="cursor-pointer"
                  >
                    {tag.tag.name}
                  </Chip>
                ),
              )}
            </div>
          )}
        </div>
      </div>
      <Spacer y={6} />
    </Layout>
  );
}
