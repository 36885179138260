import Layout from "../../../../components/Layout";
import {
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Input,
  Spacer,
  Spinner,
  Switch,
  Textarea,
} from "@nextui-org/react";
import { useDropzone } from "react-dropzone";
import {
  IconDragDrop,
  IconDragDrop2,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import { Button } from "../../../../components/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import {
  creatVideo,
  getAdminVideo,
  updateVideo,
} from "../../../../api/admin/app/kBase/video.api";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";

const digitsOnly = (value: string | undefined) => {
  if (value === undefined) return false;

  return /^\d+$/.test(value);
};

const schema = yup
  .object()
  .shape({
    title: yup.string().required(),
    description: yup.string().required(),
    order: yup
      .string()
      .test("Digits only", i18n.t("admin:kBase.videos.digitsOnly"), digitsOnly)
      .required(),
    active: yup.boolean().default(false),
  })
  .required();

export type QuizDto = {
  id?: string;
  position: number;
  question: string;
  answer: string;
  options: string[];
};

export default function VideoAdmin({ isNew }: { isNew?: boolean }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const { t } = useTranslation("admin");
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
  });
  const [quiz, setQuiz] = useState<QuizDto[] | null>(null);

  const addQuizQuestion = () => {
    if (quiz?.length === 0 || !quiz)
      return setQuiz([
        { position: 1, question: "", answer: "", options: ["", "", ""] },
      ]);

    if (quiz?.length >= 3) return;

    if (quiz?.filter((q) => q.question === "").length > 0)
      return form.setError("root", {
        message: "Minden kerdes kitoltese kotelezo",
      });

    // Find next position and add new question
    const nextPosition =
      quiz.reduce((acc, q) => (q.position > acc ? q.position : acc), 0) + 1;
    setQuiz([
      ...quiz,
      {
        position: nextPosition,
        question: "",
        answer: "",
        options: ["", "", ""],
      },
    ]);
  };

  const changeQuizByPosition = (
    position: number,
    key: string,
    value: string | string[],
  ) => {
    if (!quiz) return;

    const updatedQuiz = quiz.map((q) => {
      if (q.position === position) {
        return {
          ...q,
          [key]: value,
        };
      }
      return q;
    });

    setQuiz(updatedQuiz);
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      multiple: false,
      accept: { "video/*": [] },
    });

  const { isLoading: isVideoLoading, data: video } = useQuery(
    ["video", id],
    () => getAdminVideo(id!),
    {
      enabled: !isNew,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (!isNew && video) {
      form.setValue("title", video.title);
      form.setValue("description", video.description);
      form.setValue("order", video.order);
      form.setValue("active", video.status === "ACTIVE" ? true : false);
      if (acceptedFiles.length === 0) {
        acceptedFiles.push(new File([], video.url));
      }
      if (video.quiz) {
        setQuiz(video.quiz);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  useEffect(() => {
    return () => {
      acceptedFiles.pop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading: updateIsLoading, mutate: saveUpdatedVideo } = useMutation(
    updateVideo,
    {
      onError: (error: { message: string }) => {
        form.setError("root", { message: error.message });
      },
      onSuccess: () => {
        acceptedFiles.pop();
        navigate(ROUTES.kBase.getModule({ id: state?.moduleId, videoId: id! }));
      },
    },
  );

  const { isLoading: createIsLoading, mutate: saveVideo } = useMutation(
    creatVideo,
    {
      onError: (error: { message: string }) => {
        form.setError("root", { message: error.message });
      },
      onSuccess: () => {
        acceptedFiles.pop();
        navigate(ROUTES.kBase.getModule({ id: state?.moduleId, videoId: id! }));
      },
    },
  );

  useEffect(() => {
    setIsLoading(createIsLoading || updateIsLoading || isVideoLoading);
  }, [createIsLoading, updateIsLoading, isVideoLoading]);

  if (!state?.moduleId && !video?.moduleId) {
    navigate(ROUTES.kBase.root);
    return null;
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit((data) => {
      if (isNew) {
        saveVideo({
          ...data,
          moduleId: state?.moduleId,
          videos: acceptedFiles,
          quiz,
        });
      } else {
        saveUpdatedVideo({
          id: id!,
          ...data,
          moduleId: state?.moduleId,
          videos: acceptedFiles,
          quiz,
        });
      }
    })();
  };

  if (!isNew && isVideoLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter classNames={{ main: "max-w-screen-sm" }}>
      <form onSubmit={onSubmit}>
        <h1 className="mt-4 text-xl font-semibold">
          {isNew ? t("kBase.videos.newVideo") : t("kBase.videos.editVideo")}
        </h1>

        <Controller
          name="active"
          control={control}
          render={({ field }) => (
            <Switch
              onValueChange={(isSelected) => field.onChange(isSelected)}
              isSelected={field.value}
              className="mt-4"
            >
              <span className="max-w opacity-70">
                {t("kBase.videos.active")}
              </span>
            </Switch>
          )}
        />

        <Spacer y={4} />

        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("kBase.videos.name")}
              variant="bordered"
              color="primary"
              errorMessage={form.formState.errors.title?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              label={t("kBase.videos.description")}
              variant="bordered"
              color="primary"
              errorMessage={form.formState.errors.description?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="order"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("kBase.videos.order")}
              variant="bordered"
              color="primary"
              errorMessage={form.formState.errors.order?.message}
            />
          )}
        />

        <div
          {...getRootProps()}
          className="mt-4 flex flex-col items-center justify-center rounded-xl border-2 border-solid border-default-200 p-3 shadow-sm "
        >
          <h1 className="self-start pb-1 text-sm text-primary-300">
            {t("kBase.videos.video")}
          </h1>
          <input {...getInputProps()} />
          {isDragActive && (
            <div
              className={
                (acceptedFiles.length ? "h-80" : "") +
                " flex flex-col items-center justify-center"
              }
            >
              <IconDragDrop2 size={48} className="text-primary-500" />
              <p className="text-center text-sm text-primary-300">
                {t("kBase.videos.videoDrag")}
              </p>
            </div>
          )}
          {isDragActive ||
            (!acceptedFiles.length && (
              <>
                <IconDragDrop size={48} className="text-primary-300" />
                <p className="text-sm text-primary-300">
                  {t("kBase.videos.videoDrop")}
                </p>
              </>
            ))}
          {!isDragActive && acceptedFiles.length > 0 && (
            <div>
              {acceptedFiles.map((file) => (
                <video key={file.name} controls className="max-h-80 rounded-lg">
                  <source
                    src={
                      file.name.includes("googleapis")
                        ? file.name
                        : URL.createObjectURL(file)
                    }
                  />
                  {t("kBase.videos.notSupported")}
                </video>
              ))}
            </div>
          )}
        </div>

        <div className="mt-4">
          <h1 className="self-start pb-1 text-sm text-primary-300">
            {t("kBase.videos.quiz")}
          </h1>
          <Button
            variant="solid"
            color="primary"
            className="mt-2 w-full"
            isDisabled={quiz?.length === 3}
            onClick={addQuizQuestion}
          >
            <IconPlus /> {t("kBase.videos.newQuizQuestion")}
          </Button>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            {quiz &&
              quiz?.map((q, i) => (
                <Card key={i} className="mt-4 w-full max-w-lg">
                  <CardHeader className="flex flex-row items-start">
                    <Input
                      label={t("kBase.videos.quizQuestion", { count: i + 1 })}
                      variant="bordered"
                      color="primary"
                      value={q.question}
                      onChange={(e) =>
                        changeQuizByPosition(
                          q.position,
                          "question",
                          e.target.value,
                        )
                      }
                      className="w-full"
                    />
                    <Spacer x={2} />
                    <Button
                      variant="solid"
                      color="danger"
                      className="mt-2"
                      size="sm"
                      onClick={() =>
                        setQuiz(quiz.filter((qu) => qu.position !== q.position))
                      }
                    >
                      <IconTrash />
                    </Button>
                  </CardHeader>
                  <CardBody className="grid grid-cols-10 gap-2">
                    <Checkbox
                      value={q.options?.[0]}
                      isSelected={q.answer === q.options?.[0]}
                      onValueChange={() => {
                        changeQuizByPosition(
                          q.position,
                          "answer",
                          q.options?.[0],
                        );
                      }}
                    />
                    <Input
                      label={t("kBase.videos.quizAnswer", { count: 1 })}
                      variant="bordered"
                      color="primary"
                      className="w-full"
                      classNames={{ base: "col-span-9" }}
                      value={q.options?.[0]}
                      onChange={(e) =>
                        changeQuizByPosition(q.position, "options", [
                          e.target.value,
                          q.options[1],
                          q.options[2],
                        ])
                      }
                    />
                    <Checkbox
                      value={q.options?.[1]}
                      isSelected={q.answer === q.options?.[1]}
                      onValueChange={() => {
                        changeQuizByPosition(
                          q.position,
                          "answer",
                          q.options?.[1],
                        );
                      }}
                    />
                    <Input
                      label={t("kBase.videos.quizAnswer", { count: 2 })}
                      variant="bordered"
                      color="primary"
                      className="w-full"
                      classNames={{ base: "col-span-9" }}
                      value={q.options?.[1]}
                      onChange={(e) =>
                        changeQuizByPosition(q.position, "options", [
                          q.options[0],
                          e.target.value,
                          q.options[2],
                        ])
                      }
                    />
                    <Checkbox
                      value={q.options?.[2]}
                      isSelected={q.answer === q.options?.[2]}
                      onValueChange={() => {
                        changeQuizByPosition(
                          q.position,
                          "answer",
                          q.options?.[2],
                        );
                      }}
                    />
                    <Input
                      label={t("kBase.videos.quizAnswer", { count: 3 })}
                      variant="bordered"
                      color="primary"
                      className="w-full"
                      classNames={{ base: "col-span-9" }}
                      value={q.options?.[2]}
                      onChange={(e) =>
                        changeQuizByPosition(q.position, "options", [
                          q.options[0],
                          q.options[1],
                          e.target.value,
                        ])
                      }
                    />
                  </CardBody>
                </Card>
              ))}
          </div>
        </div>

        {form.formState.errors.root && (
          <>
            <Spacer y={3} />
            <p className="text-center text-sm text-red-500">
              {form.formState.errors.root.message}
            </p>
          </>
        )}

        <Button
          variant="solid"
          color="primary"
          className="mt-8 w-full"
          type="submit"
          isLoading={isLoading}
          isDisabled={
            isLoading || !form.formState.isValid || acceptedFiles.length === 0
          }
        >
          {t("kBase.videos.save")}
        </Button>
      </form>
      <Spacer y={4} />
    </Layout>
  );
}
