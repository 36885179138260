import { RouteObject } from "react-router-dom";
import CourseAdmin from "../pages/admin/app/kBase/course";
import ModuleAdmin from "../pages/admin/app/kBase/module";
import VideoAdmin from "../pages/admin/app/kBase/video";
import AdminLecturers from "../pages/admin/app/kBase/lecturers";
import AdminLecturer from "../pages/admin/app/kBase/lecturer";
import AdminRecipe from "../pages/admin/app/recipes/recipe";
import AdminMenu from "../pages/admin/adminMenu";
import { ROUTES } from "../constants/routes";
import { RequireAuth } from "./RequireAuth";
import { RequireRole } from "./RequireRole";
import AdminSubscriptions from "../pages/admin/subscriptions/subscriptions";
import AdminSubscription from "../pages/admin/subscriptions/subscription";
import AdminCoupons from "../pages/admin/coupons/coupons";
import AdminCoupon from "../pages/admin/coupons/coupon";
import AdminUsers from "../pages/admin/users/users";
import AdminUser from "../pages/admin/users/user";
import AdminAnalysis from "../pages/admin/analysis/analysis";
import AdminAnalysisData from "../pages/admin/analysis/data";

export const AdminRouter: RouteObject[] = [
  {
    path: ROUTES.admin.root,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminMenu />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.kBase.course,
    element: (
      <RequireAuth>
        <RequireRole>
          <CourseAdmin />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.kBase.newCourse,
    element: (
      <RequireAuth>
        <RequireRole>
          <CourseAdmin isNew />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.kBase.module,
    element: (
      <RequireAuth>
        <RequireRole>
          <ModuleAdmin />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.kBase.newModule,
    element: (
      <RequireAuth>
        <RequireRole>
          <ModuleAdmin isNew />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.kBase.video,
    element: (
      <RequireAuth>
        <RequireRole>
          <VideoAdmin />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.kBase.newVideo,
    element: (
      <RequireAuth>
        <RequireRole>
          <VideoAdmin isNew />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.kBase.lecturers,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminLecturers />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.kBase.newLecturer,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminLecturer isNew />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.kBase.lecturer,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminLecturer />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.recipes.newRecipe,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminRecipe isNew />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.recipes.recipe,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminRecipe />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.subscriptions.root,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminSubscriptions />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.subscriptions.newSubscription,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminSubscription isNew />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.subscriptions.subscription,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminSubscription />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.coupons.root,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminCoupons />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.coupons.newCoupon,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminCoupon isNew />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.coupons.coupon,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminCoupon />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.users.root,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminUsers />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.users.user,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminUser />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.users.user,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminUsers />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.analysis.root,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminAnalysis />
        </RequireRole>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.admin.analysis.data,
    element: (
      <RequireAuth>
        <RequireRole>
          <AdminAnalysisData />
        </RequireRole>
      </RequireAuth>
    ),
  },
];
