import { API_URL } from "../../../../constants/environment";
import { axios } from "../../../axios";

axios.defaults.withCredentials = true;

export async function createModule({
  title,
  active,
  images,
  courseId,
}: {
  title: string;
  active?: boolean;
  images: File[];
  courseId: string;
}) {
  const form = new FormData();

  for (const image of images) {
    if (image instanceof File) {
      form.append("images", image);
    }
  }

  form.append("title", title);
  form.append("courseId", courseId);
  if (active) form.append("active", active.toString());

  const response = await axios.post(`${API_URL}/admin/create-module`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function updateModule({
  id,
  title,
  active,
  images,
  courseId,
}: {
  id: string;
  title: string;
  active?: boolean;
  images: File[];
  courseId: string;
}) {
  const form = new FormData();

  for (const image of images) {
    if (image instanceof File) {
      form.append("images", image);
    }
  }

  form.append("id", id);
  form.append("title", title);
  form.append("courseId", courseId);
  if (active) form.append("active", active.toString());

  const response = await axios.post(`${API_URL}/admin/update-module`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getAdminModule(id: string) {
  const response = await axios.get(`${API_URL}/admin/get-module/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
