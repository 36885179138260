import { RouterProvider } from "react-router-dom";
import "./App.css";
import { NextUIProvider, Spinner } from "@nextui-org/react";
import { Router } from "./routers/Router";
import { useApp } from "./store/app.store";
import { QueryClient, QueryClientProvider } from "react-query";
import { StatsigProvider } from "@statsig/react-bindings";
import { StatsigClient } from "@statsig/js-client";
import { runStatsigAutoCapture } from "@statsig/web-analytics";
import {
  FB_PIXEL_ID,
  FB_PIXEL_ID_NQ,
  STATSIG_ID,
} from "./constants/environment";
import CookieManager from "./components/CookieManager";
import ReactPixel from "react-facebook-pixel";
import { useMemo, useState } from "react";

function useAsyncClient(): { isLoading: boolean; client: StatsigClient } {
  const [isLoading, setIsLoading] = useState(true);

  const client = useMemo(() => {
    const instance = new StatsigClient(
      STATSIG_ID,
      {},
      {
        environment: {
          tier: import.meta.env.MODE,
        },
      },
    );

    instance
      .initializeAsync()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });

    return instance;
  }, []);

  runStatsigAutoCapture(client);

  return { client, isLoading };
}

ReactPixel.init(FB_PIXEL_ID, undefined, {
  autoConfig: true,
  debug: import.meta.env.DEV,
});
ReactPixel.init(FB_PIXEL_ID_NQ, undefined, {
  autoConfig: true,
  debug: import.meta.env.DEV,
});

const queryClient = new QueryClient();

function App() {
  const app = useApp();
  const { client, isLoading } = useAsyncClient();

  if (isLoading) {
    return (
      <div className="flex h-[100svh] items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <NextUIProvider>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <StatsigProvider client={client as any}>
        <QueryClientProvider client={queryClient}>
          <main
            className={`${app.theme === "dark" ? "dark" : ""} min-h-[100svh] bg-background text-foreground`}
          >
            <RouterProvider router={Router} />
            <CookieManager />
          </main>
        </QueryClientProvider>
      </StatsigProvider>
    </NextUIProvider>
  );
}

export default App;
