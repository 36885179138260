import { motion } from "framer-motion";
import { Button } from "../../components/Button";
import { useStatsigClient } from "@statsig/react-bindings";
import { Image } from "@nextui-org/react";

export default function Welcome({ onContinue }: { onContinue: () => void }) {
  const { logEvent } = useStatsigClient();

  return (
    <div className="container mx-auto max-w-screen-md px-2">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0, duration: 1 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mb-4 flex items-center justify-center"
      >
        <Image
          src="https://img.icons8.com/?size=400&id=SHXCSKAf4o5a&format=png&color=000000"
          alt="Nutriqueens"
          width={100}
          height={100}
        />
      </motion.div>
      <motion.h1
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="text-center text-4xl font-bold"
      >
        DÍJMENTES személyre szabott táplálkozási elemzés 🥗
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1, duration: 1 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        Válaszolj rövid kérdéseinkre, és derítsd ki, hogy hogyan tudunk segíteni
        neked!
      </motion.p>
      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1, duration: 1 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-4"
      >
        Az elemzés teljesen ingyenes és nem kötelez semmire.
      </motion.p>
      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 1 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        Az eredmények alapján e-mailben személyre szabott tanácsokat küldünk,
        hogy segítsünk elérni a céljaidat. 🎯
      </motion.p>
      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 1 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        Fontos megjegyezni, hogy ez az elemzés nem helyettesíti az orvosi
        vizsgálatot.
      </motion.p>
      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 1 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-4"
      >
        Az elemzést a Nutriqueens táplálkozási szakértője végzi.
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mb-4 mt-12"
      >
        <Button
          onClick={() => {
            onContinue();
            logEvent("free_analysis_started", "welcome");
          }}
          className="w-full"
          variant="solid"
          color="primary"
        >
          Vágjunk bele 😍
        </Button>
      </motion.div>
    </div>
  );
}
