import { useStatsigClient } from "@statsig/react-bindings";
import { useNavigate } from "react-router-dom";
import { usePayment } from "../../../store/payment.store";
import { SubscriptionType } from "../../../types";
import { IconPoint, IconShieldDollar, IconSquareX } from "@tabler/icons-react";
import { ROUTES } from "../../../constants/routes";
import { Divider } from "@nextui-org/react";
import { useAuth } from "../../../store/auth.store";
import { motion } from "framer-motion";

export default function Subscriptions({
  subscriptions,
}: {
  subscriptions: SubscriptionType[];
}) {
  const payment = usePayment();
  const navigate = useNavigate();
  const auth = useAuth();
  const { logEvent } = useStatsigClient();

  return (
    <section className="bg-gradient-to-b from-primary-100 py-8">
      <div className="container mx-auto mb-24 mt-8 max-w-5xl">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true, amount: 0.35 }}
        >
          <p className="text-center uppercase">
            A{" "}
            <span className="underline decoration-accent-500 decoration-2 underline-offset-[5px]">
              lehetőséget
            </span>{" "}
            mi adjuk de,
          </p>
          <h2 className="my-2 mb-12 w-full px-4 text-center text-5xl font-bold uppercase leading-tight">
            A döntés a tiéd!
          </h2>
        </motion.div>
        <div className="mt-6 flex w-full justify-center px-4"></div>
        <div className="mx-auto mt-12 flex w-full max-w-4xl flex-col-reverse gap-8 md:flex-row ">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true, amount: 0.35 }}
            className="group mx-auto flex w-full max-w-sm flex-col rounded-3xl bg-opacity-30 bg-gradient-to-b from-default-200 to-default-300 p-4 shadow-medium transition-transform duration-300 hover:-translate-y-2"
          >
            <div className="flex flex-col items-center justify-center p-3">
              <h1 className="text-center text-2xl font-bold uppercase">
                Tovább hitegeted magad
              </h1>
              <h2 className="mt-4 text-xl font-bold text-default-600">
                Ingyenes
              </h2>
            </div>
            <div className="flex flex-auto flex-col p-3">
              <ul>
                <li className="mb-2 flex flex-row items-center gap-4 space-x-2">
                  <IconPoint className="flex-shrink-0 stroke-default-700" />
                  Több kilót szedsz fel
                </li>
                <li className="mb-2 flex flex-row items-center gap-4 space-x-2">
                  <IconPoint className="flex-shrink-0 stroke-default-700" />
                  Kevesebbet mozogsz
                </li>
                <li className="mb-2 flex flex-row items-center gap-4 space-x-2">
                  <IconPoint className="flex-shrink-0 stroke-default-700" />
                  Rosszabbul alszol
                </li>
                <li className="mb-2 flex flex-row items-center gap-4 space-x-2">
                  <IconPoint className="flex-shrink-0 stroke-default-700" />
                  Többet stresszelsz
                </li>
                <li className="mb-2 flex flex-row items-center gap-4 space-x-2">
                  <IconPoint className="flex-shrink-0 stroke-default-700" />
                  Rosszabbul érzed magam
                </li>
              </ul>
            </div>
            <div className="flex flex-col p-3">
              <div className="focus:shadow-outline mx-auto transform rounded-full border-3 border-solid border-default-400 px-8 py-4 text-center font-bold text-opacity-75 shadow-lg transition duration-300 ease-in-out focus:outline-none lg:mx-0">
                Egészségtelen maradok
              </div>
            </div>
          </motion.div>
          {subscriptions?.length &&
            subscriptions?.map((sub: SubscriptionType) => {
              const getAmount = () => {
                if (payment.affiliateData) {
                  return (
                    sub.amount * (1 - payment.affiliateData?.percentage * 0.01)
                  );
                }
                return sub.amount;
              };

              const splitBenefits = sub.description?.split("\n");

              const benefits = splitBenefits?.map((benefit, index) => (
                <li
                  key={index}
                  className="mb-2 flex flex-row items-center gap-4 space-x-2"
                >
                  <IconPoint className="flex-shrink-0 stroke-primary-500" />
                  {benefit}
                </li>
              ));

              return (
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  viewport={{ once: true, amount: 0.35 }}
                  className="group mx-auto flex w-full max-w-sm cursor-pointer flex-col rounded-3xl bg-opacity-30 bg-gradient-to-b from-default-200 to-default-300 p-4 shadow-medium transition-transform duration-300 hover:-translate-y-2"
                  key={sub.id}
                  onClick={() => {
                    payment.setSubscription(sub);
                    if (auth.user) {
                      navigate(ROUTES.payment);
                      return;
                    }
                    logEvent("subscription_select", sub.name);
                    navigate(ROUTES.directPayment);
                  }}
                >
                  <div className="flex flex-col items-center justify-center p-3">
                    <h1 className="text-center text-2xl font-bold uppercase">
                      {sub.name}
                    </h1>
                    <h2 className="mt-4 text-xl font-bold text-secondary-600">
                      <span className="text-foreground/40 line-through decoration-1">
                        {" "}
                        {sub.compareAmount} Ft
                      </span>{" "}
                      {getAmount()} Ft
                    </h2>
                  </div>
                  <div className="flex flex-auto flex-col p-3">
                    <ul>{benefits}</ul>
                  </div>
                  <div className="flex flex-col p-3">
                    <Divider />
                    <div className="mt-6 flex w-full gap-4">
                      <IconSquareX className=" opacity-70" />
                      <p className="flex-grow text-left opacity-70">
                        Bármikor lemondható
                      </p>
                    </div>
                    <div className="mt-2 flex w-full gap-4">
                      <IconShieldDollar className="mx-auto text-primary-500" />
                      <p className="flex-grow text-left font-semibold text-primary-500">
                        {getAmount()} Ft / hó örökre
                      </p>
                    </div>
                    <div className="focus:shadow-outlin e z-50 mx-auto mt-6 transform rounded-full bg-gradient-to-r from-accent-300 to-primary-500 px-8 py-4 text-center font-semibold uppercase text-white shadow-lg transition duration-300 ease-in-out hover:scale-105 focus:outline-none lg:mx-0">
                      Belépek a Nutriqueens-be
                    </div>
                  </div>
                </motion.div>
              );
            })}
        </div>
      </div>
    </section>
  );
}
