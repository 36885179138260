import { useEffect } from "react";
import Layout from "../../components/Layout";
import { Spinner } from "@nextui-org/react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../constants/environment";
import { useAuth } from "../../store/auth.store";
import { ROUTES } from "../../constants/routes";
import ReactPixel from "react-facebook-pixel";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY, {
  locale: "hu",
});

export default function PaymentConfirmation() {
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  const clientSecret = new URLSearchParams(location.search).get(
    "payment_intent_client_secret",
  );

  ReactPixel.track("Purchase");

  ReactPixel.track("Subscribe");

  useEffect(() => {
    const getStripePaymentIntent = async () => {
      const stripe = await stripePromise;
      if (!stripe || !clientSecret) return;
      const { paymentIntent } =
        await stripe.retrievePaymentIntent(clientSecret);

      if (paymentIntent?.status === "succeeded") {
        auth.setUserStatus("ACTIVE");

        navigate(ROUTES.appRoot);
      }
    };

    getStripePaymentIntent();
  }, [clientSecret, navigate, auth]);

  return (
    <Layout classNames={{ main: "items-center justify-center flex" }}>
      <Spinner size="lg" />
    </Layout>
  );
}
