import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import Layout from "../components/Layout";
import { IconMoodSad2 } from "@tabler/icons-react";
import { ROUTES } from "../constants/routes";

export default function Error() {
  const navigate = useNavigate();

  return (
    <Layout showFooter>
      <div className="flex flex-col items-center justify-center h-full">
        <IconMoodSad2 size={100} />
        <h1 className="text-4xl font-bold text-center">Hiba történt</h1>
        <p className="text-center text-lg mt-4">
          Kérjük, próbálja meg később újra.
        </p>

        <Button className="mt-8" onClick={() => navigate(ROUTES.appRoot)}>
          Vissza a főoldalra
        </Button>
      </div>
    </Layout>
  );
}
