import Layout from "../components/Layout";

export default function Privacy() {
  return (
    <Layout showFooter>
      {/* <h1 className="mt-12 text-center text-2xl font-bold">
        Adatvédelmi és adatkezelési tájékoztató
      </h1>

      <p className="mt-8">
        A jelen Adatkezelési Tájékoztató Szabó Barbara egyéni vállalkozó
        (székhely: 6200 Kiskőrös, Agárhalom tanya 103. a továbbiakban:
        "Adatkezelő"), mint adatkezelő által a weboldalhoz, feliratkoztató
        oldalakhoz kapcsolódóan végzett adatkezelés szabályait és az érintettet
        megillető jogok szóló tájékoztatást tartalmazza. <br />
        Az Adatkezelési Tájékoztató a weboldal és landing oldalak használatához
        és a szolgáltatásaihoz kapcsolódó adatkezelésre terjed ki.
      </p>
      <p className="mt-8">
        Jelen Adatvédelmi Tájékoztató a természetes személyeknek a személyes
        adatok kezelése tekintetében történő védelmére és a személyes adatok
        szabad áramlására vonatkozó szabályokat állapít meg, így különösen a
        személyes adatok védelméhez fűződő jogokat védi a vonatkozó jogszabályok
        iránymutatásának megfelelően.
      </p>
      <p className="mt-8">
        Felhasználó: minden olyan személy, aki a weboldalt meglátogatja, a
        weboldal és/vagy az Üzemeltető által nyújtott szolgáltatásokat igénybe
        veszi. Az Adatkezelési Tájékoztató személyi hatálya tehát kiterjed a
        Weboldalon keresztül érdeklődő vagy vásárló természetes személyekre,
        továbbá a nem természetes személyek képviselőire, kapcsolattartóira. A
        személyi hatály kiterjed továbbá a Weboldal üzemeltetésével
        megbízottakra.
      </p>
      <p className="mt-8">
        Az Adatkezelési Tájékoztató hatálya: Jelen Adatkezelési Tájékoztató
        hatálya az Üzemeltető jelen weboldalhoz (továbbiakban: Weboldal)
        kapcsolódóan nyújtott szolgáltatásaira és adatkezeléseire terjed ki.
        Ellenkező kikötés hiányában az Adatkezelési Tájékoztató hatálya nem
        terjed ki azon szolgáltatásokra és adatkezelésekre, melyek a Weboldalon
        megjelenő, harmadik személyek által közzétett tartalomhoz kapcsolódnak,
        vagy azon weboldalak szolgáltatásaira és adatkezeléseire, melyekre a
        jelen Weboldalon található hivatkozás vezet.
      </p>
      <p className="mt-8">
        A jelen Adatkezelési Tájékoztatóban használatra kerülnek olyan fogalmak,
        amiket az alábbiakban értelmeznek:
      </p>
      <ul className="list-disc ml-6">
        <li>
          "személyes adat": azonosított vagy azonosítható természetes személyre
          ("érintett") vonatkozó bármely információ.
        </li>
        <li>
          "adatkezelés": a személyes adatokon vagy adatállományokon automatizált
          vagy nem automatizált módon végzett bármely művelet vagy műveletek
          összessége, így a gyűjtés, rögzítés, rendszerezés, tagolás, tárolás,
          átalakítás vagy megváltoztatás, lekérdezés, betekintés, felhasználás,
          közlés továbbítás, terjesztés vagy egyéb módon történő hozzáférhetővé
          tétel útján, összehangolás vagy összekapcsolás, korlátozás, törlés,
          illetve megsemmisítés;
        </li>
        <li>
          "adattovábbítás": az adat meghatározott harmadik személy számára
          történő hozzáférhetővé tétele.
        </li>
        <li>
          "adatfeldolgozás": az adatkezelő megbízásából vagy rendelkezése
          alapján eljáró adatfeldolgozó által végzett adatkezelési műveletek
          összessége.
        </li>
        <li>
          "adatállomány": az egy nyilvántartásban kezelt adatok összessége.
        </li>
      </ul>
      <p className="mt-8">
        Az adatkezelő/üzemeltető adatai: <br />
        Név: Szabó Barbara ev. <br />
        Székhely: 6200 Kiskőrös, Agárhalom tanya 103. <br />
        Nyilvántartási szám: 55437679 <br />
        Adószám: 56751974-1-23 <br />
        E-mail: hello@szabobarbara.hu <br />
        Bankszámlaszám: OTP Bank 11773432-01065497
        <br />
      </p>
      <h2 className="mt-8 font-semibold text-xl">
        Az Üzemeltetőre vonatkozó szabályok
      </h2>
      <h3 className="mt-4 font-medium text-lg">Adatkezelés</h3>
      <p className="mt-4">
        Az adatkezelés helye, az irányadó szabályozás A személyes adatok
        kezelése az Üzemeltető székhelyén vagy az Üzemeltető által megbízott
        adatfeldolgozó helyiségében történik. Az adatkezeléssel kapcsolatos
        döntéseket az Üzemeltető hozza meg. Az Üzemeltető, a 2.2. pontban
        felsorolt személyes adatok kezelése során, az adatok tárolásának és
        rendelkezésre állásának biztosítása érdekében, tárhely szolgáltatás
        céljából adatfeldolgozókat vehet igénybe. Az Üzemeltető gazdasági
        tevékenységet folytató természetes személy, az adatkezelés helye
        Magyarország.
      </p>
      <ul className="ml-6 list-disc">
        <li>
          Mindezek alapján a személyes adatok kezelésére a magyar jogszabályok,
          elsősorban az információs önrendelkezési jogról és az
          információszabadságról szóló 2011. évi CXII. törvény (a továbbiakban:
          Infotv.) szabályai az irányadók.
        </li>
        <li>
          Az Üzemeltető számára az adatkezelés során irányadó továbbá az Európai
          Parlament és a Tanács (EU) 2016/679. Rendeletét (2016. április 27.) a
          természetes személyeknek a személyes adatok kezelése tekintetében
          történő védelméről és az ilyen adatok szabad áramlásáról, valamint a
          95/46/EK rendelet hatályon kívül helyezéséről (általános adatvédelmi
          rendelet, a továbbiakban: GDPR rendelet).
        </li>
      </ul>
      <h3 className="mt-4 font-medium text-lg">
        Az adatkezelés jogalapja, köre, célja és elvei
      </h3>
      <p className="mt-4">
        A személyes adatok kezelője az Üzemeltető és munkatársai, akik kizárólag
        a feladataik elvégzéséhez feltétlenül szükséges mértékben kezelhetik az
        adatokat. Az Üzemeltető megfelelő információbiztonsági intézkedésekkel
        gondoskodik arról, hogy a Felhasználó személyes adatait védje többek
        között a jogosulatlan hozzáférés ellen vagy azok jogosulatlan
        megváltoztatása ellen. <br />A szervereken tárolt személyes adatokhoz
        való hozzáférés naplózásra kerül, ami alapján mindig ellenőrizhető, ki,
        mikor, milyen személyes adatokhoz fért hozzá. Az Üzemeltető megfelelő
        szervezési intézkedésekkel gondoskodik arról, hogy a személyes adatok ne
        válhassanak hozzáférhetővé meghatározatlan számú személy számára.
      </p>
      <p className="mt-4">
        Az adatkezelés jogalapja a Felhasználók önkéntesen megadott
        hozzájárulása. A Felhasználók a Weboldal használatával, valamint az
        esetleges regisztrációval, érdeklődés, jelentkezés elküldésével
        elfogadják jelen Adatkezelési Tájékoztatót.
      </p>
      <h4 className="font-medium mt-6">A kezelt adatok köre, annak célja:</h4>
      <ul className="ml-6 list-disc">
        <li>a Felhasználó teljes neve azonosítás</li>
        <li>e-mail cím kapcsolattartás</li>
        <li>számlázási cím számlázás</li>
      </ul> */}

      <h1 className="text-center mt-8">Feltöltés alatt...</h1>
    </Layout>
  );
}
