import { useState } from 'react'
import Layout from '../../components/Layout'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Input, Spacer, Spinner } from '@nextui-org/react';
import { Button } from '../../components/Button';
import { IconArrowLeft, IconEye, IconEyeClosed } from '@tabler/icons-react';
import { useMutation } from 'react-query';
import { changePassword, forgotPassword } from '../../api/auth.api';
import { useAuth } from '../../store/auth.store';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const schema = yup
  .object()
  .shape({
    currentPassword: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup.string().oneOf([yup.ref('password')], i18n.t("profile:changePassword.passwordMismatch")).required(),
  })
  .required()


export default function ChangePassword() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('profile');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { isLoading: isEmailLoading, mutate: sendPasswordEmail } = useMutation(forgotPassword, {
    onError: (error: {message: string}) => {
      form.setError("root", { message: error.message });
    },
    onSuccess: () => {
      navigate(ROUTES.profile.root, { state: { emailSent: true }, replace: true });
    }
  });

  const { isLoading: isChangeLoading, mutate: changeUserPassword } = useMutation(changePassword, {
    onError: (error: {message: string}) => {
      form.setError("root", { message: error.message });
    },
    onSuccess: () => {
      navigate(ROUTES.profile.root, { state: { passwordReset: true }, replace: true });
    }
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    handleSubmit((data) => {
      changeUserPassword({
        oldPassword: data.currentPassword,
        newPassword: data.password,
      });
    })();
  }

  if (isEmailLoading) {
    return (
      <Layout>
        <div className="max-w-screen-sm mx-auto pt-4 flex flex-col items-center">
          <h1 className='text-center font-bold text-2xl'>{t("changePassword.title")}</h1>
          <Spacer y={8}/>
          <Spinner color="primary" />
        </div>
      </Layout>
    )
  }

  return (
    <Layout showFooter>
      <div className="max-w-screen-sm mx-auto pt-4 flex flex-col items-center">
        <div className='flex items-center gap-2 pb-4 pt-2 cursor-pointer opacity-70 w-min self-start' onClick={() => navigate(ROUTES.profile.root)}>
          <IconArrowLeft size={24} />
          <span className='whitespace-nowrap'>{t("changePassword.back")}</span>
        </div>
        <h1 className='text-center font-bold text-2xl'>{t("changePassword.title")}</h1>
        <Spacer y={8}/>
        <form className='w-full' onSubmit={onSubmit}>
          <Controller
            name="currentPassword"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                endContent={
                  isPasswordVisible ? (
                    <IconEye className="cursor-pointer self-center" onClick={() => setIsPasswordVisible(false)} />
                  ) : (
                    <IconEyeClosed className="cursor-pointer self-center" onClick={() => setIsPasswordVisible(true)} />
                  )
                }
                type={isPasswordVisible ? 'text' : 'password'}
                autoComplete="current-password"
                label={t("changePassword.currentPassword")}
                size="sm"
                color="primary"
                variant="bordered"
                classNames={{
                  base: 'w-full',
                }}
                errorMessage={form.formState.errors.password?.message}
              />
            )}
          />
          <Spacer y={1} />
          <span
            className="cursor-pointer opacity-70 text-sm pl-2"
            onClick={() => sendPasswordEmail({ email: auth.user!.email! })}
          >
            {t("changePassword.forgotPassword")}
          </span>
          <Spacer y={3} />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                endContent={
                  isPasswordVisible ? (
                    <IconEye className="cursor-pointer self-center" onClick={() => setIsPasswordVisible(false)} />
                  ) : (
                    <IconEyeClosed className="cursor-pointer self-center" onClick={() => setIsPasswordVisible(true)} />
                  )
                }
                type={isPasswordVisible ? 'text' : 'password'}
                label={t("changePassword.newPassword")}
                size="sm"
                color="primary"
                variant="bordered"
                errorMessage={form.formState.errors.password?.message}
              />
            )}
          />
          <Spacer y={4} />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                endContent={
                  isPasswordVisible ? (
                    <IconEye className="cursor-pointer self-center" onClick={() => setIsPasswordVisible(false)} />
                  ) : (
                    <IconEyeClosed className="cursor-pointer self-center" onClick={() => setIsPasswordVisible(true)} />
                  )
                }
                type={isPasswordVisible ? 'text' : 'password'}
                label={t("changePassword.confirmPassword")}
                size="sm"
                color={form.formState.errors.confirmPassword ? "danger" : "primary"}
                variant="bordered"
                isInvalid={!!form.formState.errors.confirmPassword}
                classNames={{
                  errorMessage: "text-danger"

                }}
                errorMessage={form.formState.errors.confirmPassword?.message}
              />
            )}
          />
          <Spacer y={4} />
          {form.formState.errors.root && (
            <>
              <p className="text-red-500 text-sm text-center">{form.formState.errors.root.message}</p>
              <Spacer y={3} />
            </>
          )}
          <Spacer y={4} />
          <Button
            variant='solid'
            color="primary"
            size='lg'
            className='w-full'
            type="submit"
            isLoading={isChangeLoading}
            isDisabled={isChangeLoading || form.formState.isSubmitting || !form.formState.isValid}
          >
            {t("changePassword.save")}
          </Button>
        </form>
      </div>
    </Layout>
  )
}
