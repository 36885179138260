import { Input } from '@nextui-org/react'
import { AnimatePresence } from 'framer-motion'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { checkAfiliate } from '../../api/payment.api'
import { motion } from 'framer-motion'
import { useSearchParams } from 'react-router-dom'
import { usePayment } from '../../store/payment.store'
import { useTranslation } from 'react-i18next'

export default function PromoCode() {
  const [params] = useSearchParams();
  const [afiliate, setAfiliate] = useState('')
  const [errorCounter, setErrorCounter] = useState(0);
  const [success, setSuccess] = useState(false);
  const payment = usePayment();
  const {t} = useTranslation('payment');

  const hideError = useMemo(() => {
    return debounce(() => {
      setErrorCounter(0);
    }, 5000)
  }, [setErrorCounter])

  const { mutate: checkCode } = useMutation(checkAfiliate, {
    onSuccess: (data) => {
      payment.setAffiliateData(data);
      if (params.get('afiliate') !== afiliate) {
        // navigate(ROUTES.getSubscriptions({code: afiliate}), { replace: true  })
        setSuccess(true)
        setErrorCounter(0)
        setTimeout(() => {
          setSuccess(false)
        }, 3000)
      }
    },
    onError: () => {
      // navigate(ROUTES.getSubscriptions({error: 'invalid-code'}))
      setAfiliate(''); // Reset afiliate code
      payment.setAffiliateData(null);
      setErrorCounter(errorCounter + 1)
      setSuccess(false);
      hideError()
    }
  })
  
  const checkRequest = useCallback((value: string) => {
    checkCode(value)
  }, [checkCode])

  const debouncedCheckCode = useMemo(() => {
    return debounce(checkRequest, 500)
  }, [checkRequest])

  const onCodeChange = (value: string) => {
    setAfiliate(value)

    debouncedCheckCode(value)
  }

  useEffect(() => {
    if (params.has('afiliate')) {
      setAfiliate(params.get('afiliate') || '')
      checkCode(params.get('afiliate') || '')
    }
  }, [checkCode, params, setAfiliate]);

  useEffect(() => {
    if (payment.affiliateData?.code) {
      setAfiliate(payment.affiliateData.code)
    }
  }, [payment.affiliateData?.code, setAfiliate])

  return (
    <>
      <Input
        label={t("promo.label")}
        variant='bordered' 
        color='primary' 
        className='w-full max-w-sm mx-auto mt-6'
        value={afiliate}
        onChange={(e) => onCodeChange(e.target.value)}
      />
      <AnimatePresence mode="sync">
        {errorCounter > 0 && (
          <motion.div
            key={errorCounter}
            className={`fixed z-10 ${ errorCounter > 1 ? 'top-[70px]' : 'top-0'} bg-danger-200 py-3 rounded-xl w-full max-w-sm mx-auto mt-4 border-2 border-solid border-danger-700`}
            whileInView={errorCounter > 1 ? { translateX: [20, -20, 10, -10, 5, -5, 0]} : { translateY: 70 }}
            exit={{ opacity: 0 }}
          >
            <h1 className='text-center font-semibold opacity-70 text-lg'>{t("promo.invalid")}</h1>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence mode="sync">
        {success && (
          <motion.div
            className={`fixed z-10 top-0 bg-success-200 py-3 rounded-xl w-full max-w-sm mx-auto mt-4 border-2 border-solid border-success-700`}
            whileInView={{ translateY: 70 }}
            exit={{ opacity: 0 }}
          >
            <h1 className='text-center font-semibold opacity-70 text-lg'>{t("promo.valid")}</h1>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
