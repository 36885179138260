import { Button as ButtonNextUI, extendVariants } from "@nextui-org/react";

export const Button = extendVariants(ButtonNextUI, {
  variants: {
    color: {
      accent: 'bg-accent',
      secondary: 'bg-secondary text-foreground',
      defaultPrimary: 'bg-transparent text-foreground hover:bg-primary-100',
      dangerPrimary: 'bg-transparent text-foreground hover:text-danger hover:bg-danger-50',
    }
  }
})