import { IconCopyright } from "@tabler/icons-react";

export default function Footer({ shadow = true }: { shadow?: boolean }) {
  return (
    <div
      className={`min-h-20 w-full bg-primary-100 ${shadow ? "shadow-medium" : ""}`}
    >
      <div className="mx-auto max-w-screen-lg px-4 pt-4 text-center">
        <h1 className="inline-flex items-center gap-1 text-xl">
          NutriQueens
          <IconCopyright size={15} /> 2024
        </h1>
        <div className="mx-auto flex max-w-screen-lg flex-col justify-center gap-6 px-4 pb-8 pt-4 md:flex-row md:justify-between">
          <div className="flex flex-col items-center md:items-start">
            <h2 className="text-lg font-semibold">Kontakt:</h2>
            <p className="text-md">
              <a href="mailto:hello@nutriqueens.hu">hello@nutriqueens.hu</a>
            </p>
            {/* <h2 className="mt-4 text-lg font-semibold">Cím:</h2>
            <p className="text-md ">6200 Kiskőrös, Agárhalom tanya 103.</p> */}
          </div>
          <div className="text-md flex flex-col items-center gap-1 md:items-start">
            <h2 className="pb-2 text-lg font-semibold">Hasznos linkek:</h2>
            <a href="/login">Bejelentkezés</a>
            <a href="/register">Regisztráció</a>
            <a href="/terms">ÁSZF</a>
            <a href="/privacy">Adatvédelmi irányelvek</a>
          </div>
        </div>
      </div>
    </div>
  );
}
