import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getSavedRecipes } from "../../../api/app/recipes/recipes.api";
import Layout from "../../../components/Layout";
import {
  Card,
  CardBody,
  CardFooter,
  Image,
  Spacer,
  Spinner,
} from "@nextui-org/react";
import { RecipeType } from ".";
import { ROUTES } from "../../../constants/routes";
import { getImageUrl } from "../../../utils/images.utils";

export default function SavedRecipes() {
  const navigate = useNavigate();

  const { isLoading: recipesIsLoading, data: recipes } = useQuery(
    ["savedRecipes"],
    () => getSavedRecipes(),
  );

  if (recipesIsLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter>
      <h1 className="text-2xl font-semibold opacity-75 mt-6 mb-4">
        Mentett Receptek
      </h1>
      <Spacer y={8} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:grid-cols-3">
        {recipes?.map((recipe: RecipeType) => (
          <Card
            key={recipe.id}
            isPressable
            isHoverable
            disableRipple
            classNames={{
              base: "overflow-hidden",
            }}
            onPress={() =>
              navigate(ROUTES.recipes.getRecipe({ id: recipe.id }))
            }
          >
            <CardBody className="overflow-hidden p-0 realtive">
              <Image
                src={getImageUrl(recipe.image)}
                alt={recipe.title}
                radius="none"
                classNames={{
                  img: "w-full",
                  wrapper: "w-full !max-w-full h-40 md:h-64 flex items-center",
                }}
              />
            </CardBody>
            <CardFooter className="flex flex-col items-start z-10">
              <h1 className="text-xl font-medium">{recipe.title}</h1>
              <Spacer y={1} />
              <p className="text-sm opacity-75">{recipe.description}</p>
            </CardFooter>
          </Card>
        ))}
      </div>
      <Spacer y={8} />
    </Layout>
  );
}
