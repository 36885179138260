import { Image } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { IconChevronRight } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { useStatsigClient } from "@statsig/react-bindings";
import { SubscriptionType } from "../../../types";
import { usePayment } from "../../../store/payment.store";
import { useAuth } from "../../../store/auth.store";

const services = [
  {
    title: "Tudastár",
    description:
      "Videóink rövidek, így könnyen tanulhatsz az egészséges életmódról.",
    imageUrl:
      "https://img.icons8.com/?size=400&id=WVpfDgrm4FVz&format=png&color=000000",
    href: ROUTES.presentation.kBase,
  },
  {
    title: "Receptek",
    description:
      "Könnyen elkészíthető recepteket találsz, amiket az egész család imádni fog.",
    imageUrl:
      "https://img.icons8.com/?size=400&id=RfjEvjsEaTgK&format=png&color=000000",
    href: ROUTES.presentation.recipes,
  },
  {
    title: "Elismert szakértők",
    description:
      "Szakértőink mindent elmondanak, amit tudni kell, hogy elérd a céljaidat.",
    imageUrl:
      "https://img.icons8.com/?size=400&id=S0GwdstGymiH&format=png&color=000000",
    href: ROUTES.presentation.professionals,
  },
];

export default function Services({
  subscriptions,
}: {
  subscriptions: SubscriptionType[];
}) {
  const payment = usePayment();
  const navigate = useNavigate();
  const auth = useAuth();
  const { logEvent } = useStatsigClient();

  return (
    <section className="bg-gradient-to-b from-background to-primary-100 py-12 pb-36 md:from-55% md:to-55%">
      <div className="container mx-auto px-6 text-center">
        <motion.h2
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true, amount: 0.35 }}
          className="my-2 mb-12 w-full text-center text-5xl font-bold leading-tight"
        >
          A Megoldás, ami{" "}
          <span className="inline-block bg-gradient-to-r from-primary-600 to-accent-400 bg-clip-text text-transparent">
            Működik
          </span>
          !
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true, amount: 0.35 }}
          className="mb-12 text-lg leading-normal"
        >
          Fedezd fel, hogy a valódi megoldások nem a gyors diétákban vagy
          csodatablettákban rejlenek, hanem a kiegyensúlyozott életmódban.
        </motion.p>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-3">
          {services.map((service, index) => (
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true, amount: 0.35 }}
              key={index}
              onClick={() => {
                navigate(service.href);
                logEvent("learn_more", service.title);
              }}
              className="relative cursor-pointer rounded-2xl bg-background-100 p-6 shadow-md transition-shadow duration-300 hover:shadow-xl"
            >
              <Image
                src={service.imageUrl}
                alt={service.title}
                classNames={{
                  img: "h-32 w-32 object-cover",
                  wrapper: "mb-4 mx-auto",
                }}
              />
              <h3 className="mb-2 min-h-16 text-xl font-semibold">
                {service.title}
              </h3>
              <p className="">{service.description}</p>
              <div className="absolute bottom-0 right-0 top-0 flex items-center justify-center">
                <IconChevronRight className="h-12 w-12 translate-x-4 rounded-full bg-primary-300 p-2 text-secondary-foreground" />
              </div>
            </motion.div>
          ))}
        </div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true, amount: 0.35 }}
          className="mt-8"
        >
          <button
            onClick={() => {
              payment.setSubscription(subscriptions[0]);
              if (auth.user) {
                navigate(ROUTES.payment);
                return;
              }
              logEvent("cta_clicked", "from_services");
              navigate(ROUTES.directPayment);
            }}
            className="focus:shadow-outline z-50 mx-auto mt-6 transform cursor-pointer rounded-full bg-gradient-to-r from-accent-300 to-primary-500 px-8 py-4 font-semibold uppercase text-white shadow-lg transition duration-300 ease-in-out hover:scale-105 focus:outline-none lg:mx-0"
          >
            Belépek a Nutriqueens-be
          </button>
        </motion.div>
      </div>
    </section>
  );
}
