import { API_URL } from "../../constants/environment";
import { axios } from "../axios";

export async function getAnalysis() {
  const response = await axios.get(`${API_URL}/admin/analysis`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getAnalysisById(id: string) {
  const response = await axios.get(`${API_URL}/admin/analysis/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function uploadAnalysis({
  videos,
  id,
}: {
  videos: File[];
  id: string;
}) {
  const form = new FormData();
  for (const video of videos) {
    if (video instanceof File) {
      form.append("images", video);
    }
  }
  form.append("id", id);

  const response = await axios.post(`${API_URL}/admin/analysis`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function sendAnalysis({ id }: { id: string }) {
  const response = await axios.post(`${API_URL}/admin/analysis/send`, { id });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
