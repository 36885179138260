import { RouteObject } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { RequireAuth } from "./RequireAuth";
import { RequireSubscription } from "./RequireSubscription";
import RecipesRoot from "../pages/app/recipes";
import Recipe from "../pages/app/recipes/recipe";
import SavedRecipes from "../pages/app/recipes/saved";

export const RecipesRouter: RouteObject[] = [
  {
    path: ROUTES.recipes.root,
    element: (
      <RequireAuth>
        <RequireSubscription>
          <RecipesRoot />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.recipes.recipe,
    element: (
      <RequireAuth>
        <RequireSubscription>
          <Recipe />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.recipes.saved,
    element: (
      <RequireAuth>
        <RequireSubscription>
          <SavedRecipes />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
];
