import { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../../components/Layout";
import { useQuery } from "react-query";
import { getRecipes, getTags } from "../../../api/app/recipes/recipes.api";
import {
  Card,
  CardBody,
  CardFooter,
  Chip,
  Image,
  Input,
  Spacer,
  Spinner,
} from "@nextui-org/react";
import { IconPencilPlus, IconSearch } from "@tabler/icons-react";
import { getImageUrl } from "../../../utils/images.utils";
import { debounce } from "lodash";
import { ROUTES } from "../../../constants/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../store/auth.store";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";

type Tag = {
  id: number;
  name: string;
  recipeId: string;
  tagId: number;
};

export type RecipeType = {
  id: string;
  title: string;
  description: string;
  image: string;
  status: string;
  time: number;
  servings: number;
  instructionsUrl: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  userId: string;
  tags: Tag[];
};

const AddButton = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation("recipes");

  if (auth.user!.role === "ADMIN" || auth.user!.role === "OWNER") {
    return (
      <div className="flex justify-center items-center pt-3 cursor-pointer opacity-70">
        <Button
          startContent={<IconPencilPlus />}
          variant="light"
          color="default"
          onClick={() => navigate(ROUTES.admin.recipes.newRecipe)}
        >
          {t("new")}
        </Button>
      </div>
    );
  }

  return null;
};

export default function RecipesRoot() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [skip] = useState(0);

  const { isLoading: isTagsLoading, data: tags } = useQuery("tags", () =>
    getTags(),
  );

  const { isLoading: recipesIsLoading, data: recipes } = useQuery(
    ["recipes", selectedTags, searchQuery, skip, tags],
    () =>
      getRecipes({
        selectedTags:
          tags
            .filter((tag: Tag) => selectedTags.includes(tag.name))
            ?.map((tag: Tag) => tag.id) || undefined,
        searchQuery,
        skip,
      }),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (params.has("tags")) {
      const tagsValue = params.get("tags");
      if (tagsValue) {
        setSelectedTags(tagsValue.split(","));
      }
    }
  }, [params]);

  const setSearchTerm = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery],
  );

  const debouncedSearch = useMemo(() => {
    return debounce(setSearchTerm, 500);
  }, [setSearchTerm]);

  const onSearch = (value: string) => {
    setSearchInput(value);
    debouncedSearch(value);
  };

  const handleTagSelect = (tagName: string) => {
    setSelectedTags((prev) => [...prev, tagName]);
    setParams((prev) => {
      const existingTags = prev.get("tags");
      return { tags: existingTags ? `${existingTags},${tagName}` : tagName };
    });
  };

  const handleTagRemove = (tagName: string) => {
    setSelectedTags((prev) => prev.filter((tag) => tag !== tagName));
    setParams(
      (prev) => {
        const existingTags = prev
          .get("tags")
          ?.split(",")
          .filter((tag) => tag !== tagName);
        if (!existingTags?.length) {
          prev.delete("tags");
          return prev;
        }
        return { tags: existingTags.join(",") };
      },
      { replace: true },
    );
  };

  if (recipesIsLoading || isTagsLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter>
      <AddButton />
      <h1 className="text-2xl font-semibold opacity-75 mt-6 mb-4">Receptek</h1>
      <div className="flex flex-col gap-4">
        <Input
          label="Keresés"
          variant="bordered"
          color="primary"
          size="sm"
          endContent={<IconSearch className="self-center" />}
          value={searchInput}
          onChange={(e) => onSearch(e.target.value)}
        />
        <div className="flex gap-2 flex-wrap">
          {selectedTags.length > 0 &&
            selectedTags.map((selectedTag) => (
              <Chip
                key={selectedTag}
                color="primary"
                className="!cursor-pointer"
                variant="solid"
                isCloseable
                onClick={() => handleTagRemove(selectedTag)}
              >
                {selectedTag}
              </Chip>
            ))}
          {tags
            ?.filter(
              (tag: { id: string; name: string }) =>
                !selectedTags.includes(tag.name),
            )
            ?.map((tag: { id: string; name: string }) => (
              <Chip
                key={tag.id}
                color="primary"
                className="!cursor-pointer"
                variant="bordered"
                onClick={() => handleTagSelect(tag.name)}
              >
                {tag.name}
              </Chip>
            ))}
        </div>
      </div>
      <Spacer y={8} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:grid-cols-3">
        {recipes?.map((recipe: RecipeType) => (
          <Card
            key={recipe.id}
            isPressable
            isHoverable
            disableRipple
            classNames={{
              base: "overflow-hidden",
            }}
            onPress={() =>
              navigate(ROUTES.recipes.getRecipe({ id: recipe.id }))
            }
          >
            <CardBody className="overflow-hidden p-0 realtive">
              {recipe.status === "INACTIVE" && (
                <Chip color="default" className="absolute top-2 left-2 z-20">
                  Nem kozetett
                </Chip>
              )}
              <Image
                src={getImageUrl(recipe.image)}
                alt={recipe.title}
                radius="none"
                classNames={{
                  img: "w-full",
                  wrapper: "w-full !max-w-full h-40 md:h-64 flex items-center",
                }}
              />
            </CardBody>
            <CardFooter className="flex flex-col items-start z-10">
              <h1 className="text-xl font-medium">{recipe.title}</h1>
              <Spacer y={1} />
              <p className="text-sm opacity-75">{recipe.description}</p>
            </CardFooter>
          </Card>
        ))}
      </div>
      <Spacer y={8} />
    </Layout>
  );
}
