import { RouteObject } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { RequireAuth } from "./RequireAuth";
import { RequireSubscription } from "./RequireSubscription";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import ForgotPassword from "../pages/auth/forgotPassword";
import ConfirmEmail from "../pages/auth/confirmEmail";
import ResetPassword from "../pages/auth/resetPassword";
import Subscriptions from "../pages/payment/subscriptions";
import Payment from "../pages/payment/payment";
import PaymentConfirmation from "../pages/payment/paymentConfirmation";
import RetryPayment from "../pages/payment/retryPayment";
import DirectPayment from "../pages/payment/directPayment";

export const AuthRouter: RouteObject[] = [
  {
    path: ROUTES.login,
    element: (
      <RequireAuth reverse>
        <Login />
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.register,
    element: (
      <RequireAuth reverse>
        <Register />
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.forgotPassword,
    element: (
      <RequireAuth reverse>
        <ForgotPassword />
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.confirmEmail,
    element: (
      <RequireAuth reverse>
        <ConfirmEmail />
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.resetPassword,
    element: (
      <RequireAuth reverse>
        <ResetPassword />
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.subsciptions,
    element: (
      <RequireSubscription reverse>
        <Subscriptions />
      </RequireSubscription>
    ),
  },
  {
    path: ROUTES.directPayment,
    element: (
      <RequireAuth reverse>
        <RequireSubscription reverse>
          <DirectPayment />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.payment,
    element: (
      <RequireAuth>
        <RequireSubscription reverse>
          <Payment />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.paymentConfirmation,
    element: (
      <RequireAuth>
        <RequireSubscription reverse>
          <PaymentConfirmation />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.retryPayment,
    element: (
      <RequireAuth>
        <RequireSubscription reverse isRetry>
          <RetryPayment />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
];
