import { ReactNode } from 'react'
import Header from './Header'
import Footer from './Footer';

interface LayoutProps {
  children: ReactNode;
  padding?: boolean;
  headerVariant?: "simple" | "full";
  classNames?: {
    main?: string;
  }
  showFooter?: boolean;
}

export default function Layout({ children, padding = true, headerVariant, classNames, showFooter }: LayoutProps) {
  return (
    <div className='flex flex-col min-h-[100svh]'>
      <Header variant={headerVariant}/>
      <main className={`flex-1 max-w-screen-xl w-full mx-auto ${padding && 'px-4'} ${classNames?.main}`}>{children}</main>
      {showFooter && <Footer />}
    </div>
  )
}
