import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { Button } from "../../components/Button";
import { Textarea } from "@nextui-org/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { QuizType } from "../../api/quiz";
import { useStatsigClient } from "@statsig/react-bindings";

const schema = yup
  .object()
  .shape({
    breakfast: yup.string().required("Kérlek add meg a reggelid"),
    lunch: yup.string().required("Kérlek add meg az ebéded"),
    dinner: yup.string().required("Kérlek add meg a vacsorád"),
    snacks: yup.string().required("Kérlek add meg a nasiid"),
  })
  .required();

export default function StepSix({
  onContinue,
  data,
}: {
  onContinue: (data: {
    breakfast: string;
    lunch: string;
    dinner: string;
    snacks: string;
  }) => void;
  data: QuizType;
}) {
  const { logEvent } = useStatsigClient();
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = () => {
    logEvent("free_analysis_step", "six");
    handleSubmit((data) => {
      onContinue(data);
    })();
  };

  useEffect(() => {
    if (
      data?.eating?.breakfast &&
      data?.eating?.lunch &&
      data?.eating?.dinner &&
      data?.eating?.snacks
    ) {
      form.setValue("breakfast", data?.eating?.breakfast, {
        shouldValidate: true,
      });
      form.setValue("lunch", data?.eating?.lunch, { shouldValidate: true });
      form.setValue("dinner", data?.eating?.dinner, { shouldValidate: true });
      form.setValue("snacks", data?.eating?.snacks, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="container mx-auto mt-4 max-w-screen-md px-2">
      <motion.h1
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="text-center text-2xl font-bold"
      >
        Étkezés
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        Írd le konkrétan, hogy a tegnapi nap folyamán mit ettél! Minnel
        részletesebben írod le, annál inkább tudunk segíteni!
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-2"
      >
        <Controller
          name="breakfast"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              label="Reggeli"
              size="lg"
              color="primary"
              variant="underlined"
              isInvalid={!!form.formState.errors.breakfast?.message}
              errorMessage={form.formState.errors.breakfast?.message}
            />
          )}
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-2"
      >
        <Controller
          name="lunch"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              label="Ebéd"
              size="lg"
              color="primary"
              variant="underlined"
              isInvalid={!!form.formState.errors.lunch?.message}
              errorMessage={form.formState.errors.lunch?.message}
            />
          )}
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.9, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-2"
      >
        <Controller
          name="dinner"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              label="Vacsora"
              size="lg"
              color="primary"
              variant="underlined"
              isInvalid={!!form.formState.errors.dinner?.message}
              errorMessage={form.formState.errors.dinner?.message}
            />
          )}
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.1, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-2"
      >
        <Controller
          name="snacks"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              label="Nasi"
              size="lg"
              color="primary"
              variant="underlined"
              isInvalid={!!form.formState.errors.snacks?.message}
              errorMessage={form.formState.errors.snacks?.message}
            />
          )}
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.3, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        <Button
          onClick={onSubmit}
          className="w-full"
          variant="shadow"
          color="primary"
          isDisabled={!form.formState.isValid}
          size="lg"
        >
          Tovább
        </Button>
      </motion.div>
    </div>
  );
}
