import { IconShieldCheckFilled } from "@tabler/icons-react";
import { motion } from "framer-motion";

export default function Refund() {
  return (
    <motion.section
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
      viewport={{ once: true, amount: 0.35 }}
      className="container mx-auto mb-8 mt-24 flex flex-col items-center justify-center px-4"
    >
      <div className="mt-8 flex max-w-screen-sm flex-col items-center justify-center gap-8 rounded-xl bg-gradient-to-t from-accent-300 to-secondary-100 p-8 shadow-xl md:flex-row">
        <IconShieldCheckFilled
          size={100}
          className="min-h-24 min-w-24 rounded-full border-4 border-solid border-foreground p-2"
        />
        <div>
          <h2 className="text-center text-3xl font-bold">
            Aggódsz, hogy megéri-e a NutriQueens?
          </h2>
          <p className="mt-8 text-lg">
            Ha bármilyen okból mégsem lennél elégedett, és ezt a vásárlást
            követő 14 napon belül jelzed nekünk e-mailben, akkor visszafizetjük
            a <span className="font-bold">teljes</span> díjat.
          </p>
        </div>
      </div>
    </motion.section>
  );
}
