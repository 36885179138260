import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { Button } from "../../components/Button";
import { Textarea } from "@nextui-org/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { QuizType } from "../../api/quiz";
import { useStatsigClient } from "@statsig/react-bindings";

const schema = yup
  .object()
  .shape({
    diagnostic: yup.string(),
  })
  .required();

export default function StepThree({
  onContinue,
  data,
}: {
  onContinue: (data: object) => void;
  data: QuizType;
}) {
  const { logEvent } = useStatsigClient();
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = () => {
    logEvent("free_analysis_step", "three");
    handleSubmit((data) => {
      onContinue(data);
    })();
  };

  useEffect(() => {
    if (data?.diagnostic) {
      form.setValue("diagnostic", data?.diagnostic, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="container mx-auto mt-4 max-w-screen-md px-2">
      <motion.h1
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="text-center text-2xl font-bold"
      >
        Betegségek és ételallergiák
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        Van bármilyen diagnosztizált betegséged/ételallergiád? Ha igen, kérlek,
        írd le konkrétan
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-2"
      >
        <Controller
          name="diagnostic"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              label="Betegségek és ételallergiák"
              size="lg"
              color="primary"
              variant="underlined"
              isInvalid={!!form.formState.errors.diagnostic?.message}
              errorMessage={form.formState.errors.diagnostic?.message}
            />
          )}
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        <Button
          onClick={onSubmit}
          className="w-full"
          variant="shadow"
          color="primary"
          isDisabled={!form.formState.isValid}
          size="lg"
        >
          Tovább
        </Button>
      </motion.div>
    </div>
  );
}
