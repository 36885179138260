import { Card, CardBody, CardHeader, Spacer } from "@nextui-org/react";
import { IconPoint } from "@tabler/icons-react";
// import { useNavigate } from "react-router-dom";
// import { ROUTES } from "../../constants/routes";
import PromoCode from "./PromoCode";
import { SubscriptionType } from "../../types";
import { usePayment } from "../../store/payment.store";
import { useTranslation } from "react-i18next";

export default function SelectedSidebar({
  subscription,
}: {
  subscription: SubscriptionType;
}) {
  // const navigate = useNavigate();
  const payment = usePayment();
  const { t } = useTranslation("payment");

  const getAmount = () => {
    if (payment.affiliateData) {
      return (
        subscription.amount * (1 - payment.affiliateData?.percentage * 0.01)
      );
    }
    return subscription.amount;
  };

  const splitBenefits = subscription.description?.split("\n");

  const benefits = splitBenefits?.map((benefit, index) => (
    <li key={index} className="mb-2 flex flex-row items-center space-x-2">
      <IconPoint className="flex-shrink-0 stroke-primary" />
      {benefit}
    </li>
  ));

  return (
    <Card
      classNames={{
        base: "mt-6 w-full md:mt-0 md:min-h-[calc(100svh-64px)] md:rounded-none py-2",
        body: "flex flex-col justify-center items-center",
      }}
    >
      <CardBody>
        <h1 className="mb-8 text-center text-xl font-semibold">
          {t("subscriptions.selected")}
        </h1>
        <Card
          classNames={{
            base: "w-full max-w-sm bg-gradient-to-b from-primary-100 to-accent-200 bg-opacity-30 mx-auto rounded-3xl p-4",
            header: "justify-center flex-col",
            footer: "justify-center",
          }}
        >
          <CardHeader>
            <h1 className="text-lg font-semibold">{subscription.name}</h1>
            <h2 className="mt-2 text-sm line-through opacity-50">
              {subscription.compareAmount} Ft
            </h2>
            <h2 className="text-xl font-bold text-secondary-600">
              {getAmount()} Ft
            </h2>
          </CardHeader>
          <CardBody>
            <ul>{benefits}</ul>
          </CardBody>
        </Card>
        {/* <span
          className="text-sm opacity-50 text-center mt-6 cursor-pointer"
          onClick={() => navigate(ROUTES.subsciptions)}
        >
          {t("subscriptions.change")}
        </span> */}
        <Spacer y={2} />
        <PromoCode />
      </CardBody>
    </Card>
  );
}
