import { Button } from "./Button";
import { useCookies } from "react-cookie";
// import { motion } from 'framer-motion'

export default function CookieManager() {
  const [cookies, setCookie] = useCookies(["nq-consent"]);

  const acceptCookies = () => {
    setCookie("nq-consent", true, {
      path: "/",
      expires: new Date(2147483647 * 1000),
    });
  };

  if (cookies["nq-consent"]) {
    return null;
  }

  return (
    <div className="fixed w-screen left-0 bottom-0 bg-accent-100 shadow-lg z-50">
      <div className="w-full max-w-screen-xl px-4 mx-auto flex flex-col">
        <h1 className="font-semibold text-2xl mt-8">
          Ez az oldal sütiket használ
        </h1>

        <p className="mt-4">
          A weboldalunk sütiket használ, hogy jobb böngészési élményt nyújtson
          Önnek. A sütik elengedhetetlenek a weboldal funkcióinak
          biztosításához, a felhasználói viselkedés elemzéséhez és a tartalom
          testre szabásához. A weboldal használatával elfogadja a sütik
          használatát.
        </p>
        <Button
          className="mt-4 mb-8"
          variant="solid"
          color="primary"
          onClick={acceptCookies}
        >
          Elfogadom
        </Button>
      </div>
    </div>
  );
}
