import { Image } from "@nextui-org/react";
import { motion } from "framer-motion";

const SOLUTIONS = [
  {
    image:
      "https://img.icons8.com/?size=400&id=udLNEDpg6Ek7&format=png&color=000000",
    title: "Csodadiéták?",
    description: (
      <>
        <p className="text-center">Kezdetben gyors eredményeket ígérnek.</p>
        <p className="mt-4 text-center">
          <span className="font-bold">De</span> hosszú távon visszatérsz a
          kiindulóponthoz... vagy még felszedsz 20 kilót.
        </p>
      </>
    ),
  },
  // {
  //   image:
  //     "https://img.icons8.com/?size=400&id=yOfOLQrIJWja&format=png&color=000000",
  //   title: "Social Media?",
  //   description: (
  //     <>
  //       <p className="text-center">
  //         Az összes információt megtalálod a közösségi oldalakon.
  //       </p>
  //       <p className="mt-4 text-center">
  //         <span className="font-bold">De</span>, nehéz lehet kiszűrni a hiteles
  //         információkat.
  //       </p>
  //     </>
  //   ),
  // },
  {
    image:
      "https://img.icons8.com/?size=400&id=JVerjvma2d4j&format=png&color=000000",
    title: "Csodabógyók?",
    description: (
      <>
        <p className="text-center">A hirdetések szerint a fogyás egyszerű.</p>
        <p className="mt-4 text-center">
          <span className="font-bold">De</span> a végén nem te leszel vékonyabb,
          hanem a pénztárcád.
        </p>
      </>
    ),
  },
];

export default function Solution() {
  return (
    <section className="container mx-auto mb-24 mt-24 flex flex-col items-center px-4 pt-4 max-md:mt-0">
      <motion.h2
        className="my-2 w-full text-center text-5xl font-bold leading-tight max-[360px]:text-4xl"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        viewport={{ once: true, amount: 0.35 }}
      >
        Csalódtál már a hatástalan{" "}
        <span className="inline-block bg-gradient-to-r from-primary-600 to-accent-400 bg-clip-text text-transparent">
          megoldásokban
        </span>
        ?
      </motion.h2>
      <div className="mt-12 flex flex-col items-start justify-center md:flex-row">
        {SOLUTIONS.map((solution, index) => (
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true, amount: 0.35 }}
            key={index}
            className="relative w-full p-6 md:w-1/3"
          >
            {/* <IconX className="mx-auto h-24 w-24 text-danger" />, */}
            <Image
              src={solution.image}
              alt={solution.title}
              classNames={{
                img: "h-32 w-32 object-cover",
                wrapper: "mb-4 mx-auto",
              }}
            />
            <h3 className="mb-2 min-h-16 text-center text-xl font-semibold uppercase">
              {solution.title}
            </h3>
            {solution.description}
          </motion.div>
        ))}
      </div>
    </section>
  );
}
