import { RouteObject } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { RequireAuth } from "./RequireAuth";
import ProfileHome from "../pages/profile";
import ChangePassword from "../pages/profile/changePassword";
import ManageSubscription from "../pages/profile/manageSubscription";

export const ProfileRouter: RouteObject[] = [
  {
    path: ROUTES.profile.root,
    element: (
      <RequireAuth>
        <ProfileHome />
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.profile.changePassword,
    element: (
      <RequireAuth>
        <ChangePassword />
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.profile.manageSubscription,
    element: (
      <RequireAuth>
        <ManageSubscription />
      </RequireAuth>
    ),
  },
];
