import Layout from "../../components/Layout";
import {
  Appearance,
  StripeElementsOptionsMode,
  loadStripe,
} from "@stripe/stripe-js";
import {
  AddressElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Card, CardBody, CardHeader, Spacer } from "@nextui-org/react";
import { useApp } from "../../store/app.store";
import { ROUTES } from "../../constants/routes";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { Button } from "../../components/Button";
import { createSubscription } from "../../api/payment.api";
import { useMutation } from "react-query";
import { STRIPE_PUBLIC_KEY } from "../../constants/environment";
import { getDomainRoute } from "../../utils/routes.utils";
import SelectedSidebar from "../../components/auth/SelectedSidebar";
import { AddressType, SubscriptionType } from "../../types";
import { usePayment } from "../../store/payment.store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStatsigClient } from "@statsig/react-bindings";
import ReactPixel from "react-facebook-pixel";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY, {
  locale: "hu",
});

export default function Payment() {
  const { theme } = useApp();
  const [address, setAddress] = useState<AddressType | null>(null);
  const payment = usePayment();
  const navigate = useNavigate();
  const { t } = useTranslation("payment");

  const appearance: Appearance = {
    theme: theme === "light" ? "flat" : "night",
    variables: {
      fontLineHeight: "1.5",
      borderRadius: "12px",
      accessibleColorOnColorPrimary: "#FFD621",
    },
    rules: {
      ".Block": {
        backgroundColor: "var(--colorBackground)",
        boxShadow: "none",
        padding: "12px",
      },
      ".Input": {
        padding: "12px",
        fontWeight: "600",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Tab": {
        padding: "10px 12px 8px 12px",
        border: "none",
      },
      ".Tab:hover": {
        border: "none",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        backgroundColor: "#fff",
        boxShadow:
          "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Label": {
        fontWeight: "400",
      },
    },
  };

  if (payment.subscription === null) {
    navigate(ROUTES.subsciptions);
    return null;
  }

  ReactPixel.track("InitiateCheckout");

  const getAmount = () => {
    if (payment.subscription === null) return 0;

    if (payment.affiliateData) {
      return (
        payment.subscription.amount *
        (1 - payment.affiliateData?.percentage * 0.01)
      );
    }
    return payment.subscription.amount;
  };

  const options: StripeElementsOptionsMode = {
    mode: "subscription",
    amount: getAmount() * 100,
    currency: "huf",
    appearance,
  };

  return (
    <Layout
      classNames={{
        main: "flex flex-col md:flex-row gap-4 md:gap-24 mb-8 md:mb-0 items-center",
      }}
    >
      <SelectedSidebar subscription={payment.subscription} />
      <div className={`flex w-full items-center`}>
        <Card
          classNames={{
            base: "md:p-4 w-full my-8",
            header: "justify-center",
          }}
        >
          <CardHeader>
            <h1 className="text-xl font-semibold text-center mb-2">
              {t("checkout.title")}
            </h1>
          </CardHeader>
          <CardBody>
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                affiliateId={payment.affiliateData?.id}
                sub={payment.subscription}
                setAddress={setAddress}
                address={address}
              />
            </Elements>
          </CardBody>
        </Card>
      </div>
    </Layout>
  );
}

const CheckoutForm = ({
  affiliateId,
  sub,
  address,
  setAddress,
}: {
  affiliateId?: string;
  sub: SubscriptionType;
  address: AddressType | null;
  setAddress: Dispatch<SetStateAction<AddressType | null>>;
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const { logEvent } = useStatsigClient();
  const stripe = useStripe();
  const elements = useElements();
  const payment = usePayment();
  const { t } = useTranslation("payment");

  const { mutate: createSub } = useMutation(createSubscription);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (error: any) => {
    setLoading(false);
    setErrorMessage(error?.message);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe) {
      return;
    }

    if (!elements) {
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      setLoading(false);
      return;
    }

    if (!address) {
      handleError("Kerlek add meg a szamlazasi adataidat");
      setLoading(false);
      return;
    }

    logEvent("subscribe");

    createSub(
      {
        firstName: address.firstName!,
        lastName: address.lastName!,
        address: address,
        subscriptionId: sub.id,
        affiliateId,
      },
      {
        onSuccess: async (data) => {
          const { clientSecret } = data;

          const confirmIntent = stripe.confirmPayment;

          const { error } = await confirmIntent({
            elements,
            clientSecret,
            confirmParams: {
              return_url: getDomainRoute(ROUTES.paymentConfirmation),
            },
          });

          logEvent("subscribe_success");

          if (error) {
            handleError(error);
            setLoading(false);
          } else {
            payment.setSubscription(null);
            payment.setAffiliateData(null);
          }
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <AddressElement
        options={{ mode: "billing", display: { name: "split" } }}
        onChange={(e) => setAddress(e.value)}
      />
      <Spacer y={2} />
      <PaymentElement />
      <Spacer y={6} />
      {errorMessage && (
        <div className="w-full text-center text-danger pb-4">
          {errorMessage}
        </div>
      )}
      <Button
        variant="solid"
        color="primary"
        size="lg"
        className="w-full"
        type="submit"
        isLoading={loading}
        isDisabled={loading}
      >
        {t("checkout.pay")}
      </Button>
    </form>
  );
};
