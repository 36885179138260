import axiosDefault from "axios";
import { useAuth } from "../store/auth.store";
import { ROUTES } from "../constants/routes";

export const axios = axiosDefault.create();

axios.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    return config;
  },
  (error) => Promise.reject(error),
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      if (window.location.pathname === ROUTES.login) return error.response;
      window.location.href = ROUTES.login;
      useAuth.getState().logout();
    }
    return error.response;
  },
);
