import { API_URL } from "../../../constants/environment";
import { AnswerType } from "../../../pages/app/kBase/module";
import { axios } from "../../axios";

axios.defaults.withCredentials = true;

export async function getVideo(id: string) {
  const response = await axios.get(`${API_URL}/kBase/videos/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function completeVideo({
  id,
  quiz,
}: {
  id: string;
  quiz?: AnswerType[];
}) {
  const response = await axios.post(`${API_URL}/kBase/complete-video`, {
    videoId: id,
    quiz,
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
