import { Card, CardBody, CardHeader, Input, Spacer } from "@nextui-org/react";
import Layout from "../../components/Layout";
import { Button } from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { resetPassword } from "../../api/auth.api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormEvent } from "react";
import { ROUTES } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const schema = yup
  .object()
  .shape({
    password: yup.string().required(),
    confirmPassword: yup.string().oneOf([yup.ref('password')], i18n.t("auth:resetPassword.passwordMismatch")).required(),
  })
  .required()

export default function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation("auth");

  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
  });

  const { isLoading, mutate: resetUserPassword } = useMutation(resetPassword, {
    onError: (error: {message: string}) => {
      form.setError("root", { message: error.message });
    },
    onSuccess: () => {
      navigate(ROUTES.root, { replace: true });
    }
  })

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!token) return form.setError("root", { message: t("resetPassword.error") });

    handleSubmit((data) => {
      resetUserPassword({ ...data, token });
    })();
  }

  return (
    <Layout headerVariant='simple' classNames={{ main: 'items-center justify-center flex' }}>
      <Card
        classNames={{
          base: 'w-full max-w-sm mx-auto',
          header: 'justify-center',
          footer: 'justify-center',
        }}
        as="form"
        onSubmit={onSubmit}
      >
        <CardHeader>
          <h1 className="font-medium text-lg">
            {t("resetPassword.title")}
          </h1>
        </CardHeader>
        <CardBody>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t("resetPassword.password")}
                type="password"
                size="sm"
                color="primary"
                variant="bordered"
                errorMessage={form.formState.errors.password?.message}
              />
            )}
          />
          <Spacer y={3} />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t("resetPassword.confirmPassword")}
                type="password"
                size="sm"
                color="primary"
                variant="bordered"
                errorMessage={form.formState.errors.confirmPassword?.message}
              />
            )}
          />
          <Spacer y={3} />
          {form.formState.errors.root && (
            <>
              <p className="text-red-500 text-sm text-center">{form.formState.errors.root.message}</p>
              <Spacer y={3} />
            </>
          )}
          <Spacer y={5} />
          <Button
            variant="solid"
            color="primary"
            type="submit"
            isLoading={isLoading}
            isDisabled={isLoading || form.formState.isSubmitting || !form.formState.isValid}
          >
            {t("resetPassword.submit")}
          </Button>
        </CardBody>
      </Card>
    </Layout>
  )
}
