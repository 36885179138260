import { API_URL } from "../../../constants/environment";
import { axios } from "../../axios";

axios.defaults.withCredentials = true;

export type CourseType = {
  id: string;
  title: string;
  description: string;
  image: string;
  status?: string;
  modules: ModuleType[];
  lecturer?: {
    id: string;
    name: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type ModuleType = {
  id: string;
  title: string;
  courseId: string;
  image: string;
  status?: string;
  videos: VideoType[];
  createdAt: string;
  updatedAt: string;
};

export type VideoType = {
  id: string;
  title: string;
  description: string;
  url: string;
  order: number;
  status?: string;
  canPlay?: boolean;
  moduleId: string;
  createdAt: string;
  updatedAt: string;
  completed?: boolean;
};

export async function getCourses() {
  const response = await axios.get(`${API_URL}/kBase/courses`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getCourse(id: string) {
  const response = await axios.get(`${API_URL}/kBase/courses/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
