import Layout from "../../components/Layout";
import {
  Appearance,
  StripeElementsOptionsMode,
  loadStripe,
} from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Card, CardBody, CardHeader, Spacer } from "@nextui-org/react";
import { useApp } from "../../store/app.store";
import { ROUTES } from "../../constants/routes";
import { FormEvent, useState } from "react";
import { Button } from "../../components/Button";
import { retryPayment, updateSubscription } from "../../api/payment.api";
import { useMutation, useQuery } from "react-query";
import { STRIPE_PUBLIC_KEY } from "../../constants/environment";
import { getDomainRoute } from "../../utils/routes.utils";
import { usePayment } from "../../store/payment.store";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY, {
  locale: "hu",
});

export default function RetryPayment() {
  const { theme } = useApp();
  const { t } = useTranslation("payment");

  const { isLoading, data } = useQuery("retry", retryPayment);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const appearance: Appearance = {
    theme: theme === "light" ? "flat" : "night",
    variables: {
      fontLineHeight: "1.5",
      borderRadius: "12px",
      accessibleColorOnColorPrimary: "#FFD621",
    },
    rules: {
      ".Block": {
        backgroundColor: "var(--colorBackground)",
        boxShadow: "none",
        padding: "12px",
      },
      ".Input": {
        padding: "12px",
        fontWeight: "600",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Tab": {
        padding: "10px 12px 8px 12px",
        border: "none",
      },
      ".Tab:hover": {
        border: "none",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        backgroundColor: "#fff",
        boxShadow:
          "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Label": {
        fontWeight: "400",
      },
    },
  };

  const options: StripeElementsOptionsMode = {
    mode: "subscription",
    amount: data.amount * 100,
    currency: "huf",
    appearance,
  };

  return (
    <Layout
      classNames={{
        main: "flex flex-col md:flex-row gap-4 md:gap-24 mb-8 md:mb-0 justify-center",
      }}
    >
      <div className={`flex w-full md:w-1/2 items-center`}>
        <Card
          classNames={{
            base: "md:p-4 w-full my-8",
            header: "justify-center",
          }}
        >
          <CardHeader className="flex flex-col gap-2">
            <h1 className="text-xl font-semibold text-center mb-2">
              {t("checkout.title")}
            </h1>
            <h2 className="opacity-75">
              A fizetes nem sikerult. Kerunk add meg kartyaid adatait
            </h2>
          </CardHeader>
          <CardBody>
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm />
            </Elements>
          </CardBody>
        </Card>
      </div>
    </Layout>
  );
}

const CheckoutForm = () => {
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const payment = usePayment();
  const { t } = useTranslation("payment");

  const { mutate: updateSub } = useMutation(updateSubscription);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (error: any) => {
    setLoading(false);
    setErrorMessage(error?.message);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe) {
      return;
    }

    if (!elements) {
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      setLoading(false);
      return;
    }

    updateSub(undefined, {
      onSuccess: async (data) => {
        const { clientSecret } = data;

        const confirmIntent = stripe.confirmPayment;

        const { error } = await confirmIntent({
          elements,
          clientSecret,
          confirmParams: {
            return_url: getDomainRoute(ROUTES.paymentConfirmation),
          },
        });

        if (error) {
          handleError(error);
          setLoading(false);
        } else {
          payment.setSubscription(null);
          payment.setAffiliateData(null);
        }
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Spacer y={6} />
      {errorMessage && (
        <div className="w-full text-center text-danger pb-4">
          {errorMessage}
        </div>
      )}
      <Button
        variant="solid"
        color="primary"
        size="lg"
        className="w-full"
        type="submit"
        isLoading={loading}
        isDisabled={loading}
      >
        {t("checkout.pay")}
      </Button>
    </form>
  );
};
