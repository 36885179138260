import Layout from '../../../../components/Layout'
import { Autocomplete, AutocompleteItem, Image, Input, Spacer, Spinner, Switch, Textarea } from '@nextui-org/react'
import {useDropzone} from 'react-dropzone';
import { IconDragDrop, IconDragDrop2 } from '@tabler/icons-react';
import { Button } from '../../../../components/Button';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { createCourse, updateCourse } from '../../../../api/admin/app/kBase/courses.api';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { getCourse } from '../../../../api/app/kBase/courses.api';
import { getImageUrl } from '../../../../utils/images.utils';
import { useTranslation } from 'react-i18next';
import { getLecturers } from '../../../../api/admin/app/kBase/lecturers.api';

const schema = yup
  .object()
  .shape({
    title: yup.string().required(),
    description: yup.string().required(),
    active: yup.boolean().default(false),
    lecturerId: yup.string().optional(),
  })
  .required()

export default function CourseAdmin({isNew}: {isNew?: boolean}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const {t} = useTranslation("admin")
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
  });

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: {'image/*': []},
  });

  const { isLoading: isCourseLoading, data: course } = useQuery(['course', id], () => getCourse(id!), {
    enabled: !isNew,
  });

  const { isLoading: isLecturersLoading, data: lecturers } = useQuery('lecturers', () => getLecturers());

  useEffect(() => {
    if (!isNew && course) {
      form.setValue('title', course.title);
      form.setValue('description', course.description);
      form.setValue('lecturerId', course.lecturerId)
      form.setValue('active', course.status === "ACTIVE" ? true : false);
      if (acceptedFiles.length === 0) {
        acceptedFiles.push(new File([], course.image));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course])

  useEffect(() => {
    return () => {
      acceptedFiles.pop();
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const { isLoading: updateIsLoading, mutate: saveUpdatedCourse } = useMutation(updateCourse, {
    onError: (error: {message: string}) => {
      form.setError("root", { message: error.message });
    },
    onSuccess: () => {
      acceptedFiles.pop();
      navigate(ROUTES.kBase.getCourse({id: id!}));
    }
  });

  const { isLoading: createIsLoading, mutate: saveCourse } = useMutation(createCourse, {
    onError: (error: {message: string}) => {
      form.setError("root", { message: error.message });
    },
    onSuccess: () => {
      acceptedFiles.pop();
      navigate(ROUTES.kBase.root);
    }
  })

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit((data) => {
      if (isNew) {
        saveCourse({...data, images: acceptedFiles});
      } else {
        saveUpdatedCourse({id: id!, ...data, images: acceptedFiles});
      }
    })();
  }

  useEffect(() => {
    setIsLoading(createIsLoading || updateIsLoading || isCourseLoading || isLecturersLoading)
  }, [createIsLoading, updateIsLoading, isCourseLoading, isLecturersLoading])

  if (!isNew && isCourseLoading) return (
    <Layout
      showFooter
      classNames={{
        main: 'flex justify-center items-center h-screen'
      }}
    >
      <Spinner />
    </Layout>
  );

  return (
    <Layout showFooter classNames={{ main: "max-w-screen-sm"}}>
      <form onSubmit={onSubmit}>
        <h1 className='text-xl mt-4 font-semibold'>{isNew ? t("kBase.courses.newCourse") : t("kBase.courses.editCourse")}</h1>

        <Controller
          name="active"
          control={control}
          render={({ field }) => (
            <Switch onValueChange={(isSelected) => field.onChange(isSelected)} isSelected={field.value} className='mt-4'>
              <span className='opacity-70 max-w'>{t("kBase.courses.active")}</span>
            </Switch>
          )}
        />

        <Spacer y={4} />

        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("kBase.courses.name")}
              variant="bordered"
              color="primary"
              errorMessage={form.formState.errors.title?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              label={t("kBase.courses.description")}
              variant="bordered"
              color="primary"
              rows={5}
              errorMessage={form.formState.errors.description?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="lecturerId"
          control={control}
          render={({ field }) => (
            <Autocomplete
              defaultItems={lecturers || []}
              label={t("kBase.courses.lecturer")}
              selectedKey={field.value}
              onSelectionChange={(item) => field.onChange(item)}
              variant='bordered'
              color='primary'
              {...field}
            >
              {(item: {name: string; id: string;}) => (
                <AutocompleteItem key={item.id}>
                  {item.name}
                </AutocompleteItem>
              )}
            </Autocomplete>
          )}
        />
        
        <div {...getRootProps()} className='mt-4 border-2 border-solid border-default-200 rounded-xl p-3 flex justify-center items-center flex-col shadow-sm '>
          <h1 className='text-primary-300 text-sm self-start pb-1'>{t("kBase.courses.thumbnail")}</h1>
          <input {...getInputProps()} />
          {isDragActive && (
            <div className={(acceptedFiles.length ? 'h-80' : '') + ' flex flex-col justify-center items-center'}>
              <IconDragDrop2 size={48} className='text-primary-500'/>
              <p className='text-primary-300 text-center text-sm'>{t("kBase.courses.thumbnailDrop")}</p>
            </div>
          )}
          {isDragActive || !acceptedFiles.length && (
            <>
              <IconDragDrop size={48} className='text-primary-300'/>
              <p className="text-primary-300 text-sm">{t("kBase.courses.thumbnailDrag")}</p>
            </>
          )}
          {!isDragActive && acceptedFiles.length > 0 && (
            <div>
              {acceptedFiles.map(file => (
                <Image
                  key={file.name}
                  src={file.name.includes('uploads') ? getImageUrl(file.name) : URL.createObjectURL(file)}
                  alt={file.name}
                  className='rounded-lg max-h-80'
                />
              ))}
            </div>
          )}
        </div>
        {
          form.formState.errors.root && (
            <>
              <Spacer y={3} />
              <p className="text-red-500 text-sm text-center">{form.formState.errors.root.message}</p>
            </>
          )
        }

        <Button
          variant='solid'
          color='primary'
          className='mt-8 w-full'
          type="submit"
          isLoading={isLoading}
          isDisabled={isLoading || !form.formState.isValid || acceptedFiles.length === 0}
        >
          {t("kBase.courses.save")}
        </Button>

        <Spacer y={4} />
      </form>
    </Layout>
  )
}
