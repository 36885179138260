import { API_URL } from "../constants/environment";
import { axios } from "./axios";

export type QuizType = {
  name: string;
  email: string;
  age: string;
  weight: string;
  height: string;
  diagnostic: string;
  pre_menstruation: string;
  pain: string;
  activity: string;
  eating: {
    breakfast: string;
    lunch: string;
    dinner: string;
    snacks: string;
  };
  goal: string;
  success: string;
};

export async function completeQuiz(answers: QuizType) {
  const response = await axios.post(`${API_URL}/quiz/complete`, {
    ...answers,
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getQuizResults(id: string) {
  const response = await axios.get(`${API_URL}/quiz/results/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
