import { Card, CardBody, CardFooter, CardHeader, Input, Spacer } from "@nextui-org/react";
import Layout from "../../components/Layout";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { login } from "../../api/auth.api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormEvent } from "react";
import { useTranslation } from "react-i18next";

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required()

export default function Login() {
  const navigate = useNavigate();
  const {t} = useTranslation("auth");

  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
  });

  const { isLoading, mutate: loginUser } = useMutation(login, {
    onError: (error: {message: string}) => {
      form.setError("root", { message: error.message });
    },
  })

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit((data) => {
      loginUser(data);
    })();
  }

  return (
    <Layout headerVariant="simple" classNames={{main: 'items-center justify-center flex'}}>
      <Card
        classNames={{
          base: 'w-full max-w-sm mx-auto',
          header: 'justify-center',
          footer: 'justify-center',
        }}
        as="form"
        onSubmit={onSubmit}

      >
        <CardHeader>
          <h1 className="font-medium text-lg">
            {t("login.title")}
          </h1>
        </CardHeader>
        <CardBody>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t("login.email")}
                size="sm"
                color="primary"
                variant="bordered"
              />
            )}
          />
          <Spacer y={3} />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t("login.password")}
                type="password"
                size="sm"
                variant="bordered"
                color="primary"
              />
            )}
          />
          <Spacer y={3} />
          {form.formState.errors.root && (
            <>
              <p className="text-red-500 text-sm text-center">{form.formState.errors.root.message}</p>
              <Spacer y={3} />
            </>
          )}
          <span onClick={() => navigate(ROUTES.forgotPassword)} className="text-foreground text-center text-opacity-70 text-sm cursor-pointer">{t("login.forgotPassword")}</span>
          <Spacer y={5} />
          <Button
            variant="solid"
            color="primary"
            type="submit"
            isDisabled={isLoading || form.formState.isSubmitting || !form.formState.isValid}
            isLoading={isLoading}
          >
            {t("login.submit")}
          </Button>
        </CardBody>
        <CardFooter>
          <p className="text-sm ">
            {t("login.noAccount")}{" "}
            <span onClick={() => navigate(ROUTES.subsciptions)} className="text-accent cursor-pointer">{t("login.signUp")}</span>
          </p>
        </CardFooter>
      </Card>
    </Layout>
  )
}
