import { useMutation, useQuery } from "react-query";
import Layout from "../../../components/Layout";
import { cancelUserSubscription, getUserById } from "../../../api/admin/users";
import { useNavigate, useParams } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spacer,
  Spinner,
  useDisclosure,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { useApp } from "../../../store/app.store";
import { ROUTES } from "../../../constants/routes";

export default function AdminUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("admin");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const app = useApp();

  const { isLoading: isUserLoading, data: user } = useQuery(
    ["user", id],
    () => getUserById(id!),
    {
      enabled: !!id,
    },
  );

  const { isLoading: isDeleteLoading, mutate: cancelSubscription } =
    useMutation(() => cancelUserSubscription(id!), {
      onSuccess: () => {
        navigate(ROUTES.admin.users.root);
      },
    });

  const handleCancelSubscription = () => {
    cancelSubscription();
  };

  if (isUserLoading || isDeleteLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter>
      <h1 className="text-2xl mt-4 font-semibold">{`${user?.firstName} ${user?.lastName}`}</h1>
      <h2 className="text-sm opacity-70">{user.email}</h2>

      <Spacer y={4} />

      <div className="flex items-center">
        <div className="flex flex-col ">
          <h3 className="text-lg font-semibold">{t("manageUsers.details")}</h3>
          <Spacer y={2} />
          <div className="flex flex-col">
            <span className="text-sm opacity-70">
              {t("manageUsers.role")}: {user.role}
            </span>
            <span className="text-sm opacity-70">
              {t("manageUsers.status")}: {user.status}
            </span>
            <span className="text-sm opacity-70">
              {t("manageUsers.createdAt")}:{" "}
              {new Date(user.createdAt).toLocaleString()}
            </span>
            <span className="text-sm opacity-70">
              {t("manageUsers.currentSubscription")}:{" "}
              {user.payments?.length
                ? user.payments[0].subscription.name
                : "No subscription"}
            </span>
          </div>
        </div>
      </div>

      <Spacer y={4} />

      {user.status === "ACTIVE" && (
        <>
          <Button
            variant="flat"
            color="danger"
            className="mt-8 w-full"
            onClick={() => onOpen()}
          >
            {t("manageUsers.cancelSubscription")}
          </Button>
          <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            backdrop="blur"
            classNames={{
              base: app.theme === "light" ? "" : "dark",
              header: app.theme === "light" ? "" : "dark text-foreground",
              body: app.theme === "light" ? "" : "dark text-foreground",
            }}
          >
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader>
                    {t("manageUsers.cancelSubscription")}
                  </ModalHeader>
                  <ModalBody>
                    <p>{t("manageUsers.cancelSubscriptionMessage")}</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={() => {
                        onClose();
                      }}
                      color="defaultPrimary"
                    >
                      {t("manageUsers.cancel")}
                    </Button>
                    <Button
                      onClick={handleCancelSubscription}
                      variant="solid"
                      color="danger"
                    >
                      {t("manageUsers.confirm")}
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        </>
      )}
    </Layout>
  );
}
