import { Chip } from "@nextui-org/react";

export default function StatusChip({ status }: { status: string }) {
  const getColor = () => {
    switch (status) {
      case "ACTIVE":
      case "COMPLETED":
        return "success";
      case "INACTIVE":
        return "danger";
      case "PENDING":
      case "INCOMPLETE":
        return "warning";
      default:
        return "default";
    }
  };

  return (
    <Chip variant="flat" color={getColor()}>
      {status}
    </Chip>
  );
}
