import { API_URL } from "../constants/environment";
import { axios } from "./axios";

axios.defaults.withCredentials = true;

export async function getSubscription() {
  const response = await axios.get(`${API_URL}/profile/subscription`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function cancelSubscription({
  password,
  reason,
}: {
  password: string;
  reason: string;
}) {
  const response = await axios.post(`${API_URL}/profile/cancel`, {
    password,
    reason,
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
