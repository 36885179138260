import { extendVariants, Avatar as AvatarNextUI } from "@nextui-org/react";


export const Avatar = extendVariants(AvatarNextUI, {
  variants: {
    size: {
      xl: {
        base: 'w-52 h-52',
        // img: 'w-full h-full',
        // fallback: 'w-full h-full',
        name: 'text-5xl',
        // icon: 'w-full h-full',
      },
    },
  },
});
