import {
  Spacer,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Layout from "../../../components/Layout";
import { ROUTES } from "../../../constants/routes";
import StatusChip from "../../../components/admin/StatusChip";
import { getAnalysis } from "../../../api/admin/analysis";

const COLUMNS = [
  {
    key: "email",
    label: "EMAIL",
  },
  {
    key: "status",
    label: "STATUS",
  },
];

export default function AdminAnalysis() {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");

  const { isLoading, data: analysis } = useQuery("analysis", () =>
    getAnalysis(),
  );

  if (isLoading)
    return (
      <Layout showFooter>
        <h1 className="mt-4 text-xl font-semibold">
          {t("manageAnalysis.analysis")}
        </h1>
        <Spacer y={4} />
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      </Layout>
    );

  return (
    <Layout showFooter>
      <h1 className="mt-4 text-xl font-semibold">
        {t("manageAnalysis.analysis")}
      </h1>
      <Spacer y={4} />
      <Table color="secondary">
        <TableHeader columns={COLUMNS}>
          {(column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          )}
        </TableHeader>
        <TableBody items={analysis || []} isLoading={isLoading}>
          {(item: { id: string; videoUrl: string }) => (
            <TableRow
              className="group cursor-pointer"
              key={item.id}
              onClick={() =>
                navigate(ROUTES.admin.analysis.getData({ id: item.id }))
              }
            >
              {(columnKey) => (
                <TableCell className="first:rounded-s-xl last:rounded-e-xl group-hover:bg-primary-100">
                  {columnKey === "status" ? (
                    <StatusChip
                      status={item?.videoUrl ? "COMPLETED" : "INCOMPLETE"}
                    />
                  ) : (
                    getKeyValue(item, columnKey)
                  )}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Layout>
  );
}
