import { API_URL } from "../../../constants/environment";
import { axios } from "../../axios";

axios.defaults.withCredentials = true;

export async function getModule(id: string) {
  const response = await axios.get(`${API_URL}/kBase/modules/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
