import {
  Spacer,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { IconPlus } from "@tabler/icons-react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Layout from "../../../components/Layout";
import { Button } from "../../../components/Button";
import { ROUTES } from "../../../constants/routes";
import StatusChip from "../../../components/admin/StatusChip";
import { getAdminCoupons } from "../../../api/admin/coupons";

const COLUMNS = [
  {
    key: "code",
    label: "KOD",
  },
  {
    key: "status",
    label: "STATUS",
  },
  {
    key: "users",
    label: "FELHASZNALOK",
  },
];

export default function AdminCoupons() {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");

  const { isLoading, data: coupons } = useQuery("coupons", () =>
    getAdminCoupons(),
  );

  if (isLoading)
    return (
      <Layout showFooter>
        <h1 className="text-xl mt-4 font-semibold">
          {t("manageCoupons.coupons")}
        </h1>
        <Spacer y={4} />
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      </Layout>
    );

  return (
    <Layout showFooter>
      <h1 className="text-xl mt-4 font-semibold">
        {t("manageCoupons.coupons")}
      </h1>
      <Spacer y={4} />
      <Table
        color="secondary"
        bottomContent={
          <Button
            color="primary"
            onClick={() => navigate(ROUTES.admin.coupons.newCoupon)}
            startContent={<IconPlus />}
          >
            {t("manageCoupons.newCoupon")}
          </Button>
        }
      >
        <TableHeader columns={COLUMNS}>
          {(column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          )}
        </TableHeader>
        <TableBody items={coupons || []} isLoading={isLoading}>
          {(item: { id: string; status: string }) => (
            <TableRow
              className="cursor-pointer group"
              key={item.id}
              onClick={() =>
                navigate(ROUTES.admin.coupons.getCoupon({ id: item.id }))
              }
            >
              {(columnKey) => (
                <TableCell className="group-hover:bg-primary-100 last:rounded-e-xl first:rounded-s-xl">
                  {columnKey === "status" ? (
                    <StatusChip status={item.status} />
                  ) : (
                    getKeyValue(item, columnKey)
                  )}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Layout>
  );
}
