import { Spacer, Spinner } from "@nextui-org/react";
import { useDropzone } from "react-dropzone";
import { IconDragDrop, IconDragDrop2 } from "@tabler/icons-react";
import { useMutation, useQuery } from "react-query";
import { FormEvent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getAnalysisById,
  sendAnalysis,
  uploadAnalysis,
} from "../../../api/admin/analysis";
import Layout from "../../../components/Layout";
import { Button } from "../../../components/Button";
import { ROUTES } from "../../../constants/routes";

export default function AdminAnalysisData() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("admin");

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      multiple: false,
      accept: { "video/*": [] },
    });

  const { isLoading: isDataLoading, data } = useQuery(
    ["analysisData", id],
    () => getAnalysisById(id!),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (data?.videoUrl) {
      acceptedFiles.push(new File([], data.videoUrl));
    }

    return () => {
      acceptedFiles.pop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading: updateIsLoading, mutate: saveAnalysisVideo } = useMutation(
    uploadAnalysis,
    {
      onSuccess: () => {
        acceptedFiles.pop();
        // navigate(ROUTES.kBase.getModule({ id: state?.moduleId, videoId: id! }));
      },
    },
  );

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    saveAnalysisVideo({
      id: id!,
      videos: acceptedFiles,
    });
  };

  if (isDataLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter classNames={{ main: "max-w-screen-sm" }}>
      <form onSubmit={onSubmit}>
        <h1 className="mt-4 text-3xl font-semibold">Elemzés</h1>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Név</h2>
          <p className="text-sm opacity-70">{data.name}</p>
        </div>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Email</h2>
          <p className="text-sm opacity-70">{data.email}</p>
        </div>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Kor</h2>
          <p className="text-sm opacity-70">{data.age}</p>
        </div>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Súly</h2>
          <p className="text-sm opacity-70">{data.weight} kg</p>
        </div>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Magasság</h2>
          <p className="text-sm opacity-70">{data.height} cm</p>
        </div>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Betegségek / Allergiák</h2>
          <p className="text-sm opacity-70">{data.diagnostic}</p>
        </div>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Menstruaciós Tünetek</h2>
          <p className="text-sm opacity-70">
            {data.pre_menstruation === "false" ? "Nem" : "Igen"}
          </p>
        </div>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Menstruációs fájdalom</h2>
          <p className="text-sm opacity-70">
            {data.pain === "false" ? "Nem" : "Igen"}
          </p>
        </div>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Aktivitás</h2>
          <p className="text-sm opacity-70">{data.activity}</p>
        </div>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Étkezés</h2>
          <p className="text-sm opacity-70">
            Reggeli: {data.breakfast}
            <br />
            Ebéd: {data.lunch}
            <br />
            Vacsora: {data.dinner}
            <br />
            Nasi: {data.snack}
          </p>
        </div>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Cél</h2>
          <p className="text-sm opacity-70">{data.goal}</p>
        </div>

        <Spacer y={4} />

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Siker</h2>
          <p className="text-sm opacity-70">{data.success}</p>
        </div>

        <div
          {...getRootProps()}
          className="mt-4 flex flex-col items-center justify-center rounded-xl border-2 border-solid border-default-200 p-3 shadow-sm "
        >
          <h1 className="self-start pb-1 text-sm text-primary-300">
            {t("kBase.videos.video")}
          </h1>
          <input {...getInputProps()} />
          {isDragActive && (
            <div
              className={
                (acceptedFiles.length ? "h-80" : "") +
                " flex flex-col items-center justify-center"
              }
            >
              <IconDragDrop2 size={48} className="text-primary-500" />
              <p className="text-center text-sm text-primary-300">
                {t("kBase.videos.videoDrag")}
              </p>
            </div>
          )}
          {isDragActive ||
            (!acceptedFiles.length && (
              <>
                <IconDragDrop size={48} className="text-primary-300" />
                <p className="text-sm text-primary-300">
                  {t("kBase.videos.videoDrop")}
                </p>
              </>
            ))}
          {!isDragActive && acceptedFiles.length > 0 && (
            <div>
              {acceptedFiles.map((file) => (
                <video key={file.name} controls className="max-h-80 rounded-lg">
                  <source
                    src={
                      file.name.includes("googleapis")
                        ? file.name
                        : URL.createObjectURL(file)
                    }
                  />
                  {t("kBase.videos.notSupported")}
                </video>
              ))}
            </div>
          )}
        </div>

        <Button
          variant="solid"
          color="primary"
          className="mt-8 w-full"
          type="submit"
          isLoading={updateIsLoading}
          isDisabled={updateIsLoading || acceptedFiles.length === 0}
        >
          Mentés
        </Button>
      </form>

      <Button
        variant="bordered"
        color="primary"
        className="mt-8 w-full"
        onClick={() => navigate(ROUTES.getQuizResults({ id: data.id }))}
      >
        Elönézet
      </Button>

      <Button
        variant="flat"
        color="warning"
        className="mt-8 w-full"
        onClick={() => {
          sendAnalysis({ id: data.id });
          navigate(ROUTES.admin.analysis.root);
        }}
      >
        Küldés
      </Button>
      <Spacer y={4} />
    </Layout>
  );
}
