import Layout from "../../components/Layout";
import {
  Appearance,
  StripeElementsOptionsMode,
  loadStripe,
} from "@stripe/stripe-js";
import {
  AddressElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Spacer,
  Spinner,
} from "@nextui-org/react";
import { useApp } from "../../store/app.store";
import { ROUTES } from "../../constants/routes";
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Button } from "../../components/Button";
import {
  createDirectSubscription,
  // createSubscription,
  getSubscriptions,
} from "../../api/payment.api";
import { useMutation, useQuery } from "react-query";
import { STRIPE_PUBLIC_KEY } from "../../constants/environment";
import { getDomainRoute } from "../../utils/routes.utils";
import SelectedSidebar from "../../components/auth/SelectedSidebar";
import { AddressType, SubscriptionType } from "../../types";
import { usePayment } from "../../store/payment.store";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStatsigClient } from "@statsig/react-bindings";
import ReactPixel from "react-facebook-pixel";
import { IconEye, IconEyeClosed } from "@tabler/icons-react";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY, {
  locale: "hu",
});

export default function DirectPayment() {
  const { theme } = useApp();
  const [address, setAddress] = useState<AddressType | null>(null);
  const payment = usePayment();
  // const navigate = useNavigate();
  const { t } = useTranslation("payment");

  const appearance: Appearance = {
    theme: theme === "light" ? "flat" : "night",
    variables: {
      fontLineHeight: "1.5",
      borderRadius: "12px",
      accessibleColorOnColorPrimary: "#FFD621",
    },
    rules: {
      ".Block": {
        backgroundColor: "var(--colorBackground)",
        boxShadow: "none",
        padding: "12px",
      },
      ".Input": {
        padding: "12px",
        fontWeight: "600",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Tab": {
        padding: "10px 12px 8px 12px",
        border: "none",
      },
      ".Tab:hover": {
        border: "none",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        backgroundColor: "#fff",
        boxShadow:
          "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Label": {
        fontWeight: "400",
      },
    },
  };

  const { isLoading } = useQuery("subscriptions", () => getSubscriptions(), {
    enabled: payment.subscription === null,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      payment.setSubscription(data[0]);
    },
  });

  if (payment.subscription === null || isLoading) {
    return (
      <>
        <Spinner size="lg" />
      </>
    );
  }

  const getAmount = () => {
    if (payment.subscription === null) return 0;

    if (payment.affiliateData) {
      return (
        payment.subscription.amount *
        (1 - payment.affiliateData?.percentage * 0.01)
      );
    }
    return payment.subscription.amount;
  };

  const options: StripeElementsOptionsMode = {
    mode: "subscription",
    amount: getAmount() * 100,
    currency: "huf",
    appearance,
  };

  return (
    <Layout
      classNames={{
        main: "flex flex-col md:flex-row gap-4 md:gap-24 mb-8 md:mb-0 items-center",
      }}
    >
      <SelectedSidebar subscription={payment.subscription} />
      <div className={`flex w-full items-center`}>
        <Card
          classNames={{
            base: "md:p-4 w-full my-8",
            header: "justify-center",
          }}
        >
          <CardHeader>
            <h1 className="mb-2 text-center text-xl font-semibold">
              {t("checkout.title")}
            </h1>
          </CardHeader>
          <CardBody>
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                affiliateId={payment.affiliateData?.id}
                sub={payment?.subscription}
                setAddress={setAddress}
                address={address}
              />
            </Elements>
          </CardBody>
        </Card>
      </div>
    </Layout>
  );
}

const CheckoutForm = ({
  affiliateId,
  sub,
  address,
  setAddress,
}: {
  affiliateId?: string;
  sub: SubscriptionType;
  address: AddressType | null;
  setAddress: Dispatch<SetStateAction<AddressType | null>>;
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [emailAddress, setEmailAddress] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { logEvent } = useStatsigClient();
  const stripe = useStripe();
  const elements = useElements();
  const payment = usePayment();
  const { t } = useTranslation("payment");

  const { mutate: createSub } = useMutation(createDirectSubscription);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (error: any) => {
    setLoading(false);
    setErrorMessage(error?.message);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe) {
      return;
    }

    if (!elements) {
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      setLoading(false);
      return;
    }

    if (!address) {
      handleError("Kerlek add meg a szamlazasi adataidat");
      setLoading(false);
      return;
    }

    logEvent("subscribe");

    ReactPixel.track("InitiateCheckout");

    createSub(
      {
        firstName: address.firstName!,
        lastName: address.lastName!,
        email: emailAddress,
        address: address,
        password,
        subscriptionId: sub.id,
        affiliateId,
      },
      {
        onSuccess: async (data) => {
          const { clientSecret } = data;

          const confirmIntent = stripe.confirmPayment;

          const { error } = await confirmIntent({
            elements,
            clientSecret,
            confirmParams: {
              return_url: getDomainRoute(ROUTES.paymentConfirmation),
            },
          });

          logEvent("subscribe_success");

          if (error) {
            handleError(error);
            setLoading(false);
          } else {
            payment.setSubscription(null);
            payment.setAffiliateData(null);
          }
        },
      },
    );
  };

  useEffect(() => {
    if (emailAddress.match(/^\S+@\S+\.\S+$/) || emailAddress.length === 0) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }, [emailAddress]);

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label={t("checkout.email")}
        size="md"
        color="default"
        radius="lg"
        labelPlacement="outside"
        classNames={{
          label: "text-[0.93rem]",
        }}
        placeholder={`${" "}`}
        value={emailAddress}
        isInvalid={emailError}
        onChange={(e) => setEmailAddress(e.target.value)}
      />
      <Spacer y={2} />
      <Input
        label={t("checkout.password")}
        size="md"
        color="default"
        radius="lg"
        labelPlacement="outside"
        type={passwordVisible ? "text" : "password"}
        classNames={{
          label: "text-[0.93rem]",
        }}
        placeholder={`${" "}`}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        endContent={
          passwordVisible ? (
            <IconEyeClosed
              className="cursor-pointer"
              onClick={() => setPasswordVisible(false)}
            />
          ) : (
            <IconEye
              className="cursor-pointer"
              onClick={() => setPasswordVisible(true)}
            />
          )
        }
      />
      <Spacer y={2} />
      <AddressElement
        options={{ mode: "billing", display: { name: "split" } }}
        onChange={(e) => setAddress(e.value)}
      />
      <Spacer y={2} />
      <PaymentElement />
      <Spacer y={6} />
      {errorMessage && (
        <div className="w-full pb-4 text-center text-danger">
          {errorMessage}
        </div>
      )}
      <Button
        variant="solid"
        color="primary"
        size="lg"
        className="w-full"
        type="submit"
        isLoading={loading}
        isDisabled={
          loading ||
          emailError ||
          emailAddress.length === 0 ||
          password.length === 0
        }
      >
        {t("checkout.pay")}
      </Button>
    </form>
  );
};
