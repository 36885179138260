import { useMutation } from 'react-query';
import Layout from '../../components/Layout'
import { Card, CardBody, CardHeader, Spinner } from '@nextui-org/react'
import { confirmEmail } from '../../api/auth.api';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { useTranslation } from 'react-i18next';

export default function ConfirmEmail() {
  const { token } = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation("auth");
  const [error, setError] = useState<string | null>(null)

  const { mutate: confirm } = useMutation(confirmEmail, {
    onError: (error: {message: string}) => {
      setError(error.message)
    }, 
    onSuccess: () => {
      navigate(ROUTES.root, { replace: true })
    }
  });

  useEffect(() => {
    if (!token) return;

    confirm({ token });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout headerVariant='simple' classNames={{ main: 'items-center justify-center flex' }}>
        <Card classNames={{
          base: "min-h-40",
          body: "flex justify-center items-center",
          header: "justify-center",
        }}>
          <CardHeader>
            <h1 className='font-medium text-lg'>{t("confirmEmail.title")}</h1>
          </CardHeader>
          <CardBody>
            {error ? (
              <span className='text-danger'>{t("confirmEmail.error")}</span>
            ) : (
              <Spinner size='lg' />
            )}
          </CardBody>
        </Card>
    </Layout>
  )
}
