import { useQuery } from "react-query";
import Header from "../../components/Header";
import { ROUTES } from "../../constants/routes";
import { useStatsigClient } from "@statsig/react-bindings";
import { useNavigate, useParams } from "react-router-dom";
import { getSubscriptions } from "../../api/payment.api";
import { usePayment } from "../../store/payment.store";
import { useAuth } from "../../store/auth.store";
import { getQuizResults } from "../../api/quiz";
import ReactPlayer from "react-player";
import { Spinner } from "@nextui-org/react";

export default function QuizResults() {
  const { id } = useParams();
  const navigate = useNavigate();
  const payment = usePayment();
  const auth = useAuth();
  const { logEvent } = useStatsigClient();

  const { data: subscriptions } = useQuery(
    "subscriptions",
    () => getSubscriptions(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { isLoading: videoLoading, data: video } = useQuery(
    ["video", id],
    () => getQuizResults(id!),
    {
      enabled: !!id,
    },
  );

  return (
    <>
      <Header />
      <section className="flex flex-col items-center justify-start bg-gradient-to-t from-primary-100">
        <div className="mx-auto mt-12 flex w-full max-w-screen-md flex-col  px-4">
          <h1 className="mb-8 text-center text-3xl font-bold">
            Itt az elemzésed! 🎉
          </h1>
          {/* <p className="text-center"></p> */}
          {videoLoading ? (
            <Spinner />
          ) : (
            <ReactPlayer
              // style={{ borderRadius: "1rem", overflow: "hidden" }}
              url={video?.url}
              controls
              width="100%"
              height="100%"
              playsinline
            />
          )}
        </div>
      </section>
      <section className="flex flex-col items-center justify-start bg-gradient-to-b from-primary-100">
        <div className="max-w-screen-md px-4 text-center">
          <h2 className="pt-40 text-4xl font-bold uppercase leading-tight">
            Hozd ki{" "}
            <span className="inline-block bg-gradient-to-r from-accent-600 to-secondary-500 bg-clip-text text-transparent">
              magadból
            </span>{" "}
            a maximumot, az egészséges életmóddal!
          </h2>
          <p className="mt-6">
            Csatlakozz a Nutriqueens csapatához, és érd el te is celjaidat!
          </p>
          <button
            onClick={() => {
              payment.setSubscription(subscriptions[0]);
              if (auth.user) {
                navigate(ROUTES.payment);
                return;
              }
              navigate(ROUTES.directPayment);
              logEvent("interested_in_NQ");
            }}
            className="focus:shadow-outline z-50 mx-auto mt-6 transform cursor-pointer rounded-full bg-gradient-to-r from-accent-300 to-primary-500 px-8 py-4 font-semibold uppercase text-white shadow-lg transition duration-300 ease-in-out hover:scale-105 focus:outline-none lg:mx-0"
          >
            Igen, ezt akarom!
          </button>
        </div>
      </section>
    </>
  );
}
