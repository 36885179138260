import {
  IconArrowLeft,
  IconDiscountCheckFilled,
  IconLock,
  IconMoodSad,
  IconPencil,
  IconPencilPlus,
} from "@tabler/icons-react";
import Layout from "../../../components/Layout";
import {
  Card,
  CardBody,
  CardHeader,
  Chip,
  Divider,
  Progress,
  Spacer,
  Spinner,
} from "@nextui-org/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useAuth } from "../../../store/auth.store";
import { Button } from "../../../components/Button";
import { getModule } from "../../../api/app/kBase/modules.api";
import { useMutation, useQuery } from "react-query";
import ReactPlayer from "react-player";
import { Fragment } from "react/jsx-runtime";
import { completeVideo, getVideo } from "../../../api/app/kBase/videos.api";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { QuizDto } from "../../admin/app/kBase/video";
import { useTranslation } from "react-i18next";

const AddAndModifyButtons = ({
  moduleId,
  courseId,
  videoId,
}: {
  moduleId: string;
  courseId: string;
  videoId: string;
}) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation("videos");

  if (auth.user!.role === "ADMIN" || auth.user!.role === "OWNER") {
    return (
      <div className="flex flex-col md:flex-row justify-center items-center pt-3 cursor-pointer opacity-70">
        <Button
          startContent={<IconPencil />}
          variant="light"
          color="default"
          onClick={() =>
            navigate(ROUTES.admin.kBase.getModule({ id: moduleId }), {
              state: { courseId, moduleId },
            })
          }
        >
          {t("changeModule")}
        </Button>
        <Spacer x={2} />
        <Button
          startContent={<IconPencilPlus />}
          variant="light"
          color="default"
          onClick={() =>
            navigate(ROUTES.admin.kBase.newVideo, {
              state: { moduleId, courseId },
            })
          }
        >
          {t("newVideo")}
        </Button>
        <Spacer x={2} />
        <Button
          startContent={<IconPencil />}
          variant="light"
          color="default"
          onClick={() =>
            navigate(ROUTES.admin.kBase.getVideo({ id: videoId }), {
              state: { moduleId, courseId },
            })
          }
        >
          {t("changeVideo")}
        </Button>
      </div>
    );
  }

  return null;
};

export type AnswerType = {
  questionId: string;
  answer: string;
};

type VideoType = {
  id: string;
  title: string;
  description: string;
  url: string;
  order: number;
  status: string;
  canPlay: boolean;
  completed?: boolean;
  quiz: QuizDto[];
};

export default function Module() {
  const navigate = useNavigate();
  const { id, videoId } = useParams();
  const auth = useAuth();
  const { state } = useLocation();
  const { t } = useTranslation("videos");
  const [step, setStep] = useState(0);
  const [quizStep, setQuizStep] = useState(0);
  const [answers, setAnswers] = useState<AnswerType[]>([]);
  const [errorValue, setErrorValue] = useState(0);
  let errorTimeout: ReturnType<typeof setTimeout>;
  let errorInterval: ReturnType<typeof setTimeout>;

  const {
    isLoading,
    data: module,
    refetch: refetchModule,
  } = useQuery(["course", id], () => getModule(id!), {
    refetchOnWindowFocus: false,
  });

  const { isLoading: videoIsLoading, data: videoData } = useQuery(
    ["video", videoId],
    () => getVideo(videoId!),
    {
      refetchOnWindowFocus: false,
    },
  );

  const handleNextVideo = () => {
    const nextVideoId = module?.videos.find(
      (v: VideoType) =>
        v.order === videoData.order + 1 && v.status === "ACTIVE",
    )?.id;
    setStep(0);
    setQuizStep(0);
    setAnswers([]);
    if (nextVideoId) {
      refetchModule();
      navigate(ROUTES.kBase.getModule({ id: id!, videoId: nextVideoId }), {
        state: { courseId: module?.courseId, moduleId: module?.id },
      });
    } else {
      navigate(ROUTES.kBase.getCourse({ id: module?.courseId }));
    }
  };

  const { isLoading: completeIsLoading, mutate: sendVideo } = useMutation(
    completeVideo,
    {
      onSuccess: () => {
        handleNextVideo();
      },
      onError: (e: { message: string }) => {
        if (e.message === "Video already completed") {
          handleNextVideo();
          return;
        }
        setStep(2);

        errorInterval = setInterval(() => {
          setErrorValue((prev) => prev + 100);
        }, 100);

        errorTimeout = setTimeout(() => {
          setStep(0);
          setQuizStep(0);
          setAnswers([]);
          clearInterval(errorInterval);
        }, 5000);
      },
    },
  );

  const handleVideoEnd = () => {
    if (videoData?.progress.length > 0 || videoData?.quiz?.length === 0) {
      sendVideo({ id: videoId! });
      return;
    }

    setStep(1);
  };

  const answerQuestion = (questionId: string, answer: string) => {
    const newAnswers = answers.filter((a) => a.questionId !== questionId);
    newAnswers.push({ questionId, answer });
    setAnswers(newAnswers);
    setQuizStep(quizStep + 1);
    if (quizStep === videoData?.quiz?.length - 1) {
      sendVideo({ id: videoId!, quiz: newAnswers });
      return;
    }
  };

  useEffect(() => {
    if (videoData?.status === "INACTIVE" && auth?.user?.role !== "ADMIN") {
      navigate(ROUTES.kBase.getCourse({ id: module?.courseId }));
    }
  }, [auth?.user?.role, module?.courseId, navigate, videoData]);

  useEffect(() => {
    setStep(0);
    setErrorValue(0);

    return () => {
      clearTimeout(errorTimeout);
      clearInterval(errorInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || videoIsLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  if (!module)
    return (
      <Layout showFooter>
        <AddAndModifyButtons
          moduleId={id!}
          courseId={module.courseId}
          videoId={videoId!}
        />
        <div
          className="flex items-center gap-2 pb-4 pt-6 cursor-pointer opacity-70 w-min"
          onClick={() => navigate(ROUTES.kBase.root)}
        >
          <IconArrowLeft size={24} />
          <span className="whitespace-nowrap">{t("back")}</span>
        </div>
        <Spacer y={40} />
        <div className="flex justify-center items-center flex-col">
          <IconMoodSad size={100} className="text-center text-secondary-200" />
          <Spacer y={2} />
          <h2 className="text-center text-lg opacity-85">
            {t("notAvailable")}
          </h2>
          <h3 className="text-center text-sm opacity-70">{t("retryLater")}</h3>
        </div>
      </Layout>
    );

  return (
    <Layout showFooter>
      <div>
        <AddAndModifyButtons
          moduleId={id!}
          courseId={module.courseId}
          videoId={videoId!}
        />
        <div
          className="flex items-center gap-2 pb-4 pt-6 cursor-pointer opacity-70 w-min"
          onClick={() =>
            navigate(ROUTES.kBase.getCourse({ id: state.courseId }))
          }
        >
          <IconArrowLeft size={24} />
          <span className="whitespace-nowrap">{t("back")}</span>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 md:gap-8">
        <div className="flex flex-col md:order-last col-span-3">
          <h1 className="text-center md:text-left font-bold text-2xl opacity-75">
            {videoData?.title}
          </h1>
          <Spacer y={6} />
          {step === 0 && videoData?.url && (
            <>
              <ReactPlayer
                // style={{ borderRadius: "1rem", overflow: "hidden" }}
                url={videoData.url}
                controls
                width="100%"
                height="100%"
                onEnded={handleVideoEnd}
                playsinline
              />
              <Spacer y={4} />
              <p className="text-sm">{videoData.description}</p>
            </>
          )}
          {step === 1 && videoData?.quiz && (
            <>
              <div key="quiz" className="flex flex-col gap-2">
                <h1 className="text-center md:text-left font-bold text-2xl text-secondary-300">
                  {t("quiz")}
                </h1>
                {videoData.quiz?.map((question: QuizDto, index: number) => (
                  <AnimatePresence mode="popLayout" key={question.id}>
                    {quizStep === index && (
                      <motion.div
                        key={index}
                        className="flex flex-col gap-2"
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -100 }}
                      >
                        <h2 className="font-semibold">{question.question}</h2>
                        {question.options?.map((answer: string) => (
                          <div
                            key={answer}
                            className="cursor-pointer w-full border-2 border-solid border-accent-200 p-3 rounded-xl"
                            onClick={() => answerQuestion(question.id!, answer)}
                          >
                            <span>{answer}</span>
                          </div>
                        ))}
                      </motion.div>
                    )}
                    {completeIsLoading && quizStep === index + 1 ? (
                      <motion.div
                        className="flex justify-center items-center gap-2"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <Spacer x={2} />
                        <Spinner />
                        <h1 className="text-primary-200">{t("checking")}</h1>
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                ))}
              </div>
              <Spacer y={2} />
            </>
          )}
          {step === 2 && (
            <div className="flex flex-col gap-2 justify-center items-center">
              <Progress maxValue={4500} value={errorValue} />
              <IconMoodSad
                size={100}
                className="text-center text-secondary-200"
              />
              <h1 className="text-center md:text-left font-bold text-2xl text-secondary-300">
                {t("incorrectResponse", { count: videoData?.quiz?.length })}
              </h1>
              <p className="text-center md:text-left">{t("retry")}</p>
            </div>
          )}
          <Spacer y={4} />
        </div>
        <Card
          classNames={{
            base: "flex flex-grow mb-4",
          }}
        >
          <CardHeader className="justify-center">
            <h2 className=" uppercase font-bold">{t("videos")}</h2>
          </CardHeader>
          <CardBody className="flex flex-col gap-1">
            {module.videos
              .sort((a: VideoType, b: VideoType) => a.order - b.order)
              .map((video: VideoType, index: number) => {
                const isLast =
                  auth?.user?.role === "ADMIN"
                    ? module.videos.length === index + 1
                    : module.videos.filter(
                        (v: VideoType) => v.status === "ACTIVE",
                      ).length ===
                      index + 1;
                const canPlay = video?.canPlay;

                if (
                  auth?.user?.role !== "ADMIN" &&
                  video.status === "INACTIVE"
                ) {
                  return null;
                }

                return (
                  <Fragment key={video.id}>
                    <div
                      className={`flex gap-2 ${canPlay ? "hover:bg-background-100 cursor-pointer" : "cursor-default opacity-50"} p-2 rounded-md  items-center`}
                      onClick={() => {
                        if (canPlay || auth?.user?.role === "ADMIN") {
                          navigate(
                            ROUTES.kBase.getModule({
                              id: id!,
                              videoId: video.id,
                            }),
                            {
                              state: {
                                courseId: module.courseId,
                                moduleId: module.id,
                              },
                              replace: true,
                            },
                          );
                          setStep(0);
                          setQuizStep(0);
                          setAnswers([]);
                        }
                      }}
                    >
                      <Chip
                        color={video.id === videoId ? "primary" : "default"}
                      >
                        {video.order}
                      </Chip>
                      <div>
                        <h1 className="opacity-70">{video.title}</h1>
                        {video.status === "INACTIVE" && (
                          <Chip color="default" size="sm">
                            {t("notPublic")}
                          </Chip>
                        )}
                      </div>
                      {!canPlay && <IconLock />}
                      {video.completed && (
                        <IconDiscountCheckFilled fill="var(--primary-200)" />
                      )}
                    </div>
                    {!isLast && <Divider />}
                  </Fragment>
                );
              })}
          </CardBody>
        </Card>
      </div>
    </Layout>
  );
}
