export const API_URL = import.meta.env.VITE_API_BASE_URL;

export const STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY;

export const APP_DOMAIN = import.meta.env.VITE_APP_DOMAIN;

export const GETSTREAM_API_KEY = import.meta.env.VITE_GETSTREAM_API_KEY;

export const STATSIG_ID = import.meta.env.VITE_STATSIG_ID;

export const FB_PIXEL_ID = import.meta.env.VITE_FB_PIXEL_ID;

export const FB_PIXEL_ID_NQ = import.meta.env.VITE_FB_PIXEL_ID_NQ;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
