import { FormEvent, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { ROUTES } from "../../../../constants/routes";
import Layout from "../../../../components/Layout";
import {
  Image,
  Input,
  Spacer,
  Spinner,
  Switch,
  Textarea,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import {
  createLecturer,
  getLecturer,
  updateLecturer,
} from "../../../../api/admin/app/kBase/lecturers.api";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTiktok,
  IconBrandYoutube,
  IconDragDrop,
  IconDragDrop2,
  IconMailFast,
  IconWorldWww,
} from "@tabler/icons-react";
import { getImageUrl } from "../../../../utils/images.utils";
import { Button } from "../../../../components/Button";

const schema = yup
  .object()
  .shape(
    {
      name: yup.string().required(),
      description: yup.string().required(),
      email: yup.string().email().required(),
      instagram: yup.string().nullable().optional().url(),
      facebook: yup.string().nullable().optional().url(),
      linkedin: yup.string().nullable().optional().url(),
      youtube: yup.string().nullable().optional().url(),
      tiktok: yup.string().nullable().optional().url(),
      website: yup.string().nullable().optional().url(),
      active: yup.boolean().default(false),
    },
    [
      ["instagram", "instagram"],
      ["facebook", "facebook"],
      ["linkedin", "linkedin"],
      ["youtube", "youtube"],
      ["tiktok", "tiktok"],
      ["website", "website"],
    ],
  )
  .required();

export default function AdminLecturer({ isNew }: { isNew?: boolean }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("admin");
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      multiple: false,
      accept: { "image/*": [] },
    });

  const { isLoading: isCourseLoading, data: lecturer } = useQuery(
    ["lecturer", id],
    () => getLecturer(id!),
    {
      enabled: !isNew,
    },
  );

  useEffect(() => {
    if (!isNew && lecturer) {
      form.setValue("name", lecturer.name);
      form.setValue("description", lecturer.description);
      form.setValue("email", lecturer?.email);
      form.setValue("instagram", lecturer?.instagram);
      form.setValue("facebook", lecturer?.facebook);
      form.setValue("linkedin", lecturer?.linkedin);
      form.setValue("youtube", lecturer?.youtube);
      form.setValue("tiktok", lecturer?.tiktok);
      form.setValue("website", lecturer?.website);
      form.setValue("active", lecturer.status === "ACTIVE" ? true : false);
      if (acceptedFiles.length === 0) {
        acceptedFiles.push(new File([], lecturer.image));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lecturer]);

  useEffect(() => {
    return () => {
      acceptedFiles.pop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading: updateIsLoading, mutate: saveUpdatedLecturer } =
    useMutation(updateLecturer, {
      onError: (error: { message: string }) => {
        form.setError("root", { message: error.message });
      },
      onSuccess: () => {
        acceptedFiles.pop();
        navigate(ROUTES.admin.kBase.lecturers);
      },
    });

  const { isLoading: createIsLoading, mutate: saveLecturer } = useMutation(
    createLecturer,
    {
      onError: (error: { message: string }) => {
        form.setError("root", { message: error.message });
      },
      onSuccess: () => {
        acceptedFiles.pop();
        navigate(ROUTES.admin.kBase.lecturers);
      },
    },
  );

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log(form.formState.errors);
    handleSubmit((data) => {
      if (isNew) {
        saveLecturer({ ...data, images: acceptedFiles });
      } else {
        saveUpdatedLecturer({ id: id!, ...data, images: acceptedFiles });
      }
    })();
  };

  useEffect(() => {
    setIsLoading(createIsLoading || updateIsLoading || isCourseLoading);
  }, [createIsLoading, updateIsLoading, isCourseLoading]);

  if (!isNew && isCourseLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter classNames={{ main: "max-w-screen-sm" }}>
      <form onSubmit={onSubmit}>
        <h1 className="text-xl mt-4 font-semibold">
          {isNew
            ? t("kBase.lecturers.newLecturer")
            : t("kBase.lecturers.editLecturer")}
        </h1>

        <Controller
          name="active"
          control={control}
          render={({ field }) => (
            <Switch
              onValueChange={(isSelected) => field.onChange(isSelected)}
              isSelected={field.value}
              className="mt-4"
            >
              <span className="opacity-70 max-w">
                {t("kBase.lecturers.active")}
              </span>
            </Switch>
          )}
        />

        <Spacer y={4} />

        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("kBase.lecturers.name")}
              variant="bordered"
              color="primary"
              isInvalid={!!form.formState.errors.name?.message}
              errorMessage={form.formState.errors.name?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              label={t("kBase.lecturers.description")}
              variant="bordered"
              color="primary"
              rows={5}
              isInvalid={!!form.formState.errors.description?.message}
              errorMessage={form.formState.errors.description?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("kBase.lecturers.email")}
              variant="bordered"
              color="primary"
              endContent={<IconMailFast />}
              isInvalid={!!form.formState.errors.email?.message}
              errorMessage={form.formState.errors.email?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="website"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("kBase.lecturers.website")}
              variant="bordered"
              color="primary"
              endContent={<IconWorldWww />}
              isInvalid={!!form.formState.errors.website?.message}
              errorMessage={form.formState.errors.website?.message}
              value={field.value ?? undefined}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="instagram"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("kBase.lecturers.instagram")}
              variant="bordered"
              color="primary"
              endContent={<IconBrandInstagram />}
              isInvalid={!!form.formState.errors.instagram?.message}
              errorMessage={form.formState.errors.instagram?.message}
              value={field.value ?? undefined}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="facebook"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("kBase.lecturers.facebook")}
              variant="bordered"
              color="primary"
              endContent={<IconBrandFacebook />}
              isInvalid={!!form.formState.errors.facebook?.message}
              errorMessage={form.formState.errors.facebook?.message}
              value={field.value ?? undefined}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="linkedin"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("kBase.lecturers.linkedin")}
              variant="bordered"
              color="primary"
              endContent={<IconBrandLinkedin />}
              isInvalid={!!form.formState.errors.linkedin?.message}
              errorMessage={form.formState.errors.linkedin?.message}
              value={field.value ?? undefined}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="youtube"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("kBase.lecturers.youtube")}
              variant="bordered"
              color="primary"
              endContent={<IconBrandYoutube />}
              isInvalid={!!form.formState.errors.youtube?.message}
              errorMessage={form.formState.errors.youtube?.message}
              value={field.value ?? undefined}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="tiktok"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("kBase.lecturers.tiktok")}
              variant="bordered"
              color="primary"
              endContent={<IconBrandTiktok />}
              isInvalid={!!form.formState.errors.tiktok?.message}
              errorMessage={form.formState.errors.tiktok?.message}
              value={field.value ?? undefined}
            />
          )}
        />

        <div
          {...getRootProps()}
          className="mt-4 border-2 border-solid border-default-200 rounded-xl p-3 flex justify-center items-center flex-col shadow-sm "
        >
          <h1 className="text-primary-300 text-sm self-start pb-1">
            {t("kBase.lecturers.thumbnail")}
          </h1>
          <input {...getInputProps()} />
          {isDragActive && (
            <div
              className={
                (acceptedFiles.length ? "h-80" : "") +
                " flex flex-col justify-center items-center"
              }
            >
              <IconDragDrop2 size={48} className="text-primary-500" />
              <p className="text-primary-300 text-center text-sm">
                {t("kBase.lecturers.thumbnailDrop")}
              </p>
            </div>
          )}
          {isDragActive ||
            (!acceptedFiles.length && (
              <>
                <IconDragDrop size={48} className="text-primary-300" />
                <p className="text-primary-300 text-sm">
                  {t("kBase.lecturers.thumbnailDrag")}
                </p>
              </>
            ))}
          {!isDragActive && acceptedFiles.length > 0 && (
            <div>
              {acceptedFiles.map((file) => (
                <Image
                  key={file.name}
                  src={
                    file.name.includes("uploads")
                      ? getImageUrl(file.name)
                      : URL.createObjectURL(file)
                  }
                  alt={file.name}
                  className="rounded-lg max-h-80"
                />
              ))}
            </div>
          )}
        </div>
        {form.formState.errors.root && (
          <>
            <Spacer y={3} />
            <p className="text-red-500 text-sm text-center">
              {form.formState.errors.root.message}
            </p>
          </>
        )}

        <Button
          variant="solid"
          color="primary"
          className="mt-8 w-full"
          type="submit"
          isLoading={isLoading}
          isDisabled={isLoading || acceptedFiles.length === 0}
        >
          {t("kBase.lecturers.save")}
        </Button>

        <Spacer y={4} />
      </form>
    </Layout>
  );
}
