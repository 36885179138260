import { create } from "zustand";
import { AffiliateType, SubscriptionType } from "../types";

interface PaymentData {
  subscription: SubscriptionType | null;
  setSubscription: (subscription: SubscriptionType | null) => void;
  affiliateData: AffiliateType | null;
  setAffiliateData: (affiliateData: AffiliateType | null) => void;
}

export const usePayment = create<PaymentData>((set) => ({
  subscription: null,
  setSubscription: (subscription) => set(() => ({ subscription })),
  affiliateData: null,
  setAffiliateData: (affiliateData) => set(() => ({ affiliateData })),
}));
