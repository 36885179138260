import { FormEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Layout from "../../../components/Layout";
import {
  Input,
  Select,
  SelectItem,
  Spacer,
  Spinner,
  Switch,
} from "@nextui-org/react";
import { Button } from "../../../components/Button";
import { useMutation, useQuery } from "react-query";
import { ROUTES } from "../../../constants/routes";
import {
  createCoupon,
  getCouponById,
  updateCoupon,
} from "../../../api/admin/coupons";

const schema = yup
  .object()
  .shape({
    code: yup.string().required("A kód megadása kötelező"),
    active: yup.boolean().default(false),
    percentage: yup
      .number()
      .required("A kedvezmény mértékének megadása kötelező"),
  })
  .required();

export default function AdminCoupon({ isNew }: { isNew?: boolean }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("admin");
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const percentages = [
    { label: "5%", value: 5 },
    { label: "10%", value: 10 },
    { label: "15%", value: 15 },
    { label: "20%", value: 20 },
    { label: "25%", value: 25 },
    { label: "30%", value: 30 },
    { label: "35%", value: 35 },
    { label: "40%", value: 40 },
    { label: "45%", value: 45 },
    { label: "50%", value: 50 },
    { label: "55%", value: 55 },
    { label: "60%", value: 60 },
    { label: "65%", value: 65 },
    { label: "70%", value: 70 },
    { label: "75%", value: 75 },
    { label: "80%", value: 80 },
    { label: "85%", value: 85 },
    { label: "90%", value: 90 },
    { label: "95%", value: 95 },
    { label: "100%", value: 100 },
  ];

  const { isLoading: isCouponLoading, data: coupon } = useQuery(
    ["coupon", id],
    () => getCouponById(id!),
    {
      enabled: !isNew,
    },
  );

  useEffect(() => {
    if (!isNew && coupon) {
      form.setValue("code", coupon.code);
      form.setValue("active", coupon.status === "ACTIVE" ? true : false);
      form.setValue("percentage", coupon.percentage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupon]);

  const { isLoading: updateIsLoading, mutate: saveUpdatedCoupon } = useMutation(
    updateCoupon,
    {
      onError: (error: { message: string }) => {
        form.setError("root", { message: error.message });
      },
      onSuccess: () => {
        navigate(ROUTES.admin.coupons.root);
      },
    },
  );

  const { isLoading: createIsLoading, mutate: saveCoupon } = useMutation(
    createCoupon,
    {
      onError: (error: { message: string }) => {
        form.setError("root", { message: error.message });
      },
      onSuccess: () => {
        navigate(ROUTES.admin.coupons.root);
      },
    },
  );

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log(form.formState.errors);
    handleSubmit((data) => {
      if (isNew) {
        saveCoupon({ ...data });
      } else {
        saveUpdatedCoupon({ id: id!, ...data });
      }
    })();
  };

  useEffect(() => {
    setIsLoading(createIsLoading || updateIsLoading || isCouponLoading);
  }, [createIsLoading, updateIsLoading, isCouponLoading]);

  if (!isNew && isCouponLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter classNames={{ main: "max-w-screen-sm" }}>
      <form onSubmit={onSubmit}>
        <h1 className="text-xl mt-4 font-semibold">
          {isNew ? t("manageCoupons.newCoupon") : t("manageCoupons.editCoupon")}
        </h1>

        <Controller
          name="active"
          control={control}
          render={({ field }) => (
            <Switch
              onValueChange={(isSelected) => field.onChange(isSelected)}
              isSelected={field.value}
              className="mt-4"
            >
              <span className="opacity-70 max-w">
                {t("manageCoupons.active")}
              </span>
            </Switch>
          )}
        />

        <Spacer y={4} />

        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("manageCoupons.code")}
              variant="bordered"
              color="primary"
              isInvalid={!!form.formState.errors.code?.message}
              errorMessage={form.formState.errors.code?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="percentage"
          control={control}
          render={({ field }) => (
            <Select
              selectedKeys={[field.value]}
              onChange={(e) => field.onChange(e.target.value)}
              label={t("manageCoupons.percentage")}
              variant="bordered"
              color="primary"
              items={percentages}
              isInvalid={!!form.formState.errors.percentage?.message}
              errorMessage={form.formState.errors.percentage?.message}
              isDisabled={!isNew}
              disabled={!isNew}
            >
              {(item) => <SelectItem key={item.value}>{item.label}</SelectItem>}
            </Select>
          )}
        />

        {form.formState.errors.root && (
          <>
            <Spacer y={3} />
            <p className="text-red-500 text-sm text-center">
              {form.formState.errors.root.message}
            </p>
          </>
        )}

        <Button
          variant="solid"
          color="primary"
          className="mt-8 w-full"
          type="submit"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          {t("manageCoupons.save")}
        </Button>

        <Spacer y={4} />
      </form>
    </Layout>
  );
}
