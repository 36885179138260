import Layout from "../../components/Layout";
import {
  IconArrowLeft,
  IconPoint,
  IconSwitch3,
  IconUserX,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spacer,
  Spinner,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";
import { useMutation, useQuery } from "react-query";
import { cancelSubscription, getSubscription } from "../../api/profile.api";
import { ROUTES } from "../../constants/routes";
import { Button } from "../../components/Button";
import { useState } from "react";
import { useApp } from "../../store/app.store";
import { useAuth } from "../../store/auth.store";

export default function ManageSubscription() {
  const navigate = useNavigate();
  const { t } = useTranslation("profile");
  const [cancelStep, setCancelStep] = useState(0);
  const [cancelReason, setCancelReason] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const app = useApp();
  const { user } = useAuth();
  const isCancelled = user?.status === "CANCELLED";

  const { isLoading, data: subscription } = useQuery(
    "subscription",
    getSubscription,
  );

  const { mutate: cancelSub } = useMutation(cancelSubscription, {
    onSuccess: () => {
      navigate(ROUTES.profile.root, {
        state: { subscriptionCanceled: true },
        replace: true,
      });
    },
    onError: (error: { message: string }) => {
      setErrorMessage(error.message);
      setCancelStep(2);
    },
  });

  const handleCancelContinue = () => {
    if (cancelStep === 2) {
      cancelSub({ reason: cancelReason, password });
    }
    setCancelStep((prev) => prev + 1);
    setErrorMessage("");
  };

  if (isLoading) {
    return (
      <Layout>
        <div className="max-w-screen-sm mx-auto pt-4 flex flex-col items-center">
          <h1 className="text-center font-bold text-2xl">
            {t("manageSubscription.title")}
          </h1>
          <Spacer y={8} />
          <Spinner color="primary" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout showFooter>
      <div className="max-w-screen-sm mx-auto pt-4 flex flex-col items-center">
        <div
          className="flex items-center gap-2 pb-4 pt-2 cursor-pointer opacity-70 w-min self-start"
          onClick={() => navigate(ROUTES.profile.root)}
        >
          <IconArrowLeft size={24} />
          <span className="whitespace-nowrap">
            {t("manageSubscription.back")}
          </span>
        </div>
        <h1 className="text-center font-bold text-2xl">
          {t("manageSubscription.title")}
        </h1>
        <Spacer y={4} />
        <div className="bg-default-200 rounded-2xl flex flex-col w-full items-center px-4">
          <Spacer y={4} />
          <h2 className="opacity-70 text-lg">
            {t("manageSubscription.currentSubscription")}
          </h2>
          <Spacer y={4} />
          <Card
            classNames={{
              base: "w-full max-w-sm bg-primary-100 bg-opacity-30 mx-auto  p-4",
              header: "justify-center flex-col",
              footer: "justify-center",
            }}
          >
            <CardHeader>
              <h1 className="font-semibold text-lg">
                {subscription?.subscription?.name}
              </h1>
              <h2 className="text-sm opacity-50 line-through mt-2">
                {subscription?.subscription?.compareAmount} Ft
              </h2>
              <h2 className="text-xl text-secondary-600 font-bold">
                {subscription?.amount} Ft
              </h2>
            </CardHeader>
            <CardBody>
              <ul>
                <li className="flex flex-row">
                  <IconPoint className="stroke-primary" />1 havi elofizetes
                </li>
                <li className="flex flex-row">
                  <IconPoint className="stroke-primary" />1 havi elofizetes
                </li>
                <li className="flex flex-row">
                  <IconPoint className="stroke-primary" />1 havi elofizetes
                </li>
              </ul>
            </CardBody>
          </Card>
          <Spacer y={4} />
          {isCancelled && (
            <>
              <span className="text-lg text-warning">
                {t("manageSubscription.cancel.stillActiveUntilEnd")}
              </span>
              <Spacer y={4} />
            </>
          )}
        </div>
        <Spacer y={4} />
        <Divider />
        <Spacer y={4} />
        <Button
          variant="flat"
          color="defaultPrimary"
          size="lg"
          startContent={<IconSwitch3 className="md:hidden" />}
          endContent={<IconSwitch3 className="hidden md:block" />}
          className="mt-4 w-full justify-start md:justify-between"
          isDisabled={isCancelled}
          // onClick={() => navigate(ROUTES.profile.manageSubscription)}
        >
          {t("manageSubscription.changePlan")}
        </Button>
        <Button
          variant="flat"
          color="dangerPrimary"
          size="lg"
          startContent={<IconUserX className="md:hidden" />}
          endContent={<IconUserX className="hidden md:block" />}
          className="mt-4 w-full justify-start md:justify-between"
          onClick={() => onOpen()}
          isDisabled={isCancelled}
        >
          {t("manageSubscription.cancelPlan")}
        </Button>
        <Modal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          backdrop="blur"
          classNames={{
            base: app.theme === "light" ? "" : "dark",
            header: app.theme === "light" ? "" : "dark text-foreground",
            body: app.theme === "light" ? "" : "dark text-foreground",
          }}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  {t("manageSubscription.cancel.title")}
                </ModalHeader>
                <ModalBody>
                  {cancelStep === 0 && (
                    <p>{t("manageSubscription.cancel.text")}</p>
                  )}
                  {cancelStep === 1 && (
                    <>
                      <p>{t("manageSubscription.cancel.whyNot")}</p>
                      <Textarea
                        placeholder={t("manageSubscription.cancel.placeholder")}
                        className="mt-2"
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                        color="primary"
                        variant="bordered"
                      />
                    </>
                  )}
                  {cancelStep === 2 && (
                    <>
                      <p>{t("manageSubscription.cancel.enterPassword")}</p>
                      <Input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        size="sm"
                        label={t("manageSubscription.cancel.password")}
                        className="mt-2"
                        color="primary"
                        variant="bordered"
                      />
                    </>
                  )}
                  {cancelStep === 3 && <Spinner />}
                  {errorMessage && (
                    <p className="text-danger text-sm text-center">
                      {errorMessage}
                    </p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      onClose();
                      setCancelStep(0);
                    }}
                    color="defaultPrimary"
                  >
                    {t("manageSubscription.cancel.cancel")}
                  </Button>
                  <Button
                    onClick={handleCancelContinue}
                    variant="solid"
                    color="danger"
                    isDisabled={
                      (cancelStep === 1 && cancelReason === "") ||
                      (cancelStep === 2 && password === "")
                    }
                  >
                    {t("manageSubscription.cancel.confirm")}
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    </Layout>
  );
}
