import Layout from "../../../components/Layout";
import {
  Card,
  CardBody,
  CardFooter,
  Chip,
  Image,
  Progress,
  Spacer,
  Spinner,
} from "@nextui-org/react";
import {
  IconBook2,
  IconMoodSad,
  IconPencilPlus,
  IconVideo,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useQuery } from "react-query";
import { CourseType, getCourses } from "../../../api/app/kBase/courses.api";
import { useAuth } from "../../../store/auth.store";
import { Button } from "../../../components/Button";
import { getImageUrl } from "../../../utils/images.utils";
import { useTranslation } from "react-i18next";

const AddButton = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation("courses");

  if (auth.user!.role === "ADMIN" || auth.user!.role === "OWNER") {
    return (
      <div className="flex justify-center items-center pt-3 cursor-pointer opacity-70">
        <Button
          startContent={<IconPencilPlus />}
          variant="light"
          color="default"
          onClick={() => navigate(ROUTES.admin.kBase.newCourse)}
        >
          {t("addCourse")}
        </Button>
      </div>
    );
  }

  return null;
};

export default function KnowledgeBaseRoot() {
  const navigate = useNavigate();
  const { t } = useTranslation("courses");

  const { isLoading, data } = useQuery("courses", () => getCourses(), {
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  if (!data)
    return (
      <Layout showFooter>
        <AddButton />
        <h1 className="text-2xl font-semibold opacity-75 mt-6 mb-4">
          {t("courses")}
        </h1>
        <Spacer y={40} />
        <div className="flex justify-center items-center flex-col">
          <IconMoodSad size={100} className="text-center text-secondary-200" />
          <Spacer y={2} />
          <h2 className="text-center text-lg opacity-85">
            {t("notAvailable")}
          </h2>
          <h3 className="text-center text-sm opacity-70">{t("retryLater")}</h3>
        </div>
      </Layout>
    );

  return (
    <Layout showFooter>
      <AddButton />
      <h1 className="text-2xl font-semibold opacity-75 mt-6 mb-4">
        {t("courses")}
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {data.map((course: CourseType) => {
          const numberOfVideos = course.modules.reduce(
            (acc, val) => acc + val.videos.length,
            0,
          );

          const completedVideos = course.modules.reduce((acc, val) => {
            const completed = val.videos.filter(
              (video) => video.completed,
            ).length;
            return acc + completed;
          }, 0);

          return (
            <Card
              key={course.id}
              isPressable
              isHoverable
              isFooterBlurred
              disableRipple
              classNames={{
                base: "overflow-hidden",
              }}
              onPress={() =>
                navigate(ROUTES.kBase.getCourse({ id: course.id }))
              }
            >
              <CardBody className="overflow-visible p-0 realtive">
                {course.status === "INACTIVE" && (
                  <Chip color="default" className="absolute top-2 left-2 z-20">
                    {t("notPublic")}
                  </Chip>
                )}
                <Image
                  src={getImageUrl(course.image)}
                  alt={course.title}
                  radius="none"
                  classNames={{
                    img: "w-full",
                    wrapper:
                      "w-full !max-w-full h-40 md:h-64 flex items-center",
                  }}
                />
              </CardBody>
              <CardFooter className="flex flex-col items-start z-10">
                <div className="flex flex-row justify-between w-full">
                  <div className="flex flex-col items-start">
                    <h2 className="text-xl font-medium">{course.title}</h2>
                    <Spacer y={1} />
                    <p className="text-sm opacity-80 text-left">
                      {course.description}
                    </p>
                    <Spacer y={4} />
                  </div>
                  {course.lecturer && <Chip>{course.lecturer?.name}</Chip>}
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center w-2/3">
                    <IconVideo size={20} className="opacity-65 w-10" />
                    <Spacer x={2} />
                    <Progress
                      size="sm"
                      value={completedVideos}
                      maxValue={numberOfVideos}
                      color="secondary"
                    />
                    <Spacer x={4} />
                    <h3 className="text-sm opacity-65 whitespace-nowrap">
                      {completedVideos} / {numberOfVideos} {t("video")}
                    </h3>
                  </div>
                  <div className="flex items-center">
                    <IconBook2 size={20} className="opacity-65" />
                    <Spacer x={0.5} />
                    <h3 className="text-sm opacity-65">
                      {course.modules.length} {t("module")}
                    </h3>
                  </div>
                </div>
              </CardFooter>
            </Card>
          );
        })}
      </div>
      <Spacer y={12} />
    </Layout>
  );
}
