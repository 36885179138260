import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { Button } from "../../components/Button";
import { Select, SelectItem } from "@nextui-org/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { QuizType } from "../../api/quiz";
import { useStatsigClient } from "@statsig/react-bindings";

const schema = yup
  .object()
  .shape({
    pre_menstruation: yup.string().required(),
    pain: yup.string().required(),
  })
  .required();

export default function StepFour({
  onContinue,
  data,
}: {
  onContinue: (data: object) => void;
  data: QuizType;
}) {
  const { logEvent } = useStatsigClient();
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = () => {
    logEvent("free_analysis_step", "four");
    handleSubmit((data) => {
      onContinue(data);
    })();
  };

  useEffect(() => {
    if (data?.pre_menstruation && data?.pain) {
      form.setValue("pre_menstruation", data?.pre_menstruation, {
        shouldValidate: true,
      });
      form.setValue("pain", data?.pain, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="container mx-auto mt-4 max-w-screen-md px-2">
      <motion.h1
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="text-center text-2xl font-bold"
      >
        Menstruáció
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        A menstruációs szakaszt megelőzően szokott lennei PMS (premenstruációs
        szindróma) tüneted? pl.: hangulatingadozás, falási roham, fáradtság,
        puffadás, súlygyarapodás…
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-2"
      >
        <Controller
          name="pre_menstruation"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label="Válasz"
              size="lg"
              color="primary"
              variant="underlined"
            >
              <SelectItem key={"true"} value="true">
                Igen
              </SelectItem>
              <SelectItem key={"flase"} value="false">
                Nem
              </SelectItem>
            </Select>
          )}
        />
      </motion.div>
      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        Fájdalmas a menstruációd?
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-2"
      >
        <Controller
          name="pain"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label="Válasz"
              size="lg"
              color="primary"
              variant="underlined"
            >
              <SelectItem key={"true"} value="true">
                Igen
              </SelectItem>
              <SelectItem key={"flase"} value="false">
                Nem
              </SelectItem>
            </Select>
          )}
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.9, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        <Button
          onClick={onSubmit}
          className="w-full"
          variant="shadow"
          color="primary"
          isDisabled={!form.formState.isValid}
          size="lg"
        >
          Tovább
        </Button>
      </motion.div>
    </div>
  );
}
