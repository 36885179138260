import { RouteObject } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { RequireAuth } from "./RequireAuth";
import { RequireSubscription } from "./RequireSubscription";
import KnowledgeBaseRoot from "../pages/app/kBase";
import Course from "../pages/app/kBase/course";
import Module from "../pages/app/kBase/module";
import Lecturer from "../pages/app/kBase/lecturer";

export const KBaseRouter: RouteObject[] = [
  {
    path: ROUTES.kBase.root,
    element: (
      <RequireAuth>
        <RequireSubscription>
          <KnowledgeBaseRoot />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.kBase.course,
    element: (
      <RequireAuth>
        <RequireSubscription>
          <Course />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.kBase.module,
    element: (
      <RequireAuth>
        <RequireSubscription>
          <Module />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.kBase.lecturer,
    element: (
      <RequireAuth>
        <RequireSubscription>
          <Lecturer />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
];
