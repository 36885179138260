// import { useExperiment } from "@statsig/react-bindings";
import ReactPixel from "react-facebook-pixel";
// import HomePageV3 from "../components/home/v3/HomePageV3";
// import HomePageV1 from "../components/home/v1/HomePageV1";
import HomePageV5 from "../components/home/v5_quiz/HomePageV5";

export default function Interactive() {
  ReactPixel.pageView();

  return <HomePageV5 />;
}
