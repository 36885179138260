export const getIntervalData = (intervalData: string) => {
  let interval;

  if (intervalData.includes("m")) {
    interval = "m";
  }

  if (intervalData.includes("y")) {
    interval = "y";
  }

  if (intervalData.includes("w")) {
    interval = "w";
  }

  if (intervalData.includes("d")) {
    interval = "d";
  }

  return {
    unit: interval || "",
    time: intervalData.replace(/\D/g, ""),
  };
};
