import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { Image } from "@nextui-org/react";
import RecipesShowcase from "../../assets/recipesshowcase.png";
import { useQuery } from "react-query";
import { getSubscriptions } from "../../api/payment.api";
import BottomCTA from "../../components/home/v7/BottomCTA";

export default function Recipes() {
  const { data: subscriptions } = useQuery(
    "subscriptions",
    () => getSubscriptions(),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="bg-primary-300">
        <h1 className="px-4 py-24 text-center text-5xl font-bold">
          Tápanyagdús receptek
        </h1>
      </section>
      <section className=" bg-gradient-to-b from-primary-100 py-8">
        <div className="container mx-auto my-8 max-w-4xl px-4">
          <motion.div
            className="mt-6 flex w-full justify-center px-4"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <p className="max-w-2xl text-center">
              Folyamatosan bővülő, tápanyagban gazdag és változatos{" "}
              <span className="underline decoration-accent-500 decoration-2 underline-offset-[5px]">
                receptek
              </span>{" "}
              , amelyekkel könnyedén készíthetsz finom és egészséges ételeket
              minden kategóriában - levesektől a házi pékségig - húsos ételektől
              a vegán ételekig!
            </p>
          </motion.div>
          <div className="relative mt-8">
            <Image src={RecipesShowcase} className="w-full" alt="Receptek" />
            <div className="absolute bottom-0 left-0 right-0 top-0 z-50 h-full w-full bg-gradient-to-t from-background" />
          </div>
        </div>
      </section>
      <BottomCTA subscriptions={subscriptions} />
      <Footer shadow={false} />
    </>
  );
}
