import { API_URL } from "../../../../constants/environment";
import { axios } from "../../../axios";

axios.defaults.withCredentials = true;

export async function createLecturer({
  name,
  description,
  active,
  images,
  website,
  youtube,
  instagram,
  facebook,
  linkedin,
  tiktok,
  email,
}: {
  name: string;
  description: string;
  active?: boolean;
  website?: string | null;
  youtube?: string | null;
  instagram?: string | null;
  facebook?: string | null;
  linkedin?: string | null;
  tiktok?: string | null;
  email?: string;
  images: File[];
}) {
  const form = new FormData();

  for (const image of images) {
    if (image instanceof File) {
      form.append("images", image);
    }
  }

  form.append("name", name);
  form.append("description", description);
  form.append("website", website || "");
  form.append("youtube", youtube || "");
  form.append("instagram", instagram || "");
  form.append("facebook", facebook || "");
  form.append("linkedin", linkedin || "");
  form.append("tiktok", tiktok || "");
  form.append("email", email || "");
  if (active) form.append("active", active.toString());

  const response = await axios.post(`${API_URL}/admin/create-lecturer`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function updateLecturer({
  id,
  name,
  description,
  active,
  images,
  website,
  youtube,
  instagram,
  facebook,
  linkedin,
  tiktok,
  email,
}: {
  id: string;
  name: string;
  description: string;
  active?: boolean;
  website?: string | null;
  youtube?: string | null;
  instagram?: string | null;
  facebook?: string | null;
  linkedin?: string | null;
  tiktok?: string | null;
  email?: string;
  images: File[];
}) {
  const form = new FormData();

  for (const image of images) {
    if (image instanceof File) {
      form.append("images", image);
    }
  }

  form.append("id", id);
  form.append("name", name);
  form.append("description", description);
  form.append("website", website || "");
  form.append("youtube", youtube || "");
  form.append("instagram", instagram || "");
  form.append("facebook", facebook || "");
  form.append("linkedin", linkedin || "");
  form.append("tiktok", tiktok || "");
  form.append("email", email || "");
  if (active) form.append("active", active.toString());

  const response = await axios.post(`${API_URL}/admin/update-lecturer`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getLecturer(id: string) {
  const response = await axios.get(`${API_URL}/admin/get-lecturer/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getLecturers() {
  const response = await axios.get(`${API_URL}/admin/get-lecturers`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
