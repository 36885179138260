import { startCase } from "lodash";
import { API_URL } from "../constants/environment";
import { axios } from "./axios";
import { useAuth } from "../store/auth.store";

axios.defaults.withCredentials = true;

export async function checkAfiliate(code: string) {
  const response = await axios.post(`${API_URL}/payment/check-affiliate`, {
    code: code.toUpperCase(),
  });

  if (response.status == 200) {
    return response.data;
  }

  if (response.status == 400) {
    throw response.data;
  }

  throw response.data;
}

export async function getSubscriptions() {
  const response = await axios.get(`${API_URL}/payment/subscriptions`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function createSubscription({
  firstName,
  lastName,
  address,
  subscriptionId,
  affiliateId,
}: {
  firstName: string;
  lastName: string;
  address: object;
  subscriptionId: string;
  affiliateId?: string;
}) {
  const response = await axios.post(`${API_URL}/payment/create`, {
    firstName: startCase(firstName),
    lastName: startCase(lastName),
    address,
    subscriptionId,
    affiliateId,
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function createDirectSubscription({
  firstName,
  lastName,
  email,
  address,
  password,
  subscriptionId,
  affiliateId,
}: {
  firstName: string;
  lastName: string;
  email: string;
  address: object;
  password: string;
  subscriptionId: string;
  affiliateId?: string;
}) {
  const response = await axios.post(`${API_URL}/payment/direct-create`, {
    firstName: startCase(firstName),
    lastName: startCase(lastName),
    email,
    address,
    password,
    subscriptionId,
    affiliateId,
  });

  if (response.status == 200) {
    useAuth.getState().setUser(response.data);
    return response.data;
  }

  throw response.data;
}

export async function updateSubscription() {
  const response = await axios.post(`${API_URL}/payment/update`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function retryPayment() {
  const response = await axios.get(`${API_URL}/payment/retry`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
