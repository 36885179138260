import i18n from "../i18n";

export const UNITS = [
  {
    value: "gram",
    label: i18n.t("recipeunits:gram"),
    labelShort: i18n.t("recipeunits:gramShort"),
  },
  {
    value: "kilogram",
    label: i18n.t("recipeunits:kilogram"),
    labelShort: i18n.t("recipeunits:kilogramShort"),
  },
  {
    value: "liter",
    label: i18n.t("recipeunits:liter"),
    labelShort: i18n.t("recipeunits:literShort"),
  },
  {
    value: "milliliter",
    label: i18n.t("recipeunits:milliliter"),
    labelShort: i18n.t("recipeunits:milliliterShort"),
  },
  {
    value: "deciliter",
    label: i18n.t("recipeunits:deciliter"),
    labelShort: i18n.t("recipeunits:deciliterShort"),
  },
  {
    value: "teaspoon",
    label: i18n.t("recipeunits:teaspoon"),
    labelShort: i18n.t("recipeunits:teaspoonShort"),
  },
  {
    value: "tablespoon",
    label: i18n.t("recipeunits:tablespoon"),
    labelShort: i18n.t("recipeunits:tablespoonShort"),
  },
  {
    value: "piece",
    label: i18n.t("recipeunits:piece"),
    labelShort: i18n.t("recipeunits:pieceShort"),
  },
  {
    value: "pinch",
    label: i18n.t("recipeunits:pinch"),
    labelShort: i18n.t("recipeunits:pinchShort"),
  },
  {
    value: "finger",
    label: i18n.t("recipeunits:finger"),
    labelShort: i18n.t("recipeunits:fingerShort"),
  },
  {
    value: "handful",
    label: i18n.t("recipeunits:handful"),
    labelShort: i18n.t("recipeunits:handfulShort"),
  },
  {
    value: "head",
    label: i18n.t("recipeunits:head"),
    labelShort: i18n.t("recipeunits:headShort"),
  },
  {
    value: "bunch",
    label: i18n.t("recipeunits:bunch"),
    labelShort: i18n.t("recipeunits:bunchShort"),
  },
  {
    value: "clove",
    label: i18n.t("recipeunits:clove"),
    labelShort: i18n.t("recipeunits:cloveShort"),
  },
  {
    value: "fist",
    label: i18n.t("recipeunits:fist"),
    labelShort: i18n.t("recipeunits:fistShort"),
  },
  {
    value: "slice",
    label: i18n.t("recipeunits:slice"),
    labelShort: i18n.t("recipeunits:sliceShort"),
  },
  {
    value: "none",
    label: i18n.t("recipeunits:none"),
    labelShort: i18n.t("recipeunits:noneShort"),
  },
];
