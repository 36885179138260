import React from "react";
import ReactDOM from "react-dom/client";
import { Suspense } from "react";
import * as Sentry from "@sentry/react";
import SuspenseFallback from "./components/SuspenseFallback";
import App from "./App.tsx";
import "./index.css";
import "./i18n.ts";
import { SENTRY_DSN } from "./constants/environment.ts";
import Error from "./pages/Error.tsx";

Sentry.init({
  dsn: SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/nutriqueens\.hu/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Suspense fallback={<SuspenseFallback />}>
      <Sentry.ErrorBoundary fallback={<Error />}>
        <App />
      </Sentry.ErrorBoundary>
    </Suspense>
  </React.StrictMode>,
);
