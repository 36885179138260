import { API_URL } from "../../../../constants/environment";
import { axios } from "../../../axios";

axios.defaults.withCredentials = true;

export async function getRecipe(id: string) {
  const response = await axios.get(`${API_URL}/admin/recipe/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function createRecipe({
  title,
  description,
  active,
  time,
  servings,
  ingredients,
  instructions,
  tags,
  categories,
  nutritionalFacts,
  images,
}: {
  title: string;
  description: string;
  active?: boolean;
  time: string;
  servings: string;
  ingredients: {
    name: string;
    quantity: string;
    unit: string;
  }[];
  instructions: string;
  tags?: (string | undefined)[];
  categories?: (string | undefined)[];
  nutritionalFacts?: {
    calories: string;
    protein: string;
    fat: string;
    carbs: string;
  };
  images: File[];
}) {
  const form = new FormData();

  for (const image of images) {
    if (image instanceof File) {
      form.append("images", image);
    }
  }

  form.append("title", title);
  form.append("description", description);
  form.append("time", time);
  form.append("servings", servings.toString());
  form.append("ingredients", JSON.stringify(ingredients));
  form.append("instructions", instructions);
  form.append("tags", JSON.stringify(tags));
  form.append("categories", JSON.stringify(categories));
  form.append("nutritionalFacts", JSON.stringify(nutritionalFacts));
  if (active) form.append("active", active.toString());

  const response = await axios.post(`${API_URL}/admin/create-recipe`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function updateRecipe({
  id,
  title,
  description,
  active,
  time,
  servings,
  ingredients,
  instructions,
  tags,
  categories,
  nutritionalFacts,
  images,
}: {
  id: string;
  title: string;
  description: string;
  active?: boolean;
  time: string;
  servings: string;
  ingredients: {
    name: string;
    quantity: string;
    unit: string;
  }[];
  instructions: string;
  tags?: (string | undefined)[];
  categories?: (string | undefined)[];
  nutritionalFacts?: {
    calories: string;
    protein: string;
    fat: string;
    carbs: string;
  };
  images: File[];
}) {
  const form = new FormData();

  for (const image of images) {
    if (image instanceof File) {
      form.append("images", image);
    }
  }

  form.append("id", id);
  form.append("title", title);
  form.append("description", description);
  form.append("time", time);
  form.append("servings", servings.toString());
  form.append("ingredients", JSON.stringify(ingredients));
  form.append("instructions", instructions);
  form.append("tags", JSON.stringify(tags));
  form.append("categories", JSON.stringify(categories));
  form.append("nutritionalFacts", JSON.stringify(nutritionalFacts));
  if (active) form.append("active", active.toString());

  const response = await axios.post(`${API_URL}/admin/update-recipe`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
