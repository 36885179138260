import Layout from "../components/Layout";

export default function Terms() {
  return (
    <Layout showFooter>
      {/* <h1 className="mt-12 text-center text-2xl font-bold">
        ÁLTALÁNOS SZERZŐDÉSI FELTÉTELEK (ÁSZF)
      </h1>

      <p className="mt-2 text-sm text-center">
        <a href="https://nutriqueens.hu">https://nutriqueens.hu</a> - hatályos
        ettől a naptól: 2024.06.01.
      </p>

      <p className="mt-8">
        Kérjük, hogy megrendelése véglegesítése előtt figyelmesen olvassa el a
        jelen dokumentumot, mert megrendelése véglegesítésével Ön elfogadja a
        jelen ÁSZF tartalmát!
      </p>

      <p className="mt-4">
        Az Általános Szolgáltatási Feltételek („ÁSZF”) tartalmazzák az Szabó
        Barbara ev. (székhely: 6200 Kiskőrös, Agárhalom tanya 103..; adószám:
        56751974-1-23), mint szolgáltató (“Szolgáltató”) által üzemeltetett
        webáruház használatára vonatkozó általános szerződési feltételeket.
        Kérjük, hogy csak akkor vegye igénybe szolgáltatásainkat, amennyiben
        minden pontjával egyetért, és kötelező érvényűnek tekinti magára nézve.
        Jelen dokumentum nem kerül iktatásra, kizárólag elektronikus formában
        kerül megkötésre (nem minősül írásba foglalt szerződésnek), magatartási
        kódexre nem utal.
      </p>

      <ol className="list-decimal ml-6">
        <li className="pl-2 mt-4">
          <span className="font-bold">Impresszum - Üzemeltetői adatok:</span>{" "}
          <br />
          Név: Szabó Barbara ev. <br />
          Székhely: 6200 Kiskőrös, Agárhalom tanya 103. <br />
          Levelezési cím: 6200 Kiskőrös, Agárhalom tanya 103. <br />
          Adószám: 56751974-1-23 <br />
          E-mail: hello@szabobarbara.hu <br />
          Honlap: www.nutriqueens.hu <br />
        </li>
        <li className="pl-2 mt-4">
          <span className="font-bold">Alapvető rendelkezések:</span> <br />
          <ol className="list-[upper-roman] ml-6">
            <li className="pl-2">Hivatkozás a jogszabályokra:</li>
            <li className="pl-2">A szabályzat módosíthatósága.</li>
            <li className="pl-2">
              Szolgáltató fenntart magának minden jogot a weboldal, annak
              bármely részlete és az azon megjelenő tartalmak, valamint a
              weboldal terjesztésének tekintetében. Tilos a weboldalon megjelenő
              tartalmak vagy azok bármely részletének letöltése, elektronikus
              tárolása, feldolgozása és értékesítése a Szolgáltató írásos
              hozzájárulása nélkül.
            </li>
            <li className="pl-2">
              ÁSZF hatálya, elfogadása: A közöttünk létrejövő szerződés
              tartalmát - a vonatkozó kötelező érvényű jogszabályok
              rendelkezései mellett - a jelen Általános Szerződési Feltételek (a
              továbbiakban: ÁSZF) határozzák meg. Ennek megfelelően tartalmazza
              a jelen ÁSZF az Önt és bennünket illető jogokat és
              kötelezettségeket, a szerződés létrejöttének feltételeit, a
              teljesítési határidőket, a szállítási és fizetési feltételeket, a
              felelősségi szabályokat, valamint az elállási jog gyakorlásának
              feltételeit. <br />
              A Honlap használatához szükséges azon technikai tájékoztatást,
              melyet jelen ÁSZF nem tartalmaz, a Honlapon elérhető egyéb
              tájékoztatások nyújtják. <br />
              Ön a megrendelése véglegesítése előtt köteles megismerni a jelen
              ÁSZF rendelkezéseit. A webáruházunkon keresztül történő
              vásárlással Ön elfogadja a jelen ÁSZF rendelkezéseit, és az ÁSZF
              maradéktalanul az Ön és az Szolgáltató között létrejövő szerződés
              részét képezi.
            </li>
          </ol>
        </li>
        <li className="pl-2 mt-4">
          <span className="font-bold">
            Megvásárolható termékek, szolgáltatások köre:
          </span>{" "}
          <br />A honlapon a megvásárolható termékek lényeges tulajdonságairól
          az egyes termékeknél szereplő leírásokban adunk tájékoztatást.
          <ol className="list-[upper-roman] ml-6">
            <li className="pl-2">
              Az adatbeviteli hibák javítása - felelősség a megadott adatok
              valóságáért
            </li>
          </ol>
        </li>
      </ol> */}
      <h1 className="text-center mt-8">Feltöltés alatt...</h1>
    </Layout>
  );
}
