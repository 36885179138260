import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type App = {
  theme: string;
  toggleTheme: () => void;
};

export const useApp = create<App>()(
  persist(
    (set) => ({
      theme: "light",
      toggleTheme: () =>
        set((prev) => ({ theme: prev.theme === "light" ? "dark" : "light" })),
    }),
    {
      name: "app-storage-app",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
