import { API_URL } from "../../../../constants/environment";
import { axios } from "../../../axios";

axios.defaults.withCredentials = true;

export async function createCourse({
  title,
  description,
  active,
  lecturerId,
  images,
}: {
  title: string;
  description: string;
  active?: boolean;
  lecturerId?: string;
  images: File[];
}) {
  const form = new FormData();

  for (const image of images) {
    if (image instanceof File) {
      form.append("images", image);
    }
  }

  form.append("title", title);
  form.append("description", description);
  form.append("lecturerId", lecturerId || "");
  if (active) form.append("active", active.toString());

  const response = await axios.post(`${API_URL}/admin/create-course`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function updateCourse({
  id,
  title,
  description,
  active,
  lecturerId,
  images,
}: {
  id: string;
  title: string;
  description: string;
  active?: boolean;
  lecturerId?: string;
  images: File[];
}) {
  const form = new FormData();

  for (const image of images) {
    if (image instanceof File) {
      form.append("images", image);
    }
  }

  form.append("id", id);
  form.append("title", title);
  form.append("description", description);
  form.append("lecturerId", lecturerId || "");
  if (active) form.append("active", active.toString());

  const response = await axios.post(`${API_URL}/admin/update-course`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
