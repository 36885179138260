import { API_URL } from "../../constants/environment";
import { axios } from "../axios";

export async function getAdminSubscriptions() {
  const response = await axios.get(`${API_URL}/admin/subscriptions`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getSubscriptionById(id: string) {
  const response = await axios.get(`${API_URL}/admin/subscription/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function updateSubscription({
  id,
  name,
  active,
  description,
  interval,
  amount,
  compareAmount,
}: {
  id: string;
  name: string;
  active?: boolean;
  description: string;
  interval: { time: string; unit: string };
  amount: string;
  compareAmount?: string;
}) {
  const response = await axios.post(`${API_URL}/admin/update-subscription`, {
    id,
    name,
    status: active ? "ACTIVE" : "INACTIVE",
    description,
    interval: interval?.time + interval?.unit,
    amount,
    compareAmount,
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function createSubscription({
  name,
  active,
  description,
  interval,
  amount,
  compareAmount,
}: {
  name: string;
  active: boolean;
  description: string;
  interval: { time: string; unit: string };
  amount: string;
  compareAmount?: string;
}) {
  const response = await axios.post(`${API_URL}/admin/create-subscription`, {
    name,
    status: active ? "ACTIVE" : "INACTIVE",
    description,
    interval: interval?.time + interval?.unit,
    amount,
    compareAmount,
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
