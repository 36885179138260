import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Image } from "@nextui-org/react";
import Nutrition from "../../assets/nutrition.svg";
import Style from "../../assets/style.svg";
import Cycle from "../../assets/cycle.svg";
import Training from "../../assets/training.svg";
import Yoga from "../../assets/yoga.svg";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { getSubscriptions } from "../../api/payment.api";
import { useQuery } from "react-query";
import BottomCTA from "../../components/home/v7/BottomCTA";

export default function KBase() {
  const { data: subscriptions } = useQuery(
    "subscriptions",
    () => getSubscriptions(),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="bg-primary-300">
        <h1 className="py-24 text-center text-5xl font-bold">Tudástár</h1>
      </section>
      <section className="text  bg-gradient-to-b from-primary-100 py-8">
        <motion.div
          className="mt-6 flex w-full justify-center px-4"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          viewport={{ once: true }}
        >
          <p className="max-w-2xl text-center">
            Minden tudás a{" "}
            <span className="underline decoration-accent-500 decoration-2 underline-offset-[5px]">
              női egészségről
            </span>{" "}
            egy helyen: Fedezd fel az életmódtól a táplálkozásig mindent, amire
            szükséged van a teljes körű egészséghez és jólléthez.
          </p>
        </motion.div>
        <div className="container mx-auto mt-12 grid w-full grid-cols-1 gap-4 px-4 md:grid-cols-2">
          <motion.div
            className="w-full rounded-xl bg-default-200 p-8"
            initial={{ opacity: 0, x: -10 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="flex w-full flex-row-reverse items-center gap-4">
              <div className="mb-4 flex w-full justify-center md:mb-0">
                <h3 className="text-center text-2xl font-bold md:text-3xl ">
                  Táplálkozás
                </h3>
              </div>
              <div className="flex w-full justify-center">
                <Image
                  src={Nutrition}
                  className="h-48 w-48 object-cover"
                  alt="Táplálkozás"
                />
              </div>
            </div>
          </motion.div>

          <motion.div
            className="w-full rounded-xl bg-default-200 p-8"
            initial={{ opacity: 0, x: 10 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="flex w-full flex-row-reverse items-center gap-4">
              <div className="mb-4 flex w-full justify-center md:mb-0">
                <h3 className="text-center text-2xl font-bold md:text-3xl ">
                  Jóga
                </h3>
              </div>
              <div className="flex w-full justify-center">
                <Image
                  src={Yoga}
                  className="h-48 w-48 object-cover"
                  alt="Yoga"
                />
              </div>
            </div>
          </motion.div>

          <motion.div
            className="w-full rounded-xl bg-default-200 p-8"
            initial={{ opacity: 0, x: -10 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="flex w-full flex-row-reverse items-center gap-4">
              <div className="mb-4 flex w-full justify-center md:mb-0">
                <h3 className="text-center text-2xl font-bold md:text-3xl ">
                  Ciklus- szinkron
                </h3>
              </div>
              <div className="flex w-full justify-center">
                <Image
                  src={Cycle}
                  className="h-48 w-48 object-cover"
                  alt="Mensturacios ciklus"
                />
              </div>
            </div>
          </motion.div>

          <motion.div
            className="w-full rounded-xl bg-default-200 p-8"
            initial={{ opacity: 0, x: 10 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="flex w-full flex-row-reverse items-center gap-4">
              <div className="mb-4 flex w-full justify-center md:mb-0">
                <h3 className="text-center text-2xl font-bold md:text-3xl ">
                  Szín- és stílus
                </h3>
              </div>
              <div className="flex w-full justify-center">
                <Image
                  src={Style}
                  className="h-48 w-48 object-cover"
                  alt="Szin es stilus"
                />
              </div>
            </div>
          </motion.div>

          <motion.div
            className="w-full rounded-xl bg-default-200 p-8"
            initial={{ opacity: 0, x: -10 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="flex w-full flex-row-reverse items-center gap-4">
              <div className="mb-4 flex w-full justify-center md:mb-0">
                <h3 className="text-center text-2xl font-bold md:text-3xl ">
                  Gerinc- tréning
                </h3>
              </div>
              <div className="flex w-full justify-center">
                <Image
                  src={Training}
                  className="h-48 w-48 object-cover"
                  alt="Gerinc trening"
                />
              </div>
            </div>
          </motion.div>

          <motion.div
            className="w-full rounded-xl bg-gradient-to-t from-accent-100 to-primary-200 p-8"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            viewport={{ once: true }}
          >
            <div className="flex h-full w-full flex-row-reverse items-center justify-center gap-4">
              <div className="mb-4 flex w-full items-center justify-center md:mb-0">
                <h3 className="text-center text-2xl font-bold md:text-3xl ">
                  Még több hamarosan...
                </h3>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
      <BottomCTA subscriptions={subscriptions} />
      <Footer shadow={false} />
    </>
  );
}
