import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EDITOR_TOOLS } from '../../constants/editor.constant';

interface EditorProps {
  onChange: (value: string) => void;
  value: string;
  readOnly?: boolean;
}

function Editor({ onChange, value, readOnly }: EditorProps) {
  return (
    <div className="max-w-full prose ">
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        modules={{
          toolbar: EDITOR_TOOLS,
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true,
          },
        }}
        className='overflow-y-auto [&>*:first-child]:rounded-t-xl [&>*:last-child]:rounded-b-xl'
      />
    </div>
  );
}

export default Editor;
