import { createBrowserRouter } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import Home from "../pages/home";
import AppHome from "../pages/app";
import { KBaseRouter } from "./KBaseRouter";
import { RecipesRouter } from "./RecipesRouter";
import { ProfileRouter } from "./ProfileRouter";
import { AuthRouter } from "./AuthRouter";
import { RequireAuth } from "./RequireAuth";
import { RequireSubscription } from "./RequireSubscription";
import { AdminRouter } from "./AdminRouter";
import Chat from "../pages/chat/chat";
import Terms from "../pages/terms";
import Privacy from "../pages/pricavy";
import { PresentationRouter } from "./PresentationRouter";
import Interactive from "../pages/interactive";
import Quiz from "../pages/quiz/quiz";
import QuizThanks from "../pages/quiz/quizThanks";
import QuizResults from "../pages/quiz/quizResults";

export const Router = createBrowserRouter([
  {
    path: ROUTES.root,
    element: <Home />,
  },
  {
    path: ROUTES.interactive,
    element: <Interactive />,
  },
  {
    path: ROUTES.quiz,
    element: <Quiz />,
  },
  {
    path: ROUTES.quizThanks,
    element: <QuizThanks />,
  },
  {
    path: ROUTES.quizResults,
    element: <QuizResults />,
  },
  {
    path: ROUTES.appRoot,
    element: (
      <RequireAuth>
        <RequireSubscription>
          <AppHome />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.chat.root,
    element: (
      <RequireAuth>
        <RequireSubscription>
          <Chat />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.terms,
    element: <Terms />,
  },
  {
    path: ROUTES.privacy,
    element: <Privacy />,
  },
  ...AuthRouter,
  ...ProfileRouter,
  ...RecipesRouter,
  ...KBaseRouter,
  ...AdminRouter,
  ...PresentationRouter,
]);
