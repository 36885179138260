import Header from "../../components/Header";
import { ROUTES } from "../../constants/routes";
import { useStatsigClient } from "@statsig/react-bindings";
import { useNavigate } from "react-router-dom";

export default function QuizThanks() {
  const navigate = useNavigate();
  const { logEvent } = useStatsigClient();

  return (
    <>
      <Header />
      <section className="flex flex-col items-center justify-start bg-gradient-to-t from-primary-100">
        <div className="mx-auto mt-12 flex w-full max-w-screen-md flex-col  px-4">
          <h1 className="mb-4 text-center text-3xl font-bold">
            Megkaptuk a válaszaidat! 🎉
          </h1>
          <p className="text-center">
            Hamarosan küldjük a személyre szabott elemzésedet!
          </p>
        </div>
      </section>
      <section className="flex flex-col items-center justify-start bg-gradient-to-b from-primary-100">
        <div className="max-w-screen-md px-4 text-center">
          <h2 className="pt-40 text-4xl font-bold uppercase leading-tight">
            Tudd meg, hogy{" "}
            <span className="inline-block bg-gradient-to-r from-accent-600 to-secondary-500 bg-clip-text text-transparent">
              neked
            </span>{" "}
            való-e a Nutriqueens
          </h2>
          <p className="mt-6">
            Töltsd ki a rövid kvízünket, és derítsd ki, hogy hogyan tudunk
            segíteni neked!
          </p>
          <button
            onClick={() => {
              navigate(ROUTES.interactive);
              logEvent("interested_in_quiz");
            }}
            className="focus:shadow-outline z-50 mx-auto mt-6 transform cursor-pointer rounded-full bg-gradient-to-r from-accent-300 to-primary-500 px-8 py-4 font-semibold uppercase text-white shadow-lg transition duration-300 ease-in-out hover:scale-105 focus:outline-none lg:mx-0"
          >
            Igen, érdekel!
          </button>
        </div>
      </section>
    </>
  );
}
