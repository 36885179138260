import Layout from "../../components/Layout";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { IconChevronRight } from "@tabler/icons-react";

export default function AdminMenu() {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");

  return (
    <Layout showFooter>
      <div className="mx-auto flex max-w-screen-sm flex-col items-center pt-4">
        <h1 className="text-center text-2xl font-bold">{t("title")}</h1>
        <Button
          variant="bordered"
          color="defaultPrimary"
          size="lg"
          className="mt-4 w-full justify-between"
          endContent={<IconChevronRight />}
          onClick={() => navigate(ROUTES.admin.kBase.lecturers)}
        >
          {t("lecturers")}
        </Button>
        <Button
          variant="bordered"
          color="defaultPrimary"
          size="lg"
          className="mt-4 w-full justify-between"
          endContent={<IconChevronRight />}
          onClick={() => navigate(ROUTES.admin.subscriptions.root)}
        >
          {t("subscriptions")}
        </Button>
        <Button
          variant="bordered"
          color="defaultPrimary"
          size="lg"
          className="mt-4 w-full justify-between"
          endContent={<IconChevronRight />}
          onClick={() => navigate(ROUTES.admin.coupons.root)}
        >
          {t("coupons")}
        </Button>
        <Button
          variant="bordered"
          color="defaultPrimary"
          size="lg"
          className="mt-4 w-full justify-between"
          endContent={<IconChevronRight />}
          onClick={() => navigate(ROUTES.admin.users.root)}
        >
          {t("users")}
        </Button>
        <Button
          variant="bordered"
          color="defaultPrimary"
          size="lg"
          className="mt-4 w-full justify-between"
          endContent={<IconChevronRight />}
          onClick={() => navigate(ROUTES.admin.analysis.root)}
        >
          {t("analysis")}
        </Button>
      </div>
    </Layout>
  );
}
