import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(
    resourcesToBackend(async (language: string, namespace: string) => {
      // console.log(language, namespace);
      const data = await import(`./locales/${language}/${namespace}.json`);
      return data;
    }),
  )
  .init({
    lng: "hu-HU", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    // debug: true,
    // load: "currentOnly",
  });

export default i18n;
