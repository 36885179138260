import { Card, CardBody, CardHeader, Input, Spacer } from "@nextui-org/react";
import Layout from "../../components/Layout";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { IconChevronLeft } from "@tabler/icons-react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormEvent, useState } from "react";
import { forgotPassword } from "../../api/auth.api";
import { useTranslation } from "react-i18next";

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
  })
  .required()

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const {t} = useTranslation("auth");

  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
  });

  const { isLoading, mutate: requestPassword } = useMutation(forgotPassword, {
    onError: (error: {message: string}) => {
      form.setError("root", { message: error.message });
    },
    onSuccess: () => {
      setStep(2);
    }
  })

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit((data) => {
      requestPassword(data);
    })();
  }

  return (
    <Layout headerVariant="simple" classNames={{main: 'items-center justify-center flex'}}>
      <Card
        classNames={{
          base: 'w-full max-w-sm mx-auto',
          header: 'justify-center flex-col',
        }}
        as="form"
        onSubmit={onSubmit}
      >
        {step === 1 && (
          <>
            <CardHeader>
              <IconChevronLeft onClick={() => navigate(ROUTES.login)} className="cursor-pointer absolute top-3 left-3" size={28} />
              <h1 className="font-medium text-lg">
                {t("fogotPassword.title")}
              </h1>
              <Spacer y={1} />
              <p className="font-light text-sm text-center">
              {t("fogotPassword.description")}
              </p>
            </CardHeader>
            <CardBody>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t("fogotPassword.email")}
                    size="sm"
                    color="primary"
                    variant="bordered"
                  />
                )}
              />
              {form.formState.errors.root && (
                <>
                  <p className="text-red-500 text-sm text-center">{form.formState.errors.root.message}</p>
                  <Spacer y={3} />
                </>
              )}
              <Spacer y={3} />
              <Button
                variant="solid"
                color="primary"
                type="submit"
                isLoading={isLoading}
                isDisabled={isLoading || !form.formState.isValid}
              >
                {t("fogotPassword.submit")}
              </Button>
            </CardBody>
          </>
        )}
        {step === 2 && (
          <>
            <CardHeader>
              <h1 className="font-medium text-lg">
                {t("fogotPassword.successTitle")}
                </h1>
                <Spacer y={1} />
                <p className="text-sm text-center">
                {t("fogotPassword.successDescription")}
                </p>
            </CardHeader>
            <CardBody>
              <Button
                variant="solid"
                color="primary"
                onClick={() => {setStep(1); form.reset()}}
              >
                {t("fogotPassword.back")}
              </Button>
            </CardBody>
          </>
        )}
      </Card>
    </Layout>
  )
}
