import { API_URL } from "../../../constants/environment";
import { axios } from "../../axios";

axios.defaults.withCredentials = true;

export async function getTags() {
  const response = await axios.get(`${API_URL}/recipes/tags`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getRecipes({
  selectedTags,
  searchQuery,
  skip,
}: {
  selectedTags: (string | undefined)[];
  searchQuery: string;
  skip: number;
}) {
  const getQueryParams = () => {
    const queryParams = [];

    if (selectedTags.length > 0) {
      queryParams.push(`tags=${selectedTags.join(",")}`);
    }

    if (searchQuery) {
      queryParams.push(`search=${searchQuery}`);
    }

    if (skip) {
      queryParams.push(`skip=${skip}`);
    }

    return queryParams.join("&");
  };

  const response = await axios.get(`${API_URL}/recipes?${getQueryParams()}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getRecipeById(id: string) {
  const response = await axios.get(`${API_URL}/recipes/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function toggleLike(id: string) {
  const response = await axios.patch(`${API_URL}/recipes/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getSavedRecipes() {
  const response = await axios.get(`${API_URL}/recipes/saved`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
