import { API_URL } from "../../../../constants/environment";
import { QuizDto } from "../../../../pages/admin/app/kBase/video";
import { axios } from "../../../axios";

axios.defaults.withCredentials = true;

export async function creatVideo({
  title,
  active,
  videos,
  order,
  description,
  moduleId,
  quiz,
}: {
  title: string;
  active?: boolean;
  description: string;
  order: string;
  videos: File[];
  moduleId: string;
  quiz?: QuizDto[] | null;
}) {
  const form = new FormData();

  for (const video of videos) {
    if (video instanceof File) {
      form.append("images", video);
    }
  }

  form.append("title", title);
  form.append("moduleId", moduleId);
  form.append("description", description);
  form.append("order", order);
  form.append("quiz", JSON.stringify(quiz));
  if (active) form.append("active", active.toString());

  const response = await axios.post(`${API_URL}/admin/create-video`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function updateVideo({
  id,
  title,
  active,
  videos,
  order,
  description,
  moduleId,
  quiz,
}: {
  id: string;
  title: string;
  active?: boolean;
  description: string;
  order: string;
  videos: File[];
  moduleId: string;
  quiz?: QuizDto[] | null;
}) {
  const form = new FormData();

  for (const video of videos) {
    if (video instanceof File) {
      form.append("images", video);
    }
  }

  form.append("id", id);
  form.append("title", title);
  form.append("moduleId", moduleId);
  form.append("description", description);
  form.append("order", order);
  form.append("quiz", JSON.stringify(quiz));
  if (active) form.append("active", active.toString());

  const response = await axios.post(`${API_URL}/admin/update-video`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getAdminVideo(id: string) {
  const response = await axios.get(`${API_URL}/admin/get-video/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
