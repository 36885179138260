import { API_URL } from "../constants/environment";
import { axios } from "./axios";
import { useAuth } from "../store/auth.store";
import { ROUTES } from "../constants/routes";

axios.defaults.withCredentials = true;

export async function register({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const response = await axios.post(`${API_URL}/auth/register`, {
    email,
    password,
  });

  if (response.status == 201) {
    useAuth.getState().setUser(response.data);
    return response.data;
  }

  if (response.status == 400) {
    throw response.data;
  }

  throw response.data;
}

export async function login({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const response = await axios.post(`${API_URL}/auth/login`, {
    email,
    password,
  });

  if (response?.status == 200) {
    useAuth.getState().setUser(response?.data);
    window.location.href = ROUTES.appRoot;
  }

  if (
    response?.status == 401 ||
    response?.status == 403 ||
    response?.status == 404
  ) {
    throw response?.data;
  }

  return response?.data;
}

export async function forgotPassword({ email }: { email: string }) {
  const response = await axios.post(`${API_URL}/auth/forgot-password`, {
    email,
  });

  if (response.status == 200) {
    return response.data;
  }

  if (response.status == 404) {
    throw response.data;
  }

  throw response.data;
}

export async function resetPassword({
  token,
  password,
}: {
  token: string;
  password: string;
}) {
  const response = await axios.post(`${API_URL}/auth/reset-password`, {
    token,
    password,
  });

  if (response.status == 404) {
    throw response.data;
  }

  useAuth.getState().setUser(response.data);
  return response.data;
}

export async function logout() {
  await axios.post(`${API_URL}/auth/logout`);
  useAuth.getState().logout();
  window.location.href = ROUTES.login;
}

export async function confirmEmail({ token }: { token: string }) {
  const response = await axios.post(`${API_URL}/auth/confirm-email`, {
    token,
  });

  if (response.status == 200) {
    useAuth.getState().setUser(response.data);
    return response.data;
  }

  if (response.status == 404) {
    throw response.data;
  }

  throw response.data;
}

export async function changePassword({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) {
  const response = await axios.post(`${API_URL}/auth/change-password`, {
    oldPassword,
    newPassword,
  });

  if (response.status == 200) {
    return response.data;
  }

  if (response.status == 400) {
    throw response.data;
  }

  throw response.data;
}

export async function fetchUser() {
  const response = await axios.get(`${API_URL}/auth/me`);

  if (response.status == 200) {
    useAuth.getState().setUser(response.data);
    return response.data;
  }

  if (response.status == 404) {
    throw response.data;
  }

  throw response.data;
}
