import Layout from "../../../components/Layout";
import {
  Card,
  CardBody,
  CardFooter,
  Chip,
  Image,
  Progress,
  Spacer,
  Spinner,
} from "@nextui-org/react";
import {
  IconArrowLeft,
  IconChevronRight,
  IconDiscountCheckFilled,
  IconMoodSad,
  IconPencil,
  IconPencilPlus,
  IconPlayerPlayFilled,
  IconVideo,
} from "@tabler/icons-react";
import { ROUTES } from "../../../constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Button } from "../../../components/Button";
import { useAuth } from "../../../store/auth.store";
import { ModuleType, getCourse } from "../../../api/app/kBase/courses.api";
import { getImageUrl } from "../../../utils/images.utils";
import { useTranslation } from "react-i18next";

const AddAndModifyButtons = ({ courseId }: { courseId: string }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation("modules");

  if (auth.user!.role === "ADMIN" || auth.user!.role === "OWNER") {
    return (
      <div className="flex justify-center items-center pt-3 cursor-pointer opacity-70">
        <Button
          startContent={<IconPencil />}
          variant="light"
          color="default"
          onClick={() =>
            navigate(ROUTES.admin.kBase.getCourse({ id: courseId }))
          }
        >
          {t("modify")}
        </Button>
        <Spacer x={2} />
        <Button
          startContent={<IconPencilPlus />}
          variant="light"
          color="default"
          onClick={() =>
            navigate(ROUTES.admin.kBase.newModule, { state: { courseId } })
          }
        >
          {t("addModule")}
        </Button>
      </div>
    );
  }

  return null;
};

export default function Course() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("modules");
  const auth = useAuth();

  const { isLoading, data: course } = useQuery(
    ["course", id],
    () => getCourse(id!),
    {
      refetchOnWindowFocus: false,
    },
  );

  if (isLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  if (!course)
    return (
      <Layout showFooter>
        <AddAndModifyButtons courseId={id!} />
        <div
          className="flex items-center gap-2 pb-4 pt-6 cursor-pointer opacity-70 w-min"
          onClick={() => navigate(ROUTES.kBase.root)}
        >
          <IconArrowLeft size={24} />
          <span className="whitespace-nowrap">{t("back")}</span>
        </div>
        <Spacer y={40} />
        <div className="flex justify-center items-center flex-col">
          <IconMoodSad size={100} className="text-center text-secondary-200" />
          <Spacer y={2} />
          <h2 className="text-center text-lg opacity-85">
            {t("notAvailable")}
          </h2>
          <h3 className="text-center text-sm opacity-70">{t("retryLater")}</h3>
        </div>
      </Layout>
    );

  return (
    <Layout showFooter>
      <AddAndModifyButtons courseId={id!} />
      <div
        className="flex items-center gap-2 pb-4 pt-6 cursor-pointer opacity-70 w-min"
        onClick={() => navigate(ROUTES.kBase.root)}
      >
        <IconArrowLeft size={24} />
        <span className="whitespace-nowrap">{t("back")}</span>
      </div>
      <Spacer y={2} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Card
          isFooterBlurred
          classNames={{
            base: `overflow-hidden text-white ${course.lecturer ? "md:col-span-2" : "md:col-span-3"}`,
          }}
        >
          <CardBody className="overflow-visible p-0 relative">
            {course.status === "INACTIVE" && (
              <Chip color="default" className="absolute top-2 left-2 z-20">
                {t("notPublic")}
              </Chip>
            )}
            <Image
              src={getImageUrl(course.image)}
              alt={course.title}
              radius="none"
              classNames={{
                img: "w-full",
                wrapper: "w-full !max-w-full h-40 flex items-center",
              }}
            />
          </CardBody>
          <CardFooter className="flex flex-row justify-between items-center w-full z-10">
            <div className="flex flex-col items-start">
              <h2 className="text-xl font-medium">{course.title}</h2>
              <Spacer y={1} />
              <p className="text-sm opacity-80 text-left">
                {course.description}
              </p>
            </div>
          </CardFooter>
        </Card>
        {course.lecturer && (
          <Card>
            <CardBody className="flex flex-row bg-primary-100 pb-0">
              <Image
                src={getImageUrl(course.lecturer?.image) || ""}
                alt={course.lecturer?.name}
                radius="none"
                classNames={{
                  img: "h-full object-cover w-full",
                  wrapper: "w-full",
                }}
              />
              <div className="w-full ml-8 pb-3">
                <h1 className="text-xl font-bold">{course.lecturer?.name}</h1>
                <Spacer y={1} />
                <p className="text-ellipsis line-clamp-4">
                  {course.lecturer?.description}
                </p>
                <Spacer y={3} />
                <Button
                  variant="solid"
                  color="primary"
                  endContent={<IconChevronRight />}
                  onClick={() =>
                    navigate(
                      ROUTES.kBase.getLecturer({ id: course.lecturer?.id }),
                    )
                  }
                >
                  {t("viewLecturer")}
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
      <h1 className="mt-8 text-2xl font-semibold">{t("modules")}</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 mt-6 gap-6">
        {course.modules.map((module: ModuleType) => {
          const activeVideos = module.videos.filter(
            (video) => video.status === "ACTIVE",
          );
          const numberOfVideos = activeVideos.length;
          const completedVideos = activeVideos.filter(
            (video) => video.completed,
          ).length;

          if (
            module.status === "INACTIVE" &&
            !(auth.user!.role === "ADMIN" || auth.user!.role === "OWNER")
          )
            return null;

          const sortedVideos = activeVideos.sort((a, b) => {
            return a.order - b.order;
          });

          const videoToPlay =
            sortedVideos.find((video) => !video.completed) || sortedVideos[0];

          return (
            <Card
              key={module.id}
              isPressable
              isHoverable
              isFooterBlurred
              disableRipple
              classNames={{
                base: "overflow-hidden w-full flex-1",
              }}
              onPress={() =>
                navigate(
                  ROUTES.kBase.getModule({
                    id: module.id,
                    videoId: videoToPlay?.id,
                  }),
                  { state: { courseId: id } },
                )
              }
            >
              <CardBody className="overflow-visible p-0 realtive">
                {module.status === "INACTIVE" && (
                  <Chip color="default" className="absolute top-2 left-2 z-20">
                    {t("notPublic")}
                  </Chip>
                )}
                {completedVideos === numberOfVideos && (
                  <IconDiscountCheckFilled
                    height="100%"
                    width="100%"
                    className="absolute top-0 bottom-0 z-20"
                    fill="var(--primary-300)"
                  />
                )}
                <Image
                  src={getImageUrl(module.image)}
                  alt={module.title}
                  radius="none"
                  classNames={{
                    img: "w-full",
                    wrapper:
                      "w-full !max-w-full h-40 md:h-40 flex items-center " +
                      (completedVideos === numberOfVideos
                        ? "filter grayscale"
                        : ""),
                  }}
                />
              </CardBody>
              <CardFooter className="p-0 flex flex-col z-10">
                <div className="flex flex-row items-center justify-between w-full">
                  <h2 className="p-3 text-xl font-medium">{module.title}</h2>
                  <IconPlayerPlayFilled size={24} className="mr-3" />
                </div>
                <div className="flex items-center w-full px-3 pb-3">
                  <IconVideo size={20} className="opacity-65 w-10" />
                  <Spacer x={2} />
                  <Progress
                    size="sm"
                    value={completedVideos}
                    maxValue={numberOfVideos}
                    color="secondary"
                  />
                  <Spacer x={4} />
                  <h3 className="text-sm opacity-65 whitespace-nowrap">
                    {completedVideos} / {numberOfVideos} {t("video")}
                  </h3>
                </div>
              </CardFooter>
            </Card>
          );
        })}
      </div>
      <Spacer y={4} />
    </Layout>
  );
}
