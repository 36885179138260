import Layout from "../../../../components/Layout";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import Editor from "../../../../components/admin/Editor";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import {
  Autocomplete,
  AutocompleteItem,
  Chip,
  Divider,
  Image,
  Input,
  Select,
  SelectItem,
  Spacer,
  Spinner,
  Switch,
} from "@nextui-org/react";
import { Button } from "../../../../components/Button";
import {
  IconDragDrop,
  IconDragDrop2,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import { UNITS } from "../../../../constants/recipes.constants";
import { getImageUrl } from "../../../../utils/images.utils";
import { useMutation, useQuery } from "react-query";
import { ROUTES } from "../../../../constants/routes";
import {
  createRecipe,
  updateRecipe,
} from "../../../../api/admin/app/recipes/recipes.api";
import {
  getRecipeById,
  getTags,
} from "../../../../api/app/recipes/recipes.api";

const schema = yup
  .object()
  .shape({
    title: yup.string().required(),
    description: yup.string().required(),
    active: yup.boolean().default(false),
    time: yup.string().required(),
    servings: yup.string().required(),
    ingredients: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(),
          quantity: yup.string().required(),
          unit: yup.string().required(),
        }),
      )
      .required(),
    instructions: yup.string().required(),
    tags: yup.array().of(yup.string()).required(),
    categories: yup.array().of(yup.string()).required(),
    nutritionalFacts: yup.object().shape({
      calories: yup.string().required(),
      protein: yup.string().required(),
      carbs: yup.string().required(),
      fat: yup.string().required(),
    }),
  })
  .required();

export default function AdminRecipe({ isNew }: { isNew?: boolean }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation(["admin", "recipeunits"]);
  const [isLoading, setIsLoading] = useState(false);
  const [tagInput, setTagInput] = useState("");
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      active: false,
      time: "",
      servings: "",
      ingredients: [
        {
          name: "",
          quantity: "",
          unit: "",
        },
      ],
      instructions: "",
      tags: [],
      categories: [],
      nutritionalFacts: {
        calories: "",
        protein: "",
        carbs: "",
        fat: "",
      },
    },
  });

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      multiple: false,
      accept: { "image/*": [] },
    });

  const { isLoading: isRecipeLoading, data: recipe } = useQuery(
    ["recipe", id],
    () => getRecipeById(id!),
    {
      enabled: !isNew,
    },
  );

  const { isLoading: isTagsLoading, data: tags } = useQuery("tags", () =>
    getTags(),
  );

  useEffect(() => {
    if (!isNew && recipe) {
      form.setValue("title", recipe.title);
      form.setValue("description", recipe.description);
      form.setValue("active", recipe.status === "ACTIVE" ? true : false);
      form.setValue("time", recipe.time);
      form.setValue("servings", recipe.servings);
      form.setValue(
        "ingredients",
        recipe.RecipeToIngredient.map(
          (ingredient: {
            ingredient: {
              id: string;
              ingredient: string;
              unit: string;
              quantity: string;
            };
          }) => ({
            id: ingredient.ingredient.id,
            quantity: ingredient.ingredient.quantity,
            unit: ingredient.ingredient.unit,
            name: ingredient.ingredient.ingredient,
          }),
        ),
      );
      form.setValue("instructions", recipe.instructions);
      form.setValue(
        "tags",
        recipe.tags?.map((tag: { tag: { name: string } }) => tag.tag.name),
      );
      form.setValue("categories", recipe.categories);
      form.setValue("nutritionalFacts", recipe.nutritionFacts);
      if (acceptedFiles.length === 0) {
        acceptedFiles.push(new File([], recipe.image));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipe]);

  useEffect(() => {
    return () => {
      acceptedFiles.pop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading: updateIsLoading, mutate: saveUpdatedRecipe } = useMutation(
    updateRecipe,
    {
      onError: (error: { message: string }) => {
        form.setError("root", { message: error.message });
      },
      onSuccess: () => {
        acceptedFiles.pop();
        navigate(ROUTES.recipes.root);
      },
    },
  );

  const { isLoading: createIsLoading, mutate: saveRecipe } = useMutation(
    createRecipe,
    {
      onError: (error: { message: string }) => {
        form.setError("root", { message: error.message });
      },
      onSuccess: () => {
        acceptedFiles.pop();
        navigate(ROUTES.recipes.root);
      },
    },
  );

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit((data) => {
      if (isNew) {
        saveRecipe({ ...data, images: acceptedFiles, tags: data.tags || [] });
      } else {
        saveUpdatedRecipe({
          id: id!,
          ...data,
          images: acceptedFiles,
          tags: data.tags || [],
        });
      }
    })();
  };

  useEffect(() => {
    setIsLoading(
      createIsLoading || updateIsLoading || isRecipeLoading || isTagsLoading,
    );
  }, [createIsLoading, updateIsLoading, isRecipeLoading, isTagsLoading]);

  if (!isNew && isRecipeLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter>
      <form onSubmit={onSubmit}>
        <h1 className="text-xl mt-4 font-semibold">
          {isNew ? t("recipes.new") : t("recipes.edit")}
        </h1>

        <Controller
          name="active"
          control={control}
          render={({ field }) => (
            <Switch
              onValueChange={(isSelected) => field.onChange(isSelected)}
              isSelected={field.value}
              className="mt-4"
            >
              <span className="opacity-70 max-w">{t("recipes.active")}</span>
            </Switch>
          )}
        />

        <Spacer y={4} />

        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("recipes.name")}
              variant="bordered"
              color="primary"
              isInvalid={!!form.formState.errors.title?.message}
              errorMessage={form.formState.errors.title?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("recipes.description")}
              variant="bordered"
              color="primary"
              isInvalid={!!form.formState.errors.description?.message}
              errorMessage={form.formState.errors.description?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="time"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("recipes.time")}
              variant="bordered"
              color="primary"
              isInvalid={!!form.formState.errors.time?.message}
              errorMessage={form.formState.errors.time?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="servings"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("recipes.servings")}
              variant="bordered"
              color="primary"
              isInvalid={!!form.formState.errors.servings?.message}
              errorMessage={form.formState.errors.servings?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          control={control}
          name="ingredients"
          render={({ field }) => (
            <div className="w-full border-2 border-solid border-default-200 px-3 py-2 shadow-sm rounded-medium flex flex-col">
              <label className="text-primary-300 text-small">
                {t("recipes.ingredients")}
              </label>
              {form.formState.errors.ingredients && (
                <span className="text-danger-500 text-small">
                  {form.formState.errors.ingredients.message}
                </span>
              )}
              <Spacer y={2} />
              <div>
                {field.value?.map((ingredient, index) => {
                  const isLast = index === (field.value?.length ?? 0) - 1;

                  return (
                    <>
                      <div
                        key={index}
                        className="flex flex-col md:flex-row items-center gap-2"
                      >
                        <div className="flex flex-row w-full gap-2">
                          <Input
                            value={ingredient.quantity}
                            onChange={(e) => {
                              const ingredients = field.value ?? [];
                              ingredients[index].quantity = e.target.value;
                              form.setValue("ingredients", ingredients);
                            }}
                            placeholder={t("recipes.quantity")}
                            variant="bordered"
                            color="primary"
                          />
                          <Select
                            value={ingredient.unit}
                            onChange={(e) => {
                              const ingredients = field.value ?? [];
                              ingredients[index].unit = e.target.value;
                              form.setValue("ingredients", ingredients);
                            }}
                            defaultSelectedKeys={[ingredient.unit]}
                            placeholder={t("recipes.unit")}
                            variant="bordered"
                            color="primary"
                          >
                            {UNITS.map((unit) => (
                              <SelectItem key={unit.value} value={unit.value}>
                                {t(unit.labelShort, { ns: "recipeunits" })}
                              </SelectItem>
                            ))}
                          </Select>
                        </div>
                        <div className="flex flex-row gap-2 w-full">
                          <Input
                            value={ingredient.name}
                            onChange={(e) => {
                              const ingredients = field.value ?? [];
                              ingredients[index].name = e.target.value;
                              form.setValue("ingredients", ingredients);
                            }}
                            placeholder={t("recipes.ingredient")}
                            variant="bordered"
                            color="primary"
                          />
                          <Button
                            variant="solid"
                            color="danger"
                            className="w-10 min-w-10 px-0"
                            onClick={() => {
                              const ingredients = field.value ?? [];
                              ingredients.splice(index, 1);
                              form.setValue("ingredients", ingredients);
                            }}
                          >
                            <IconTrash />
                          </Button>
                        </div>
                      </div>
                      <Spacer y={2} />
                      {!isLast && <Divider />}
                      <Spacer y={2} />
                    </>
                  );
                })}
              </div>
              <Spacer y={2} />
              <Button
                variant="solid"
                color="primary"
                onClick={() =>
                  form.setValue("ingredients", [
                    ...(field.value ?? []),
                    { name: "", quantity: "", unit: "" },
                  ])
                }
              >
                {t("recipes.newIngredient")}
              </Button>
            </div>
          )}
        />

        <Spacer y={4} />

        <Controller
          control={control}
          name="instructions"
          render={({ field }) => (
            <div className="w-full border-2 border-solid border-default-200 px-3 py-2 shadow-sm rounded-medium flex flex-col">
              <label className="text-primary-300 text-small">
                {t("recipes.instructions")}
              </label>
              <Spacer y={2} />
              <Editor value={field.value} onChange={field.onChange} />
            </div>
          )}
        />

        <div
          {...getRootProps()}
          className="mt-4 border-2 border-solid border-default-200 rounded-xl p-3 flex justify-center items-center flex-col shadow-sm "
        >
          <h1 className="text-primary-300 text-sm self-start pb-1">
            {t("recipes.thumbnail")}
          </h1>
          <input {...getInputProps()} />
          {isDragActive && (
            <div
              className={
                (acceptedFiles.length ? "h-80" : "") +
                " flex flex-col justify-center items-center"
              }
            >
              <IconDragDrop2 size={48} className="text-primary-500" />
              <p className="text-primary-300 text-center text-sm">
                {t("recipes.thumbnailDrop")}
              </p>
            </div>
          )}
          {isDragActive ||
            (!acceptedFiles.length && (
              <>
                <IconDragDrop size={48} className="text-primary-300" />
                <p className="text-primary-300 text-sm">
                  {t("recipes.thumbnailDrag")}
                </p>
              </>
            ))}
          {!isDragActive && acceptedFiles.length > 0 && (
            <div>
              {acceptedFiles.map((file) => (
                <Image
                  key={file.name}
                  src={
                    file.name.includes("uploads")
                      ? getImageUrl(file.name)
                      : URL.createObjectURL(file)
                  }
                  alt={file.name}
                  className="rounded-lg max-h-80"
                />
              ))}
            </div>
          )}
        </div>

        <Spacer y={4} />

        <Controller
          control={control}
          name="tags"
          render={({ field }) => (
            <div className="w-full border-2 border-solid border-default-200 px-3 py-2 shadow-sm rounded-medium flex flex-col">
              <label className="text-primary-300 text-small">
                {t("recipes.tags")}
              </label>
              {form.formState.errors.tags && (
                <span className="text-danger-500 text-small">
                  {form.formState.errors.tags.message}
                </span>
              )}
              <Spacer y={2} />
              <div className="flex flex-row gap-2 items-center">
                <Autocomplete
                  allowsCustomValue
                  label={t("recipes.tags")}
                  variant="bordered"
                  color="primary"
                  size="sm"
                  items={
                    tags
                      ?.filter(
                        (tag: { name: string }) =>
                          !field.value?.includes(tag.name),
                      )
                      .map((tag: { name: string }) => ({
                        value: tag.name,
                        label: tag.name,
                      })) || []
                  }
                  inputValue={tagInput}
                  onInputChange={(value) => {
                    setTagInput(value || "");
                  }}
                  onSelectionChange={(item) => {
                    if (item === tagInput) return;
                    if (!item) return;
                    field.onChange([...field.value, item]);
                    setTagInput("");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      field.onChange([...field.value, tagInput]);
                      setTagInput("");
                    }
                  }}
                >
                  {(item: { value: string; label: string }) => {
                    return (
                      <AutocompleteItem key={item.value}>
                        {item.label}
                      </AutocompleteItem>
                    );
                  }}
                </Autocomplete>
                <Button
                  variant="solid"
                  color="primary"
                  className="w-10 min-w-10 px-0"
                  onClick={() => {
                    field.onChange([...field.value, tagInput]);
                    setTagInput("");
                  }}
                >
                  <IconPlus />
                </Button>
              </div>
              <Spacer y={2} />
              <div className="flex flex-row gap-2 flex-wrap">
                {field.value?.map((tag, index) => (
                  <Chip
                    key={tag}
                    isCloseable
                    onClose={() => {
                      field.onChange(field.value.filter((_, i) => i !== index));
                    }}
                  >
                    {tag}
                  </Chip>
                ))}
              </div>
            </div>
          )}
        />

        <Spacer y={4} />

        <Controller
          control={control}
          name="nutritionalFacts"
          render={({ field }) => (
            <div className="w-full border-2 border-solid border-default-200 px-3 py-2 shadow-sm rounded-medium flex flex-col">
              <label className="text-primary-300 text-small">
                {t("recipes.nutritionalFacts")}{" "}
                <span className="text-foreground opacity-70">/100g</span>
              </label>
              {form.formState.errors.nutritionalFacts && (
                <span className="text-danger-500 text-small">
                  {form.formState.errors.nutritionalFacts.message}
                </span>
              )}
              <Spacer y={2} />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <Input
                  label={t("recipes.calories")}
                  variant="bordered"
                  color="primary"
                  value={field.value?.calories}
                  onChange={(e) => {
                    field.onChange({
                      ...field.value,
                      calories: e.target.value,
                    });
                  }}
                />
                <Input
                  label={t("recipes.protein")}
                  variant="bordered"
                  color="primary"
                  value={field.value?.protein}
                  onChange={(e) => {
                    field.onChange({ ...field.value, protein: e.target.value });
                  }}
                />
                <Input
                  label={t("recipes.carbs")}
                  variant="bordered"
                  color="primary"
                  value={field.value?.carbs}
                  onChange={(e) => {
                    field.onChange({ ...field.value, carbs: e.target.value });
                  }}
                />
                <Input
                  label={t("recipes.fat")}
                  variant="bordered"
                  color="primary"
                  value={field.value?.fat}
                  onChange={(e) => {
                    field.onChange({ ...field.value, fat: e.target.value });
                  }}
                />
              </div>
            </div>
          )}
        />

        {form.formState.errors.root && (
          <>
            <Spacer y={3} />
            <p className="text-red-500 text-sm text-center">
              {form.formState.errors.root.message}
            </p>
          </>
        )}

        <Button
          variant="solid"
          color="primary"
          className="mt-8 w-full"
          type="submit"
          isLoading={isLoading}
          isDisabled={
            isLoading || !form.formState.isValid || acceptedFiles.length === 0
          }
        >
          {t("recipes.save")}
        </Button>

        <Spacer y={4} />
      </form>
    </Layout>
  );
}
