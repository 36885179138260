import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useAuth } from "../../store/auth.store";
import { Avatar } from "../../components/Avatar";
import { Divider, Spacer } from "@nextui-org/react";
import {
  IconBookmark,
  IconFileStack,
  IconLogout,
  IconPassword,
  // IconPencil,
} from "@tabler/icons-react";
import { Button } from "../../components/Button";
import { logout } from "../../api/auth.api";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function ProfileHome() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation("profile");
  const [successMessage, setSuccessMessage] = useState("");

  let successTimer: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (state?.emailSent) {
      setSuccessMessage(t("changePassword.emailSent"));
    }

    if (state?.passwordReset) {
      setSuccessMessage(t("changePassword.passwordUpdated"));
    }

    if (state?.subscriptionCanceled) {
      setSuccessMessage(t("manageSubscription.subscriptionCanceled"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    successTimer = setTimeout(() => {
      setSuccessMessage("");
      navigate(ROUTES.profile.root, { replace: true });
    }, 3000);

    return () => {
      clearTimeout(successTimer);
    };
  }, [state, navigate, setSuccessMessage]);

  return (
    <Layout showFooter>
      <div className="max-w-screen-sm mx-auto pt-4 flex flex-col items-center">
        <h1 className="text-center font-bold text-2xl">
          {auth.user?.lastName} {auth.user?.firstName}
        </h1>
        {auth.user?.confirmToken && (
          <>
            <Spacer y={4} />
            <div
              className={`bg-warning-200 py-3 rounded-xl w-full mx-auto mt-4 border-2 border-solid border-warning-700`}
            >
              <h1 className="text-center font-medium opacity-70 text-md">
                {t("confirm-email")}
              </h1>
            </div>
          </>
        )}
        <Spacer y={8} />
        <div className="relative">
          {/* <Avatar
            icon={<IconPencil />}
            size="md"
            isBordered
            classNames={{
              base: 'absolute z-10 bottom-2 right-2 bg-primary ring-primary',
            }}
          /> */}
          <Avatar
            size="xl"
            isBordered
            classNames={{
              base: "bg-default ring-primary",
            }}
            name={
              auth.user?.firstName && auth.user?.lastName
                ? `${auth.user?.firstName + auth.user?.lastName}`
                : ""
            }
          />
        </div>
        <Spacer y={8} />
        <Divider />
        {/* <Button
          variant='flat'
          color="defaultPrimary"
          size='lg'
          isDisabled
          startContent={<IconPencil className='md:hidden'/>}
          endContent={<IconPencil className='hidden md:block'/>}
          className='mt-4 w-full justify-start md:justify-between'
        >
          Profil szerkesztése
        </Button> */}
        <Button
          variant="flat"
          color="defaultPrimary"
          size="lg"
          startContent={<IconBookmark className="md:hidden" />}
          endContent={<IconBookmark className="hidden md:block" />}
          className="mt-4 w-full justify-start md:justify-between"
          onClick={() => navigate(ROUTES.recipes.saved)}
        >
          {t("saved-recipes")}
        </Button>
        <Button
          variant="flat"
          color="defaultPrimary"
          size="lg"
          startContent={<IconPassword className="md:hidden" />}
          endContent={<IconPassword className="hidden md:block" />}
          className="mt-4 w-full justify-start md:justify-between"
          onClick={() => navigate(ROUTES.profile.changePassword)}
        >
          {t("change-password")}
        </Button>
        <Button
          variant="flat"
          color="defaultPrimary"
          size="lg"
          startContent={<IconFileStack className="md:hidden" />}
          endContent={<IconFileStack className="hidden md:block" />}
          className="mt-4 w-full justify-start md:justify-between"
          onClick={() => navigate(ROUTES.profile.manageSubscription)}
        >
          {t("manage-subscriptions")}
        </Button>
        <Button
          variant="flat"
          color="dangerPrimary"
          size="lg"
          startContent={<IconLogout className="md:hidden" />}
          endContent={<IconLogout className="hidden md:block" />}
          className="mt-4 w-full justify-start md:justify-between"
          onClick={() => logout()}
        >
          {t("logout")}
        </Button>
      </div>
      <AnimatePresence mode="sync">
        {successMessage && (
          <motion.div
            className={`fixed z-10 top-0 bg-success-200 py-3 rounded-xl w-full max-w-sm mx-auto mt-4 border-2 border-solid border-success-700`}
            whileInView={{ translateY: 70 }}
            exit={{ opacity: 0 }}
          >
            <h1 className="text-center font-medium opacity-70 text-md">
              {successMessage}
            </h1>
          </motion.div>
        )}
      </AnimatePresence>
    </Layout>
  );
}
