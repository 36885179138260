import { Accordion, AccordionItem } from "@nextui-org/react";
import { motion } from "framer-motion";

const FAQContent = [
  {
    title: "Kinek való a NutriQueens Academy?",
    content:
      "Teljesen őszintén minden nőnek, aki nem divatdiétákra alapoz, hanem változást akar az életmódjában. Szeretné megérteni és élvezni is, amit csinál és hosszútávon fenntartani.",
  },
  {
    title: "Meddig érem el a tartalmakat?",
    content:
      "Az összes tudásanyag örökké elérhető marad számodra, amíg aktív az előfizetésed.",
  },
  {
    title: "Heti hány órát kell vele foglalkoznom?",
    content:
      "Ez teljesen rajtad múlik! Saját tempódban haladsz, a saját időbeosztásod szerint. Heti 1-4 órát vesz igénybe átlagosan, de ez attól is függ mennyi edzést csinálsz meg egy héten, mennyi tananyagot dolgozol fel egyszerre.",
  },
  {
    title: "Fog ez nekem működni?",
    content:
      "Ha beleteszed a munkát igen. Nem könnyű út, de megéri. Az életmódváltásra rá kell szánnod az időt és az energiát, cserébe eléred a kitűzött céljaidat.",
  },
  {
    title: "Kell valamilyen előzetes tudás?",
    content:
      "Nem. A te részedről kettő dologra lesz szükséged: nyitottság és változtatás. A többiről pedig mi gondoskodunk.",
  },
  {
    title: "Ha elakadok van lehetőségem kérdezni?",
    content:
      "A tudástár felület chat felületén folyamatosan kérdezhetsz. Ha technikai elakadásod van, írj emailt és ránézünk, mi lehet a baj.",
  },
  {
    title: "Hogyan működik a fizetés?",
    content:
      "A NutriQueens Academy  előfizetéses rendszerként működik, így az első fizetésed után minden hónapfordulódkor automatikusan levonódik a tagsági díj a Stripe rendszerén keresztül. Ugyanúgy, mint pl. egy Netflix előfizetésnél is, az első alkalom után már nem kell bajlódnod vele!",
  },
  {
    title: "Mit tegyek, ha nem sikerül a bankkártyás fizetés?",
    content: (
      <>
        <p>
          Fontos, hogy 2021.01.01-től az összes bankkártyás fizetés folyamata
          módosult, az internetes bankkártyás vásárlások kétlépcsős azonosítást
          követően mennek végbe. Ettől az időponttól az online fizetésre adott
          megbízások már csak abban az esetben kerülnek végrehajtásra,
          amennyiben a kártyabirtokos a bankkártya adatok megadását követően
          egyedi azonosítással is megerősíti fizetési szándékát.
        </p>
        <p className="mt-4">
          Ez azt jelenti, hogy az SMS-ben kapott 6 számjegyű kód mellé egy
          általad korábban rögzített internetes vásárlási jelszót is be kell
          írnod. Ha van internetbankos mobil alkalmazás, akkor oda érkezik a
          push értesítés (az SMS helyett), amivel jóváhagyod a vásárlást.
        </p>
        <p className="mt-4">
          Amennyiben ennek ellenére is problémába ütközöl, keres fel a bankodat,
          aki segít a probléma elhárításában
        </p>
      </>
    ),
  },
  {
    title: "Hogyan tudom lemondani az előfizetésem?",
    content: (
      <>
        <p>
          Amennyiben szeretnéd végleg lemondani a tagságod és ezzel elveszíteni
          a hozzáférési lehetőséget az összes tartalomhoz, akkor a Profil
          menüpontra kell kattintanod, ott megtalálod a feliratkozás kezelése
          oldalt. Itt találsz egy Lemondás gombot, ha azt választod
          megszüntetheted a tagságod és újabb levonás nem fog történni a
          számládról.
        </p>
        <p className="mt-4">
          Vagy ezen a linken megtalálod:{" "}
          <a
            className="text-primary-500"
            href="https://nutriqueens.hu/app/profile/manage-subscription"
          >
            https://nutriqueens.hu/app/profile/manage-subscription
          </a>
        </p>
      </>
    ),
  },
];

export default function FAQ() {
  return (
    <motion.section
      className=" px-4 py-8"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
      viewport={{ once: true, amount: 0.3 }}
    >
      <div className="container mx-auto flex flex-wrap pb-12 pt-4">
        <h2 className="my-2 w-full text-center text-5xl font-bold leading-tight">
          GYIK
        </h2>
        <Accordion
          itemClasses={{
            heading: "text-2xl font-semibold",
            content: "opacity-60",
          }}
        >
          {FAQContent.map((item, index) => (
            <AccordionItem key={index} title={item.title}>
              {item.content}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </motion.section>
  );
}
