import { ROUTES } from "../constants/routes";
import { useAuth } from "../store/auth.store";

export const RequireSubscription = ({
  children,
  reverse = false,
  isRetry = false,
}: {
  children: React.ReactNode;
  reverse?: boolean;
  isRetry?: boolean;
}) => {
  const user = useAuth.getState().user;
  const isSubscribed =
    user?.status === "ACTIVE" || user?.status === "CANCELLED";

  if (isSubscribed && reverse) {
    window.location.replace(ROUTES.appRoot);
    return null;
  }

  if (user?.status === "OVERDUE" && !isRetry) {
    window.location.replace(ROUTES.retryPayment);
    return null;
  }

  if (!isSubscribed && !reverse) {
    window.location.replace(ROUTES.subsciptions);
    return null;
  }

  return children;
};
