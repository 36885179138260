import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Progress } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useStatsigClient } from "@statsig/react-bindings";
import { ROUTES } from "../../constants/routes";
import Header from "../../components/Header";
import ReactPixel from "react-facebook-pixel";
import { useMutation } from "react-query";
import Welcome from "./welcome";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import StepFour from "./stepFour";
import StepFive from "./stepFive";
import StepSix from "./stepSix";
import StepSeven from "./stepSeven";
import StepEight from "./stepEight";
import { completeQuiz } from "../../api/quiz";
import { IconChevronLeft } from "@tabler/icons-react";
import { motion } from "framer-motion";

export default function Quiz() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const { logEvent } = useStatsigClient();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    age: "",
    weight: "",
    height: "",
    diagnostic: "",
    pre_menstruation: "false",
    pain: "false",
    activity: "",
    eating: {
      breakfast: "",
      lunch: "",
      dinner: "",
      snacks: "",
    },
    goal: "",
    success: "",
  });

  const { mutate } = useMutation(completeQuiz, {
    onSuccess: () => {
      navigate(ROUTES.quizThanks);
      ReactPixel.track("Lead");
      logEvent("free_analysis_completed", "true");
    },
    onError: (error: { message: string }) => {
      console.error(error.message);
    },
  });

  useEffect(() => {
    logEvent("free_analysis_loaded", "true");
  }, [logEvent]);

  return (
    <>
      <Header />
      <section className="flex min-h-[calc(100vh-4rem)] justify-center">
        <div className="mx-auto mt-4 flex w-full max-w-screen-md flex-col px-4">
          {step > 0 && <Progress value={(step / 8) * 100} />}
          {step > 1 && (
            <>
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0 }}
                exit={{ opacity: 0 }}
                onClick={() => setStep(step - 1)}
                className="mt-4 flex flex-row text-primary-500 hover:text-primary-700"
              >
                <IconChevronLeft size={24} />
                Vissza
              </motion.button>
            </>
          )}

          <AnimatePresence mode="wait">
            {step === 0 && <Welcome onContinue={() => setStep(1)} />}
            {step === 1 && (
              <StepOne
                onContinue={(data: object) => {
                  setFormData((prev) => ({ ...prev, ...data }));
                  setStep(2);
                }}
                data={formData}
              />
            )}
            {step === 2 && (
              <StepTwo
                onContinue={(data: object) => {
                  setFormData((prev) => ({ ...prev, ...data }));
                  setStep(3);
                }}
                data={formData}
              />
            )}
            {step === 3 && (
              <StepThree
                onContinue={(data: object) => {
                  setFormData((prev) => ({ ...prev, ...data }));
                  setStep(4);
                }}
                data={formData}
              />
            )}
            {step === 4 && (
              <StepFour
                onContinue={(data: object) => {
                  setFormData((prev) => ({ ...prev, ...data }));
                  setStep(5);
                }}
                data={formData}
              />
            )}
            {step === 5 && (
              <StepFive
                onContinue={(data: object) => {
                  setFormData((prev) => ({ ...prev, ...data }));
                  setStep(6);
                }}
                data={formData}
              />
            )}
            {step === 6 && (
              <StepSix
                onContinue={(data: {
                  breakfast: string;
                  lunch: string;
                  dinner: string;
                  snacks: string;
                }) => {
                  setFormData((prev) => ({ ...prev, eating: { ...data } }));
                  setStep(7);
                }}
                data={formData}
              />
            )}
            {step === 7 && (
              <StepSeven
                onContinue={(data: object) => {
                  setFormData((prev) => ({ ...prev, ...data }));
                  setStep(8);
                }}
                data={formData}
              />
            )}
            {step === 8 && (
              <StepEight
                onContinue={(data: object) => {
                  setFormData((prev) => ({ ...prev, ...data }));
                  mutate({ ...formData, ...data });
                }}
              />
            )}
          </AnimatePresence>
        </div>
      </section>
    </>
  );
}
