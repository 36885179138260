import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Spacer,
} from "@nextui-org/react";
import { Button } from "../components/Button";
import {
  IconLock,
  IconLogout,
  IconMenu2,
  IconMoon,
  IconSun,
  IconUser,
  IconX,
} from "@tabler/icons-react";
import { useApp } from "../store/app.store";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { useAuth } from "../store/auth.store";
import { logout } from "../api/auth.api";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  variant?: "simple" | "full";
}

export default function Header({ variant = "full" }: HeaderProps) {
  const app = useApp();
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation("header");
  const [isOpen, setIsOpen] = useState(false);

  const HEADER_BUTTONS = [
    {
      label: t("kBase"),
      href: ROUTES.kBase.root,
    },
    {
      label: t("recipes"),
      href: ROUTES.recipes.root,
    },
    // {
    //   label: t("chat"),
    //   href: ROUTES.chat.root,
    // },
    {
      label: t("admin"),
      href: ROUTES.admin.root,
      admin: true,
    },
  ];

  return (
    <Navbar
      classNames={{ base: "bg-primary-200 bg-opacity-30" }}
      isBlurred
      onMenuOpenChange={setIsOpen}
    >
      {variant === "full" ? (
        <>
          <NavbarMenuToggle
            aria-label={isOpen ? "Close menu" : "Open menu"}
            className="md:hidden"
            icon={!isOpen ? <IconMenu2 /> : <IconX />}
          />
          <NavbarMenu>
            {auth.user &&
              (auth.user.status === "ACTIVE" ||
                auth.user.status === "CANCELLED") &&
              HEADER_BUTTONS.map((button, index) => {
                if (button.admin && auth.user?.role !== "ADMIN") return null;

                return (
                  <NavbarMenuItem key={index}>
                    <Link
                      color="foreground"
                      className={`w-full justify-center text-xl text-primary-500`}
                      size="lg"
                      href={button.href}
                    >
                      {button.label}
                    </Link>
                  </NavbarMenuItem>
                );
              })}
            <NavbarMenuItem className="sm:hidden">
              <Link
                color="foreground"
                className={`w-full justify-center text-xl text-primary-500`}
                size="lg"
                onClick={() => app.toggleTheme()}
              >
                {app.theme === "light" ? (
                  <>
                    <span>Sotet mod</span>
                    <Spacer x={2} />
                    <IconMoon size={20} className="text-primary-500" />
                  </>
                ) : (
                  <>
                    <span>Vilagos mod</span>
                    <Spacer x={2} />
                    <IconSun size={20} className="text-primary-500" />
                  </>
                )}
              </Link>
            </NavbarMenuItem>
          </NavbarMenu>
        </>
      ) : null}

      <NavbarBrand>
        <h1
          className="cursor-pointer text-2xl font-bold text-foreground"
          onClick={() =>
            navigate(
              auth?.user?.status === "ACTIVE" ||
                auth?.user?.status === "CANCELLED"
                ? ROUTES.appRoot
                : ROUTES.root,
            )
          }
        >
          NutriQueens
        </h1>
        <Spacer x={10} className="max-md:hidden" />
        {variant === "full" &&
          auth.user &&
          (auth.user.status === "ACTIVE" ||
            auth.user.status === "CANCELLED") && (
            <NavbarContent className="hidden md:flex">
              {HEADER_BUTTONS.map((button, index) => {
                if (button.admin && auth.user?.role !== "ADMIN") return null;

                return (
                  <Button
                    variant="light"
                    color="default"
                    key={index}
                    onClick={() => navigate(button.href)}
                    className="text-md"
                  >
                    {button.label}
                  </Button>
                );
              })}
            </NavbarContent>
          )}
      </NavbarBrand>

      <NavbarContent justify="end">
        {variant === "full" && !auth.user && (
          <Button
            variant="solid"
            color="primary"
            onClick={() => navigate(ROUTES.login)}
          >
            <IconLock size={20} />
            <span className="max-sm:hidden">{t("login")}</span>
          </Button>
        )}
        {variant === "full" && auth.user && (
          <>
            <Dropdown
              classNames={{
                base: app.theme === "light" ? "" : "dark text-foreground",
              }}
            >
              <DropdownTrigger>
                <Button
                  variant="light"
                  endContent={
                    <Avatar
                      name={
                        auth.user?.firstName && auth.user?.lastName
                          ? `${auth.user?.firstName + auth.user?.lastName}`
                          : ""
                      }
                      size="sm"
                      className="mr-2 max-md:ml-2"
                      isBordered
                    />
                  }
                  className="min-w-0 p-0 md:px-4"
                >
                  <span className="hidden md:block">{`${t("hello")}${auth.user?.firstName && `, ${auth.user?.firstName}`}`}</span>
                </Button>
              </DropdownTrigger>
              <DropdownMenu variant="light">
                <DropdownSection showDivider>
                  <DropdownItem
                    onClick={() => navigate(ROUTES.profile.root)}
                    startContent={<IconUser />}
                    color="primary"
                  >
                    {t("profile")}
                  </DropdownItem>
                </DropdownSection>
                <DropdownItem
                  color="danger"
                  startContent={<IconLogout />}
                  onClick={() => logout()}
                >
                  {t("logout")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </>
        )}
        <div
          onClick={() => app.toggleTheme()}
          className="cursor-pointer rounded-lg bg-text-100 p-2 max-sm:hidden"
        >
          {app.theme === "light" ? (
            <IconMoon size={20} className="text-foreground" />
          ) : (
            <IconSun size={20} className="text-foreground" />
          )}
        </div>
      </NavbarContent>
    </Navbar>
  );
}
