import { API_URL } from "../../constants/environment";
import { axios } from "../axios";

axios.defaults.withCredentials = true;

export async function getVideoToPlay() {
  const response = await axios.get(`${API_URL}/app/`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
