import { API_URL } from "../../constants/environment";
import { axios } from "../axios";

export async function getAdminCoupons() {
  const response = await axios.get(`${API_URL}/admin/coupons`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getCouponById(id: string) {
  const response = await axios.get(`${API_URL}/admin/coupons/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function updateCoupon({
  id,
  code,
  active,
  percentage,
}: {
  id: string;
  code: string;
  active?: boolean;
  percentage: number;
}) {
  const response = await axios.post(`${API_URL}/admin/update-coupon`, {
    id,
    status: active ? "ACTIVE" : "INACTIVE",
    code,
    percentage,
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function createCoupon({
  code,
  active,
  percentage,
}: {
  code: string;
  active?: boolean;
  percentage: number;
}) {
  const response = await axios.post(`${API_URL}/admin/create-coupon`, {
    status: active ? "ACTIVE" : "INACTIVE",
    code,
    percentage,
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
