import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { Button } from "../../components/Button";
import { Checkbox, Textarea } from "@nextui-org/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";

const schema = yup
  .object()
  .shape({
    success: yup.string().required("Kérlek add meg a sikerélményed"),
  })
  .required();

export default function StepEight({
  onContinue,
}: {
  onContinue: (data: object) => void;
}) {
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);

  const handleConsentChange = () => {
    setConsent(!consent);
    if (consentError) {
      setConsentError(false);
    }
  };

  const onSubmit = () => {
    if (!consent) {
      setConsentError(true);
      return;
    }
    handleSubmit((data) => {
      onContinue(data);
    })();
  };

  return (
    <div className="container mx-auto mt-4 max-w-screen-md px-2">
      <motion.h1
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="text-center text-2xl font-bold"
      >
        Siker
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        Hogyan éreznéd magad, ha sikerülne elérni a céljaidat az
        életmódváltással? Kérlek írd le, lehetőleg minél részletesebbet, hogy
        mik azok a sztorik, fájdalmak, esetleg félelmek, amelyek révén nem
        tudtad eddig elérni az életmódváltással kapcsolatos céljaidat!
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-2"
      >
        <Controller
          name="success"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              label="Részletek"
              size="lg"
              color="primary"
              variant="underlined"
              isInvalid={!!form.formState.errors.success?.message}
              errorMessage={form.formState.errors.success?.message}
            />
          )}
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        <Checkbox
          color="primary"
          checked={consent}
          onChange={handleConsentChange}
          isInvalid={consentError}
        >
          Megnéztem a részvételi szabályzatot és hozzájárulok adataim
          kezeléséhez
        </Checkbox>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.9, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        <Button
          onClick={onSubmit}
          className="w-full"
          variant="shadow"
          color="primary"
          isDisabled={!form.formState.isValid}
          size="lg"
        >
          Befejezés
        </Button>
      </motion.div>
    </div>
  );
}
