import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Image } from "@nextui-org/react";
import { motion } from "framer-motion";
import BAndi from "../../assets/BAndi.webp";
import SzBlanka from "../../assets/SzBlanka.webp";
import SzNiki from "../../assets/SzNiki.webp";
import SzBarbara from "../../assets/SzBarbara.webp";
import ZAndi from "../../assets/ZAndi.webp";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { getSubscriptions } from "../../api/payment.api";
import BottomCTA from "../../components/home/v7/BottomCTA";

export default function Professionals() {
  const { data: subscriptions } = useQuery(
    "subscriptions",
    () => getSubscriptions(),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="bg-primary-300">
        <h1 className="py-24 text-center text-5xl font-bold">
          Ismerd meg szakértőinket
        </h1>
      </section>
      <section className="text  bg-gradient-to-b from-primary-100 py-8">
        <div className="container mx-auto my-8 max-w-4xl px-4">
          <motion.div
            className="flex flex-row-reverse items-start justify-center gap-2 md:flex-row md:items-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="w-24 md:w-32 ">
              <Image
                src={SzBarbara}
                className="h-20 w-20 rounded-full md:h-32 md:w-32"
                alt="Szabó Barbara"
              />
            </div>
            <div className="w-5/6 max-md:pl-4 sm:w-1/2 md:p-6">
              <h3 className="text-3xl font-bold leading-none">Szabó Barbara</h3>
              <h4 className="mb-4 text-base opacity-50">
                Táplálkozási tanácsadó, élelmiszermérnök, alapító
              </h4>
              <p className=" mb-8">
                Segítek, egy hosszútávon fenntartható, egészséges táplálkozás és
                életmód kialakításában, úgy, hogy közben megérted pontosan mi
                zajlik a testedben!
              </p>
            </div>
          </motion.div>
          <motion.div
            className="flex flex-row-reverse items-start justify-center gap-2 md:flex-row md:items-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="w-24 md:w-32 ">
              <Image
                src={BAndi}
                className="h-20 w-20 rounded-full md:h-32 md:w-32"
                alt="Bánóczki Andrea"
              />
            </div>
            <div className="w-5/6 max-md:pl-4 sm:w-1/2 md:p-6">
              <h3 className="text-3xl font-bold leading-none">
                Bánóczki Andrea
              </h3>
              <h4 className="mb-4 text-base opacity-50">
                Szín-és stílustanácsadó, alkatszakértő
              </h4>
              <p className=" mb-8">
                Segítek, hogy minimalizáld a kinézeteddel kapcsolatos kétségeket
                és maximalizáld az önbizalmadat. Az öltözködés egy eszköz,
                megtanítalak, hogyan használd.
              </p>
            </div>
          </motion.div>
          <motion.div
            className="flex flex-row-reverse items-start justify-center gap-2 md:flex-row md:items-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="w-24 md:w-32 ">
              <Image
                src={ZAndi}
                className="h-20 w-20 rounded-full md:h-32 md:w-32"
                alt="Zilahi Andrea"
              />
            </div>
            <div className="w-5/6 max-md:pl-4 sm:w-1/2 md:p-6">
              <h3 className="text-3xl font-bold leading-none">Zilahi Andrea</h3>
              <h4 className="mb-4 text-base opacity-50">
                Női ciklus és hormonális tanácsadó, ciklusszinkron oktató.
              </h4>
              <p className=" mb-8">
                Szívügyem az egészséges életmód és a női test megértése. Hiszem,
                hogy a női ciklus megismerése és tudatos követèse segíthet az
                egészségmegőrzésben és a hormonális egyensúly elérésében.
              </p>
            </div>
          </motion.div>
          <motion.div
            className="flex flex-row-reverse items-start justify-center gap-2 md:flex-row md:items-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="w-24 md:w-32 ">
              <Image
                src={SzBlanka}
                className="h-20 w-20 rounded-full md:h-32 md:w-32"
                alt="Szilágyi Blanka"
              />
            </div>
            <div className="w-5/6 max-md:pl-4 sm:w-1/2 md:p-6">
              <h3 className="text-3xl font-bold leading-none">
                Szilágyi Blanka
              </h3>
              <h4 className="mb-4 text-base opacity-50">
                Női jóga és Hatha jóga oktató
              </h4>
              <p className=" mb-8">
                2015 óta gyakorlom az astanga jógát, és tapasztalataimmal
                segítek, hogy kapcsolódhass újra önmagaddal, hogy megtaláld a
                testi-lelki egyensúlyt.
              </p>
            </div>
          </motion.div>
          <motion.div
            className="flex flex-row-reverse items-start justify-center gap-2 md:flex-row md:items-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="w-24 md:w-32 ">
              <Image
                src={SzNiki}
                className="h-20 w-20 rounded-full md:h-32 md:w-32"
                alt="Szabó Niki"
              />
            </div>
            <div className="w-5/6 max-md:pl-4 sm:w-1/2 md:p-6">
              <h3 className="text-3xl font-bold leading-none">Szabó Niki</h3>
              <h4 className="mb-4 text-base opacity-50">
                Gerincprevenciós tréner
              </h4>
              <p className=" mb-8">
                Nyolc éve vagyok a pályán, és többezer vendég és megtartott
                online, ill. személyes edzés után is ugyanolyan lelkesedéssel
                képviselem az örök mottót, miszerint alapok nélkül nem építünk
                várat.
              </p>
            </div>
          </motion.div>
        </div>
      </section>
      <BottomCTA subscriptions={subscriptions} />
      <Footer shadow={false} />
    </>
  );
}
