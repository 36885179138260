import { motion } from "framer-motion";

export default function Problems() {
  return (
    <motion.section className="container mx-auto mb-24 mt-24 flex flex-col items-center px-4 pt-4 md:mb-48">
      <motion.h2
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        viewport={{ once: true, amount: 0.35 }}
        className="my-2 w-full text-center text-5xl font-bold leading-tight"
      >
        Ismerősek ezek a{" "}
        <span className="inline-block bg-gradient-to-r from-primary-600 to-accent-400 bg-clip-text text-transparent">
          problémák
        </span>
        ?
      </motion.h2>
      <div className="mt-24 flex flex-col items-start justify-center md:flex-row">
        <motion.div
          className="w-full p-4 md:w-1/3"
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true, amount: 0.35 }}
        >
          <div className="flex flex-col items-center">
            <div className="flex h-24 w-24 items-center justify-center rounded-full bg-primary-300 p-4">
              <img
                src="https://img.icons8.com/?size=100&id=W0o3UrSx99Jk&format=png&color=000000"
                alt="food"
              />
            </div>
            <h3 className="mt-4 text-2xl font-bold">Fogyási nehézség</h3>
            <p className="mt-4 text-center text-lg">
              Hiába próbálkozol különböző diétákkal és edzésprogramokkal, a
              felesleges kilók nem mozdulnak, és az eredmények is elmaradnak.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="w-full p-4 md:w-1/3"
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true, amount: 0.35 }}
        >
          <div className="flex flex-col items-center">
            <div className="flex h-24 w-24 items-center justify-center rounded-full bg-primary-300 p-4">
              <img
                src="https://img.icons8.com/?size=100&id=T8q9lmoVmRaI&format=png&color=000000"
                alt="exercise"
              />
            </div>
            <h3 className="mt-4 text-2xl font-bold">Időhiány</h3>
            <p className="mt-4 text-center text-lg">
              Egyszerűen nincs elég időd rendszeresen edzeni, egészséges
              ételeket készíteni és rendesen pihenni.
            </p>
          </div>
        </motion.div>
        {/* <motion.div
          className="w-full p-4 md:w-1/3"
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true, amount: 0.35 }}
        >
          <div className="flex flex-col items-center">
            <div className="flex h-24 w-24 items-center justify-center rounded-full bg-primary-300 p-4">
              <img
                src="https://img.icons8.com/?size=100&id=cdEJ5r6XVj3W&format=png&color=000000"
                alt="sleep"
              />
            </div>
            <h3 className="mt-4 text-2xl font-bold">Fájdalmas menstruáció</h3>
            <p className="mt-4 text-center text-lg">
              A ciklus közbeni fájdalmak és hangulatingadozások nagyon meg
              nehezíteni a napjaidat.
            </p>
          </div>
        </motion.div> */}
      </div>
    </motion.section>
  );
}
