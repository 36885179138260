import { useQuery } from "react-query";
import { useAuth } from "../store/auth.store";
import { useEffect } from "react";
import { ROUTES } from "../constants/routes";
import { fetchUser } from "../api/auth.api";

export const RequireAuth = ({
  children,
  reverse = false,
}: {
  children: React.ReactNode;
  reverse?: boolean;
}) => {
  // const isAuthenticated = !!useAuth.getState().user;
  const user = useAuth.getState().user;

  const { data: userData } = useQuery("user", fetchUser, {
    enabled: !reverse,
    refetchInterval: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (userData) {
      useAuth.getState().setUser(userData);
    }
  }, [userData]);

  const isAuthenticated = !!user;
  const isSubscribed =
    user?.status === "ACTIVE" || user?.status === "CANCELLED";

  if (isAuthenticated && reverse) {
    window.location.replace(isSubscribed ? ROUTES.appRoot : ROUTES.root);

    return null;
  }

  if (!isAuthenticated && !reverse) {
    window.location.replace(ROUTES.login);

    return null;
  }

  return children;
};
