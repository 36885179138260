import Layout from '../../../../components/Layout'
import { Spacer, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, getKeyValue } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { Button } from '../../../../components/Button';
import { IconPlus } from '@tabler/icons-react';
import StatusChip from '../../../../components/admin/StatusChip';
import { useQuery } from 'react-query';
import { getLecturers } from '../../../../api/admin/app/kBase/lecturers.api';
import { useTranslation } from 'react-i18next';

const COLUMNS = [
  {
    key: "name",
    label: "NEV",
  },
  {
    key: "status",
    label: "STATUS",
  },
];

export default function AdminLecturers() {
  const navigate = useNavigate();
  const {t} = useTranslation("admin")

  const { isLoading, data: lecturers } = useQuery("lecturers", () => getLecturers());

  if (isLoading) return (
    <Layout showFooter>
      <h1 className='text-xl mt-4 font-semibold'>{t("kBase.lecturers.lecturers")}</h1>
      <Spacer y={4} />
      <div className='flex justify-center items-center'>
        <Spinner />
      </div>
    </Layout>
  );

  return (
    <Layout showFooter>
      <h1 className='text-xl mt-4 font-semibold'>{t("kBase.lecturers.lecturers")}</h1>
      <Spacer y={4} />
      <Table
        color='secondary'
        bottomContent={
          <Button
            color="primary"
            onClick={() => navigate(ROUTES.admin.kBase.newLecturer)}
            startContent={<IconPlus />}
          >
            {t("kBase.lecturers.addNewLecturer")}
          </Button>
        }
      >
        <TableHeader columns={COLUMNS}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody
          items={lecturers || []}
          isLoading={isLoading}
        >
        {(item: {id: string; status: string;}) => (
          <TableRow className='cursor-pointer group' key={item.id} onClick={() => navigate(ROUTES.admin.kBase.getLecturer({id: item.id}))}>
            {(columnKey) => (
            <TableCell className='group-hover:bg-primary-100 last:rounded-e-xl first:rounded-s-xl'>
              {columnKey === "status" ? <StatusChip status={item.status} /> : getKeyValue(item, columnKey)}
            </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
      </Table>
    </Layout>
  )
}
