import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type User = {
  id: string;
  confirmToken?: string;
  email: string;
  role: string;
  firstName?: string;
  lastName?: string;
  status: string;
  streamToken?: string;
  createdAt: Date;
  updatedAt: Date;
};

interface Auth {
  user: User | null;
  setUser: (user: User | null) => void;
  setUserStatus: (status: string) => void;
  logout: () => void;
}

export const useAuth = create<Auth>()(
  persist(
    (set) => ({
      user: null,
      setUser: (user) => set(() => ({ user })),
      setUserStatus: (status) =>
        set((state) => ({
          user: state.user ? { ...state.user, status } : null,
        })),
      logout: () => set(() => ({ user: null })),
    }),
    {
      name: "auth-storage-app",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
