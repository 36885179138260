import { motion } from "framer-motion";
import { useApp } from "../../../store/app.store";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useStatsigClient } from "@statsig/react-bindings";
import { usePayment } from "../../../store/payment.store";
import { useAuth } from "../../../store/auth.store";
import { SubscriptionType } from "../../../types";

export default function BottomCTA({
  subscriptions,
}: {
  subscriptions: SubscriptionType[];
}) {
  const payment = usePayment();
  const navigate = useNavigate();
  const app = useApp();
  const auth = useAuth();
  const { logEvent } = useStatsigClient();
  const bottomFillColor = app.theme === "dark" ? "#1e1023" : "#eadcef";

  const waveAnimation3 = {
    y: [-5, 5, -5],
    transition: {
      duration: 4,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };

  const waveAnimation4 = {
    y: [-5, 5, -5],
    transition: {
      duration: 4,
      delay: 2,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };

  const d1 =
    "M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,320 L0,320 L0.457,34.035 Z";
  const d2 =
    "M-360.543,34.035 C-303.914,53.198 -262.792,65.809 -237.178,71.865 C-179.546,85.495 -126.705,90.29 -88.967,93.459 C-49.645,96.759 35.635,95.801 100.025,91.663 C125.76,90.01 157.727,86.372 195.926,80.752 C234.747,74.596 261.372,70.008 275.799,66.991 C302.913,61.324 351.501,49.503 366.605,46.128 C419.47,34.317 457.839,22.532 495.324,15.904 C561.689,4.169 594.676,2.522 650.185,0.432 C699.705,1.477 736.39,3.129 760.236,5.387 C800.703,9.219 847.621,17.821 874.4,22.304 C924.855,30.748 993.351,47.432 1079.886,72.354 L1080.191,320 L-360,320 L-360.543,34.035 Z";

  return (
    <section>
      <div className="relative mt-12 lg:mt-24">
        <motion.svg
          viewBox="0 -20 1440 174"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            fill={bottomFillColor}
            opacity={0.5}
            d={d2}
            animate={waveAnimation4}
          />
          <motion.path fill={bottomFillColor} d={d1} animate={waveAnimation3} />
        </motion.svg>
      </div>
      <div className="bg-primary-100">
        <motion.div
          className="flex w-full flex-col items-center justify-center bg-primary-100 pb-12"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          viewport={{ once: true, amount: 0.35 }}
        >
          {/* <h4 className="p-4 text-center text-xl font-bold">
            Hozd ki a maximumot{" "}
            <span className="inline-block bg-gradient-to-r from-accent-600 to-secondary-500 bg-clip-text text-transparent">
              magadból
            </span>
            , az egészséges életmóddal!
          </h4> */}
          <div>
            <button
              onClick={() => {
                payment.setSubscription(subscriptions[0]);
                if (auth.user) {
                  navigate(ROUTES.payment);
                  return;
                }
                logEvent("cta_clicked", "bottom_cta");
                navigate(ROUTES.directPayment);
              }}
              className="focus:shadow-outline mx-auto my-6 transform rounded-full bg-white px-8 py-4 font-bold uppercase text-gray-800 shadow-lg transition duration-300 ease-in-out hover:scale-105 focus:outline-none lg:mx-0"
            >
              Belépek a Nutriqueens-be
            </button>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
