import { API_URL } from "../../constants/environment";
import { axios } from "../axios";

export async function getUsers() {
  const response = await axios.get(`${API_URL}/admin/users`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function getUserById(id: string) {
  const response = await axios.get(`${API_URL}/admin/users/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function cancelUserSubscription(id: string) {
  const response = await axios.delete(`${API_URL}/admin/users/${id}`);

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
