import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { Button } from "../../components/Button";
import { Input } from "@nextui-org/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { QuizType } from "../../api/quiz";
import { useStatsigClient } from "@statsig/react-bindings";

const schema = yup
  .object()
  .shape({
    age: yup
      .string()
      .matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Érvénytelen életkor",
      )
      .required("Kérlek add meg az életkorod"),
    weight: yup
      .string()
      .matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Érvénytelen testsúly",
      )
      .required("Kérlek add meg a testsúlyod"),
    height: yup
      .string()
      .matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Érvénytelen magasság",
      )
      .required("Kérlek add meg a magasságod"),
  })
  .required();

export default function StepTwo({
  onContinue,
  data,
}: {
  onContinue: (data: object) => void;
  data: QuizType;
}) {
  const { logEvent } = useStatsigClient();
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = () => {
    logEvent("free_analysis_step", "two");
    handleSubmit((data) => {
      onContinue(data);
    })();
  };

  useEffect(() => {
    if (data?.age && data?.weight && data?.height) {
      form.setValue("age", data?.age, { shouldValidate: true });
      form.setValue("weight", data?.weight, { shouldValidate: true });
      form.setValue("height", data?.height, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="container mx-auto mt-4 max-w-screen-md px-2">
      <motion.h1
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="text-center text-2xl font-bold"
      >
        Általános információk
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        Hány éves vagy?
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-2"
      >
        <Controller
          name="age"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Kor"
              size="lg"
              color="primary"
              variant="underlined"
              isInvalid={!!form.formState.errors.age?.message}
              errorMessage={form.formState.errors.age?.message}
            />
          )}
        />
      </motion.div>

      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        Mennyi a testsúlyod?
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-2"
      >
        <Controller
          name="weight"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Súly"
              size="lg"
              color="primary"
              variant="underlined"
              endContent="kg"
              isInvalid={!!form.formState.errors.weight?.message}
              errorMessage={form.formState.errors.weight?.message}
            />
          )}
        />
      </motion.div>

      <motion.p
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.9, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        Milyen magas vagy?
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.9, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-2"
      >
        <Controller
          name="height"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Maggasság"
              size="lg"
              color="primary"
              variant="underlined"
              endContent="cm"
              isInvalid={!!form.formState.errors.height?.message}
              errorMessage={form.formState.errors.height?.message}
            />
          )}
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.1, duration: 0.5 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
        className="mt-12"
      >
        <Button
          onClick={onSubmit}
          className="w-full"
          variant="shadow"
          color="primary"
          isDisabled={!form.formState.isValid}
          size="lg"
        >
          Tovább
        </Button>
      </motion.div>
    </div>
  );
}
