import { Card, CardBody, CardHeader, Input, Spacer } from "@nextui-org/react";
import Layout from "../../components/Layout";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormEvent } from "react";
import { register } from "../../api/auth.api";
import SelectedSidebar from "../../components/auth/SelectedSidebar";
import { usePayment } from "../../store/payment.store";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], i18n.t("auth:register.passwordMismatch"))
      .required(),
  })
  .required();

export default function Register() {
  const navigate = useNavigate();
  const payment = usePayment();
  const { t } = useTranslation("auth");

  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
  });

  const { isLoading, mutate: registerUser } = useMutation(register, {
    onError: (error: { message: string }) => {
      form.setError("root", { message: error.message });
    },
    onSuccess: () => {
      ReactPixel.track("CompleteRegistration");
      navigate(ROUTES.payment);
    },
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit((data) => {
      registerUser(data);
    })();
  };

  return (
    <Layout
      headerVariant="simple"
      classNames={{
        main: "items-center justify-center flex flex-col md:flex-row gap-4 md:gap-24 mb-8 md:mb-0 ",
      }}
    >
      {payment.subscription && (
        <SelectedSidebar subscription={payment.subscription} />
      )}
      <Card
        classNames={{
          base: `w-full mx-auto ${!payment.subscription ? "max-w-sm" : "p-4"}`,
          header: "justify-center",
          footer: "justify-center",
        }}
        as="form"
        onSubmit={onSubmit}
      >
        <CardHeader>
          <h1 className="font-medium text-lg">{t("register.title")}</h1>
        </CardHeader>
        <CardBody>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t("register.email")}
                size="sm"
                color="primary"
                variant="bordered"
                errorMessage={form.formState.errors.email?.message}
              />
            )}
          />
          <Spacer y={3} />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t("register.password")}
                type="password"
                size="sm"
                color="primary"
                variant="bordered"
                errorMessage={form.formState.errors.password?.message}
              />
            )}
          />
          <Spacer y={3} />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t("register.confirmPassword")}
                type="password"
                size="sm"
                color="primary"
                variant="bordered"
                errorMessage={form.formState.errors.confirmPassword?.message}
              />
            )}
          />
          {form.formState.errors.root && (
            <>
              <Spacer y={3} />
              <p className="text-red-500 text-sm text-center">
                {form.formState.errors.root.message}
              </p>
            </>
          )}
          <Spacer y={5} />
          <Button
            variant="solid"
            color="primary"
            type="submit"
            isDisabled={
              isLoading ||
              form.formState.isSubmitting ||
              !form.formState.isValid
            }
            isLoading={isLoading}
          >
            {t("register.submit")}
          </Button>
        </CardBody>
      </Card>
    </Layout>
  );
}
