import Layout from '../../../components/Layout'
import { useQuery } from 'react-query';
import { getLecturer } from '../../../api/app/kBase/lecturers.api';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Image, Spacer, Spinner } from '@nextui-org/react';
import { ROUTES } from '../../../constants/routes';
import { getImageUrl } from '../../../utils/images.utils';
import { IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandTiktok, IconBrandYoutube, IconWorldWww } from '@tabler/icons-react';
import { useEffect } from 'react';

const Socials = ({
  lecturer,
  className
}: {
  lecturer: {
    email: string;
    website: string;
    instagram: string;
    linkedin: string;
    youtube: string;
    tiktok: string;
    facebook: string;
  };
  className?: string;
}) => {
  return (
    <section className={className}>
      <div className="opacity-70 flex gap-2">
        <span>{lecturer?.email}</span>
      </div> 
      <div className='flex gap-2 opacity-75 text-primary-300'>
        {lecturer.website && (
          <a href={lecturer.website} target='_blank' rel='noreferrer'>
            <IconWorldWww size={28}/>
          </a>
        )}
        {lecturer.instagram && (
          <a href={lecturer.instagram} target='_blank' rel='noreferrer'>
            <IconBrandInstagram size={28}/>
          </a>
        )}
        {lecturer.linkedin && (
          <a href={lecturer.linkedin} target='_blank' rel='noreferrer'>
            <IconBrandLinkedin size={28}/>
          </a>
        )}
        {lecturer.youtube && (
          <a href={lecturer.youtube} target='_blank' rel='noreferrer'>
            <IconBrandYoutube size={28}/>
          </a>
        )}
        {lecturer.tiktok && (
          <a href={lecturer.tiktok} target='_blank' rel='noreferrer'>
            <IconBrandTiktok size={28}/>
          </a>
        )}
        {lecturer.facebook && (
          <a href={lecturer.facebook} target='_blank' rel='noreferrer'>
            <IconBrandFacebook size={28}/>
          </a>
        )}
      </div>
    </section>
  )
}


export default function Lecturer() {
  const {id} = useParams();
  const navigate = useNavigate();

  const {isLoading, data: lecturer} = useQuery(['lecturers', id], () => getLecturer(id!), {
    enabled: !!id,
  });

  useEffect(() => {
    if (!id) navigate(ROUTES.kBase.root);
  }, [id, navigate]);

  if (isLoading) return (
    <Layout
      showFooter
      classNames={{
        main: 'flex justify-center items-center h-screen'
      }}
    >
      <Spinner />
    </Layout>
  );


  return (
    <Layout showFooter classNames={{ main: "!max-w-4xl"}}>
      <Spacer y={8} />
      <Card>
        <CardHeader className='flex flex-col md:flex-row gap-2 justify-between'>
          <h1 className='text-2xl font-bold uppercase'>{lecturer?.name}</h1>
          <Socials lecturer={lecturer} className="hidden md:flex gap-4" />
        </CardHeader>
          <CardBody className='flex justify-center items-center bg-primary-100 pb-0'>
              <Image
                src={getImageUrl(lecturer.image) || ''}
                alt={lecturer?.name}
                radius='none'
                classNames={{
                  img: 'h-52 w-full',
                  wrapper: 'w-full'
                }}
              />
          </CardBody>
          <CardFooter className='flex flex-col'>
            <p className='text-ellipsis line-clamp-4'>{lecturer?.description}</p>
            <Socials lecturer={lecturer} className="flex md:hidden flex-col items-center gap-2 mt-4" />
          </CardFooter>
        </Card>
    </Layout>
  )
}

