import { FormEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Layout from "../../../components/Layout";
import {
  Input,
  Select,
  SelectItem,
  Spacer,
  Spinner,
  Switch,
  Textarea,
} from "@nextui-org/react";
import { Button } from "../../../components/Button";
import { useMutation, useQuery } from "react-query";
import {
  createSubscription,
  getSubscriptionById,
  updateSubscription,
} from "../../../api/admin/subscriptions";
import { ROUTES } from "../../../constants/routes";
import { getIntervalData } from "../../../utils/subscription.utils";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("A név megadása kötelező"),
    active: yup.boolean().default(false),
    description: yup.string().required("A leírás megadása kötelező"),
    interval: yup.object().shape({
      time: yup.string().required("Az idő megadása kötelező"),
      unit: yup.string().required("Az időegység megadása kötelező"),
    }),
    amount: yup.string().required("Az összeg megadása kötelező"),
    compareAmount: yup.string().required("Az összeg megadása kötelező"),
  })
  .required();

export default function AdminSubscription({ isNew }: { isNew?: boolean }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("admin");
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, ...form } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const intervalNumbers = new Array(30)
    .fill(0)
    .map((_, i) => ({ value: (i + 1).toString(), label: (i + 1).toString() }));
  const intervalUnits = [
    { value: "d", label: "Nap" },
    { value: "w", label: "Hét" },
    { value: "m", label: "Hónap" },
    { value: "y", label: "Év" },
  ];

  const { isLoading: isSubscriptionLoading, data: subscription } = useQuery(
    ["subscription", id],
    () => getSubscriptionById(id!),
    {
      enabled: !isNew,
    },
  );

  useEffect(() => {
    if (!isNew && subscription) {
      form.setValue("name", subscription.name);
      form.setValue("active", subscription.status === "ACTIVE" ? true : false);
      form.setValue("description", subscription.description);
      form.setValue("interval", getIntervalData(subscription.interval));
      form.setValue("amount", subscription.amount);
      form.setValue("compareAmount", subscription.compareAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  const { isLoading: updateIsLoading, mutate: saveUpdatedSubscription } =
    useMutation(updateSubscription, {
      onError: (error: { message: string }) => {
        form.setError("root", { message: error.message });
      },
      onSuccess: () => {
        navigate(ROUTES.admin.subscriptions.root);
      },
    });

  const { isLoading: createIsLoading, mutate: saveSubscription } = useMutation(
    createSubscription,
    {
      onError: (error: { message: string }) => {
        form.setError("root", { message: error.message });
      },
      onSuccess: () => {
        navigate(ROUTES.admin.subscriptions.root);
      },
    },
  );

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log(form.formState.errors);
    handleSubmit((data) => {
      if (isNew) {
        saveSubscription({ ...data });
      } else {
        saveUpdatedSubscription({ id: id!, ...data });
      }
    })();
  };

  useEffect(() => {
    setIsLoading(createIsLoading || updateIsLoading || isSubscriptionLoading);
  }, [createIsLoading, updateIsLoading, isSubscriptionLoading]);

  if (!isNew && isSubscriptionLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter classNames={{ main: "max-w-screen-sm" }}>
      <form onSubmit={onSubmit}>
        <h1 className="text-xl mt-4 font-semibold">
          {isNew
            ? t("manageSubscriptions.newSubscription")
            : t("manageSubscriptions.editSubscription")}
        </h1>

        <Controller
          name="active"
          control={control}
          render={({ field }) => (
            <Switch
              onValueChange={(isSelected) => field.onChange(isSelected)}
              isSelected={field.value}
              className="mt-4"
            >
              <span className="opacity-70 max-w">
                {t("manageSubscriptions.active")}
              </span>
            </Switch>
          )}
        />

        <Spacer y={4} />

        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t("manageSubscriptions.name")}
              variant="bordered"
              color="primary"
              isInvalid={!!form.formState.errors.name?.message}
              errorMessage={form.formState.errors.name?.message}
            />
          )}
        />

        <Spacer y={4} />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              label={t("manageSubscriptions.description")}
              variant="bordered"
              color="primary"
              isInvalid={!!form.formState.errors.description?.message}
              errorMessage={form.formState.errors.description?.message}
            />
          )}
        />

        <Spacer y={4} />

        <div className="flex gap-2">
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t("manageSubscriptions.amount")}
                variant="bordered"
                color="primary"
                isInvalid={!!form.formState.errors.amount?.message}
                errorMessage={form.formState.errors.amount?.message}
                endContent={<span className="opacity-50 self-center">HUF</span>}
              />
            )}
          />

          <Spacer y={4} />

          <Controller
            name="compareAmount"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t("manageSubscriptions.compareAmount")}
                variant="bordered"
                color="primary"
                isInvalid={!!form.formState.errors.compareAmount?.message}
                errorMessage={form.formState.errors.compareAmount?.message}
                endContent={<span className="opacity-50 self-center">HUF</span>}
              />
            )}
          />
        </div>

        <Spacer y={4} />

        <Controller
          name="interval"
          control={control}
          render={({ field }) => {
            return (
              <div className="w-full border-2 border-solid border-default-200 px-3 py-2 shadow-sm rounded-medium flex flex-col">
                <label className="text-primary-300 text-small">
                  {t("manageSubscriptions.interval")}
                </label>
                <div className="flex gap-2 mt-2">
                  <Select
                    selectedKeys={[field.value?.time]}
                    onChange={(e) =>
                      field.onChange({
                        time: e.target.value,
                        unit: field.value?.unit,
                      })
                    }
                    color="primary"
                    variant="bordered"
                    items={intervalNumbers}
                    isInvalid={!!form.formState.errors.interval?.message}
                    errorMessage={form.formState.errors.interval?.message}
                  >
                    {(item) => (
                      <SelectItem key={item.value}>{item.label}</SelectItem>
                    )}
                  </Select>
                  <Select
                    selectedKeys={[field.value?.unit]}
                    onChange={(e) =>
                      field.onChange({
                        unit: e.target.value,
                        time: field.value?.time,
                      })
                    }
                    color="primary"
                    variant="bordered"
                    items={intervalUnits}
                    isInvalid={!!form.formState.errors.interval?.message}
                    errorMessage={form.formState.errors.interval?.message}
                  >
                    {(item) => (
                      <SelectItem key={item.value}>{item.label}</SelectItem>
                    )}
                  </Select>
                </div>
              </div>
            );
          }}
        />

        {form.formState.errors.root && (
          <>
            <Spacer y={3} />
            <p className="text-red-500 text-sm text-center">
              {form.formState.errors.root.message}
            </p>
          </>
        )}

        <Button
          variant="solid"
          color="primary"
          className="mt-8 w-full"
          type="submit"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          {t("manageSubscriptions.save")}
        </Button>

        <Spacer y={4} />
      </form>
    </Layout>
  );
}
