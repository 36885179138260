import Footer from "../../Footer";
import Header from "../../Header";
import BottomCTA from "./BottomCTA";
// import { Countdown } from "./Countdown";
import FAQ from "./FAQ";
import Hero from "./Hero";
import Problems from "./Problems";
import Refund from "./Refund";
import Services from "./Services";
import Solution from "./Solution";
import Testimonials from "./Testimonials";
// import Offer from "./Offer";
import Subscriptions from "./Subscriptions";
import { useQuery } from "react-query";
import { getSubscriptions } from "../../../api/payment.api";

export default function HomePageV7() {
  const { data: subscriptions } = useQuery(
    "subscriptions",
    () => getSubscriptions(),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      <Header />
      {/* <Countdown /> */}
      <Hero subscriptions={subscriptions} />
      <Problems />
      <Solution />
      <Services subscriptions={subscriptions} />
      <Subscriptions subscriptions={subscriptions} />
      <Testimonials />
      {/* <Offer /> */}
      <Refund />
      <FAQ />
      <BottomCTA subscriptions={subscriptions} />
      <Footer shadow={false} />
      <section className="bg-primary-100 px-4 py-8 text-center">
        <p className="font-light">
          *A tanagyagok alkalmazása minden felhasználónak saját felelösége.
        </p>
        <p className="pt-2 font-light">
          A tanácsadások nem helyettesítik az orvosi vizsgálatot.
        </p>
      </section>
    </>
  );
}
