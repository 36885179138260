import { ROUTES } from "../constants/routes";
import { useAuth } from "../store/auth.store";

export const RequireRole = ({ children }: { children: React.ReactNode }) => {
  const user = useAuth.getState().user;

  if (!["ADMIN", "OWNER"].includes(user?.role || "")) {
    window.location.replace(ROUTES.appRoot);
    return null;
  }

  return children;
};
