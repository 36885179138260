import { RouteObject } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import KBase from "../pages/presentation/kBase";
import Professionals from "../pages/presentation/professionals";
import Recipes from "../pages/presentation/recipes";

export const PresentationRouter: RouteObject[] = [
  {
    path: ROUTES.presentation.recipes,
    element: <Recipes />,
  },
  {
    path: ROUTES.presentation.kBase,
    element: <KBase />,
  },
  {
    path: ROUTES.presentation.professionals,
    element: <Professionals />,
  },
];
