import { IconStarFilled } from "@tabler/icons-react";
import Rev1 from "../../../assets/rev1.png";
import Rev3 from "../../../assets/rev3.jpg";
import { Image } from "@nextui-org/react";
import { motion } from "framer-motion";

const testimonials = [
  {
    name: "Cili",
    image: Rev1,
    review:
      "Mindenképpen ajánlom mindenkinek, mert jól összeszedett, tanulságos program, sőt tananyaggá tenném. Sok hasznos információ van benne, ami segít megérteni, hogy az életmódváltás nem bonyolult, csak a tudatosság hiányzik. Nagyon élveztem, amit célul tűztem ki, azt sikerült elérnem. Még tanulom a tudatosságot és a menütervezést, de jó úton haladok! A fenntarthatósággal sincs gond!",
  },
  {
    name: "Laura",
    image: Rev3,
    review:
      "Vettem ma harisnyát, mert holnap szoknyában megyek dolgozni, ÉN szoknyában! Igazad volt abban, hogy csak idő kérdése és az önbizalmam meg fog jönni! Annyira örülök, hogy belevágtam!",
  },
];

export default function Testimonials() {
  return (
    <section className="py-12">
      <div className="container mx-auto px-6 pb-16 text-center">
        <motion.h2
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true, amount: 0.35 }}
          className="my-2 w-full text-center text-5xl font-bold leading-tight"
        >
          Mit mondanak rólunk{" "}
          <span className="inline-block bg-gradient-to-r from-primary-600 to-accent-400 bg-clip-text text-transparent">
            tagjaink
          </span>
          ?
        </motion.h2>
        <div className="mt-32 grid grid-cols-1 gap-16 md:grid-cols-2 md:gap-12">
          {testimonials.map((testimonial, index) => (
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true, amount: 0.35 }}
              key={index}
              className="mb-6 rounded-lg bg-background-100 p-8 shadow-lg md:mb-0"
            >
              <div className="flex flex-col items-center">
                <Image
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="mx-auto  w-full object-cover"
                  classNames={{
                    wrapper: "w-1/3 h-1/3 -translate-y-14 scale-150",
                  }}
                />
                <h3 className="mb-2 text-xl font-semibold">
                  {testimonial.name}
                </h3>
                {/* <p className="text-gray-500 mb-4">{testimonial.title}</p> */}
                <div className="mb-4 h-0.5 w-16 bg-accent-400"></div>
                <div className="mb-8 flex flex-row">
                  <IconStarFilled className="text-yellow-400" />
                  <IconStarFilled className="text-yellow-400" />
                  <IconStarFilled className="text-yellow-400" />
                  <IconStarFilled className="text-yellow-400" />
                  <IconStarFilled className="text-yellow-400" />
                </div>
                <p className="italic text-foreground/80">
                  "{testimonial.review}"
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
