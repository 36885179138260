import Layout from "../../components/Layout";
import { Card, CardBody, CardHeader, Spacer, Spinner } from "@nextui-org/react";
import { IconPoint } from "@tabler/icons-react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getSubscriptions } from "../../api/payment.api";
import { ROUTES } from "../../constants/routes";
import { useAuth } from "../../store/auth.store";
import PromoCode from "../../components/auth/PromoCode";
import { SubscriptionType } from "../../types";
import { usePayment } from "../../store/payment.store";
import { useTranslation } from "react-i18next";
import { useStatsigClient } from "@statsig/react-bindings";

export default function Subscriptions() {
  const { logEvent } = useStatsigClient();
  const navigate = useNavigate();
  const auth = useAuth();
  const payment = usePayment();
  const { t } = useTranslation("payment");

  const { isLoading: isSubscriptionsLoading, data: subscriptions } = useQuery(
    "subscriptions",
    () => getSubscriptions(),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Layout
      classNames={{ main: "items-center justify-center flex flex-col mb-8" }}
    >
      <h1 className="mb-6 mt-4 text-2xl font-semibold">
        {t("subscriptions.title")}
      </h1>
      <h2 className="text-center text-lg opacity-70">
        {t("subscriptions.description")}
      </h2>
      <div className="mt-6 flex w-full flex-col gap-8 md:flex-row">
        {isSubscriptionsLoading && (
          <div className="flex w-full flex-1 items-center justify-center">
            <Spinner size="lg" />
          </div>
        )}
        {subscriptions?.length &&
          subscriptions?.map((sub: SubscriptionType) => {
            const getAmount = () => {
              if (payment.affiliateData) {
                return (
                  sub.amount * (1 - payment.affiliateData?.percentage * 0.01)
                );
              }
              return sub.amount;
            };

            const splitBenefits = sub.description?.split("\n");

            const benefits = splitBenefits?.map((benefit, index) => (
              <li
                key={index}
                className="mb-2 flex flex-row items-center space-x-2"
              >
                <IconPoint className="flex-shrink-0 stroke-primary" />
                {benefit}
              </li>
            ));

            return (
              <Card
                classNames={{
                  base: "w-full max-w-sm bg-gradient-to-b from-primary-100 to-accent-200 bg-opacity-30 mx-auto rounded-3xl p-4",
                  header: "justify-center flex-col",
                  footer: "justify-center",
                }}
                isPressable
                isHoverable
                key={sub.id}
                onClick={() => {
                  payment.setSubscription(sub);
                  if (auth.user) {
                    navigate(ROUTES.payment);
                    return;
                  }
                  logEvent("subscription_select", sub.name);
                  navigate(ROUTES.directPayment);
                }}
              >
                <CardHeader>
                  <h1 className="text-lg font-semibold">{sub.name}</h1>
                  <h2 className="mt-2 text-sm line-through opacity-50">
                    {sub.compareAmount} Ft
                  </h2>
                  <h2 className="text-xl font-bold text-secondary-600">
                    {getAmount()} Ft
                  </h2>
                </CardHeader>
                <CardBody>
                  <ul>{benefits}</ul>
                </CardBody>
              </Card>
            );
          })}
      </div>
      <PromoCode />
      <Spacer y={4} />
    </Layout>
  );
}
